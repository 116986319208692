import React from 'react';

function CostForm({ costs = [], onCostsChange, onAddCost  }) {
    const handleInputChange = (index, field) => event => {
        const newCosts = [...costs];
        newCosts[index] = { ...newCosts[index], [field]: event.target.value };
        if (onCostsChange) {
            onCostsChange(newCosts);
        }
    };

    const handleAddCost = (e) => {
        e.preventDefault();
        if (onAddCost) {
            onAddCost();
        }
    };

    const handleRemoveCost = (index) => (e) => {
        e.preventDefault();
        const newCosts = [...costs];
        newCosts.splice(index, 1);
        if (onCostsChange) {
            onCostsChange(newCosts);
        }
    };

    return (
        <div className='mt-6'>
            <hr />
            <div>
                <h3>Costos</h3>
            </div>
            {costs.map((cost, index) => (
                <div className="form-group mb-8" key={index}>
                    <label className="font-weight-bolder">Costo {index + 1}</label>
                    <input
                        type="text"
                        className="form-control form-control-solid form-control-lg"
                        value={cost.concept || ''}
                        onChange={handleInputChange(index, 'concept')}
                        placeholder="Concept"
                    />
                    <input
                        type="number"
                        min={1}
                        className="form-control form-control-solid form-control-lg"
                        value={cost.amount || ''}
                        onChange={handleInputChange(index, 'amount')}
                        placeholder="Amount"
                    />
                    <button className="btn btn-danger mt-2" onClick={handleRemoveCost(index)}>
                       Eliminar costo
                    </button>
                </div>
            ))}
            <button className="btn btn-primary mt-2" onClick={handleAddCost}>
                Agregar costo
            </button>
            <hr />
        </div>
    );
}

export default CostForm;
