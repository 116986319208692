import React, {useState, useCallback, useContext, useEffect} from 'react'
import Modal from '../../../../components/Modal/Modal'
import useFormState from '../../../../hooks/useFormState';
import { ApiContext } from '../../../../services/api/api-config';
import { getNotifier } from '../../../../services/notifier';
import {useSelector} from "react-redux";
import "./DeleteRegister.scss"
/**
 *
 *
 * @export
 * @param {*} {title, onClose, entity, id}
 * @return {*} 
 */
export default function DeleteRegister(
  {title, onClose, entity, id}
) {
  const api = useContext(ApiContext);
  const me = useSelector(s=>s.api.me);

  const [isLoading, setIsLoading] = useState(false)
  const {form, handleInputChange} = useFormState({});
  const [isSuperAdmin, setIsSuperAdmin] = useState(false)

  /**
   * Handle delete register in entity
   */
  const deleteRegister = useCallback(() => {
    api[entity].delete({id}).then(deleteResponse => {
      onClose();
    }, error => {
      setIsLoading(false);
    });
  }, [api, entity, id, onClose]);

  /**
   * Handle submit to delete register
   */
  const handleSubmit = useCallback(() => {
    if (!isSuperAdmin) {
      if (!form.username || !form.password) {
        return getNotifier().error('El usuario y contraseña del supervisor son requeridos')
      }
      setIsLoading(true);
      api.valid_supervisor.validate(
        {
          params: {
            _username: form.username,
            _password: form.password
          }
        }
      ).then(response => {
        if (response.success) {
          deleteRegister();
        }
      }, error => {
        setIsLoading(false);
      });
    } else {
      deleteRegister();
    }
  },[form.username, form.password, isSuperAdmin, api.valid_supervisor, deleteRegister]);

  
  useEffect(() => {
    if (me.role.name === 'SUPER_ADMIN') {
      setIsSuperAdmin(true);
    }
  }, [me.role.name]);
  
  return (
    <Modal
      title={title}
      onClose={onClose}
      className={"DeleteRegister"}
    >
      <div className='content'>
        
        <div className="card card-custom gutter-b">
          <div className="card-header border-0 pt-2">
            <div className='col text-center'>
              <h3>
                ¿Está seguro de eliminar el registro?
              </h3>
            </div>
          </div>
          <div className='card-content'>
            {
              !isSuperAdmin &&
              <div className="mt-3">
                <div className="form-group mb-3">
                  <label className="font-weight-bolder">Usuario del supervisor</label>
                  <input
                    type="text"
                    className="form-control form-control-solid form-control-lg"
                    value={form.username || ''}
                    onChange={handleInputChange('username')}
                    placeholder="Ingresa el usuario del supervisor"
                    maxLength={200}
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="font-weight-bolder">Contraseña del supervisor</label>
                  <input
                    type="password"
                    className="form-control form-control-solid form-control-lg"
                    value={form.password || ''}
                    onChange={handleInputChange('password')}
                    placeholder="Ingresa la contraseña del supervisor"
                    maxLength={200}
                  />
                </div>
              </div>
            }
          </div>
          <div className="card-footer pt-2">
            <div className='d-flex justify-content-end'>
              <button
                type="submit"
                disabled={isLoading}
                onClick={handleSubmit}
                className="btn btn-danger font-weight-bolder mr-2 px-8"
              >
                Eliminar
              </button>
              <button
                type="reset"
                className="btn btn-secondary font-weight-bolder px-8"
                onClick={onClose}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal> 
  )
}
