import React, { useContext} from 'react'
import { useMemo } from 'react';
import { ApiContext } from '../../../../../services/api/api-config';
import { getSponsorsTableColumns } from '../../SponsorsIndexUtils'
import useTideTable from "../../../../../hooks/useTideTable";
import TideReactTable from "../../../../../components/TideReactTable/TideReactTable";
import { useSelector } from 'react-redux';

export default function SponsorsTable(
  { onSponsorEdit, deleteSponsor, filters, onAddScholarship, onViewScholarship, events, handleEventSelected, downloadExcel, downloadPdf, seeAttended }
) {

  const api = useContext(ApiContext);
  const me = useSelector(s=>s.api.me);

  /**
   * Get columns for sponsors table
   */
  const sponsorsTableColumns = useMemo(() => getSponsorsTableColumns(
    { api, deleteSponsor, onSponsorEdit, onAddScholarship, onViewScholarship, events, handleEventSelected, downloadExcel, downloadPdf, seeAttended, me }
  ), [api, deleteSponsor, onAddScholarship, onSponsorEdit, onViewScholarship, events, handleEventSelected, downloadExcel, downloadPdf, seeAttended, me]);

  /**
   * Set filters for sponsors table
   */
  const sponsorFilters = useMemo(()=>({
    pagination: true,
    ...filters
  }),[filters]);

  /**
   * Create sponsors tide table
   */
  const sponsorsTable = useTideTable({
    entity: 'sponsors',
    columns: sponsorsTableColumns,
    requestFilters: sponsorFilters,
  });


  return (
    <div
      className="SponsorsTableView card card-custom card-shadowless product-container"
    >
      <div className="card-body p-5">
        <TideReactTable {...sponsorsTable.tableProps}/>
      </div>
    </div>
  )
}
