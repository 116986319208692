import {copyPropertyIdOrNull, copyPropertyIdOrThrow, copyPropertyOrNull, copyPropertyOrThrow} from "../formUtils";

/**
 * Options for specialties
 */
export const displaySpecialtiesOptions = [
    {value: 'Neurólogo de adultos', label: 'Neurólogo de adultos'},
    {value: 'Neurólogo pediatra', label: 'Neurólogo pediatra'},
    {value: 'Otro', label: 'Otro'}
]

/**
 * Options for titles
 */
export const displayTitleOptions = [
    {value: 'Dr.', label: 'Dr.'},
    {value: 'Dra.', label: 'Dra.'},
    {value: 'Otro', label: 'Otro'}
]

/**
 *
 * @returns Return empty registry form
 */
export const getEmptyRegistryForm = () => (
    {
        payments: [getEmptyPaymentSingle()]
    }
);

/**
 *
 * @returns return empty payment form
 */
export const getEmptyPaymentSingle = () => (
    {
        id: Math.random(),
        inscriptionType: null,
        event: null,
        paymentMethod: 0,
        sponsor: 0,
        years: ''
    }
);

/**
 *
 * @returns Return payments years current year plus 4
 */
export const getPaymentYears = () => {
    let currentYear = new Date().getFullYear();
    let years = [];
    for (let index = 1; index <= 5; index++) {
        years.push(
            {
                id: currentYear,
                value: currentYear
            }
        );
        currentYear++;
    }
    return years;
}

/**
 * Validate and Prepare data to send
 *
 * @param {any} params
 * @param {string} specialty
 * @param {string} country
 * @param {string} fiscalRegime
 * @returns
 */
export const prepareFormPaymentsRegistries = (
    params, specialty, country, fiscalRegime, title, phone, paymentDetails, isMexican, fiscalRegimeSocialReason, edit
) => {
    // Register form validations
    let register = {}
    copyPropertyIdOrThrow({title}, register, "title", "Por favor llena el campo de título");
    if (title === 'Otro') {
        copyPropertyOrThrow(params, register, "otherTitle", "Por favor llena el campo de especificar título");
    }
    copyPropertyOrThrow(params, register, "name", "Por favor llena el campo de nombre");
    copyPropertyOrThrow(params, register, "patLastName", "Por favor llena el campo de apellido paterno");
    // copyPropertyOrThrow(params, register, "matLastName", "Por favor llena el campo de apellido materno");
    // copyPropertyOrThrow({phone}, register, "phone", "Por favor llena el campo de teléfono");
    // copyPropertyOrThrow(params, register, "institution", "Por favor llena el campo de institución");
    // copyPropertyIdOrThrow({specialty}, register, "specialty", "Por favor selecciona una especialidad");
    // if (specialty === 'Otro') {
    //     copyPropertyOrThrow(params, register, "otherSpecialty", "Por favor llena el campo de especificar especialidad");
    // }
    copyPropertyIdOrThrow({country}, register, "country", "Por favor selecciona un país");
    copyPropertyOrThrow(params, register, "email", "Por favor llena el campo de correo electrónico");
    copyPropertyOrThrow(params, register, "rfc", isMexican ? "Por favor llena el campo de RFC" : "Por favor llena el campo de Identificador fiscal extranjero");
    copyPropertyOrThrow(params, register, "zipCode", "Por favor llena el campo de código postal");
    if (isMexican) {
        copyPropertyIdOrThrow({fiscalRegime}, register, "fiscalRegime", "Por favor selecciona un régimen fiscal");
    } else {
        copyPropertyOrThrow(params, register, "fiscalRegimeForeigner", "Por favor llena el campo de régimen fiscal extranjero");
    }

    // if (params.socialReason) {
    //     copyPropertyOrThrow(params, register, "socialReasonRfc", "Por favor llena el campo de RFC (razón social)");
    // }
    if (!paymentDetails) {
        copyPropertyOrThrow(paymentDetails, register, "paymentDetails", "Los detalles de pago no son válidos");
    }

    const payments = paymentDetails?.paymentMethods.map((payment) => {
        const paymentMethod = payment.id; // Example condition based on object property
        const amount = parseFloat(payment.amount).toFixed(2); // Assuming you want 2 decimal places

        return {
            paymentMethod,
            amount
        };
    })

    const registrations = [];

    paymentDetails?.paymentsAnualities.map((registration) => {
        return registrations.push({
            annuality: registration.id,
            amount: parseFloat(registration.amount).toFixed(2)
        })
    })

    paymentDetails?.paymentsEvents.map((registration) => {
        return registrations.push({
            eventPrice: registration.id,
            amount: parseFloat(registration.amount).toFixed(2)
        })
    })

    register.payments = payments;

    // Prepare person object to send
    const person = {
        id: params.id ? params.id : null,
        title: title !== 'Otro' ? title : params.otherTitle,
        name: params.name,
        patLastName: params.patLastName,
        matLastName: params.matLastName,
        phone: params.phone ? params.phone : undefined,
        institution: params.institution,
        speciality: specialty !== 'Otro'
            ? specialty ? specialty : 'na'
            : params.otherSpecialty,
        country: country,
        email: params.email,
        rfc: params.rfc,
        zipCode: params.zipCode,
        fiscalRegime: isMexican ? fiscalRegime : undefined,
        socialReason: params.socialReason ? params.socialReason : '',
        socialReasonRfc: params.socialReasonRfc ? params.socialReasonRfc : '',
        fiscalRegimeForeigner: !isMexican ? params.fiscalRegimeForeigner : '',
        zipCodeSocialReason: params.zipCodeSocialReason ? params.zipCodeSocialReason : '',
        fiscalRegimeSocialReason: isMexican && fiscalRegimeSocialReason ? fiscalRegimeSocialReason : undefined,
        fiscalRegimeForeignerSocialReason: !isMexican && params.fiscalRegimeForeignerSocialReason
            ? params.fiscalRegimeForeignerSocialReason
            : undefined,
        fiscalAddress: params.fiscalAddress,
        type: edit ? undefined : 'DOCTOR'
    };
    return {
        person: person,
        payments: paymentDetails ? payments : undefined,
        registrations: paymentDetails ? registrations : undefined
    };
}

/**
 * Verify if value exists in title options list
 *
 * @param {string} value
 * @returns boolean
 */
export const existsTitleOption = (value) => {
    let exists = false;
    displayTitleOptions.forEach(option => {
        if (option.value === value) {
            exists = true;
        }
    });
    return exists;
};

/**
 * Verify if value exists in specialty options list
 *
 * @param {string} value
 * @returns boolean
 */
export const existsSpecialtyOption = (value) => {
    let exists = false;
    displaySpecialtiesOptions.forEach(option => {
        if (option.value === value) {
            exists = true;
        }
    });
    return exists;
};

export const prepareFormPersonUpdate = (
    params, specialty, country, fiscalRegime, title, phone, isMexican, fiscalRegimeSocialReason, events
) => {
    // Register form validations
    let register = {}
    copyPropertyIdOrThrow({title}, register, "title", "Por favor llena el campo de título");
    if (title === 'Otro') {
        copyPropertyOrThrow(params, register, "otherTitle", "Por favor llena el campo de especificar título");
    }
    copyPropertyOrThrow(params, register, "name", "Por favor llena el campo de nombre");
    copyPropertyOrThrow(params, register, "patLastName", "Por favor llena el campo de apellido paterno");
    // copyPropertyOrThrow(params, register, "matLastName", "Por favor llena el campo de apellido materno");
    // copyPropertyOrThrow({phone}, register, "phone", "Por favor llena el campo de teléfono");
    // copyPropertyOrThrow(params, register, "institution", "Por favor llena el campo de institución");
    // copyPropertyIdOrThrow({specialty}, register, "specialty", "Por favor selecciona una especialidad");
    if (specialty === 'Otro') {
        copyPropertyOrThrow(params, register, "otherSpecialty", "Por favor llena el campo de especificar especialidad");
    }
    // copyPropertyIdOrThrow(params, register, "country", "Por favor selecciona un país");
    copyPropertyOrThrow(params, register, "email", "Por favor llena el campo de correo electrónico");
    // copyPropertyOrThrow(params, register, "rfc", "Por favor llena el campo de RFC");
    copyPropertyOrNull(params, register, "zipCode" );
    if (isMexican) {
        copyPropertyIdOrNull({fiscalRegime}, register, "fiscalRegime");
    } else {
        copyPropertyOrNull(params, register, "fiscalRegimeForeigner" );
    }

    if (params.socialReason) {
        copyPropertyOrNull(params, register, "socialReasonRfc");
    }

    if(fiscalRegime===""){
       register.fiscalRegime=null;
    }

    const eventIds = events.map(event => event.id);

    // Prepare person object to send
    const person = {
        title: title !== 'Otro' ? title : params.otherTitle,
        name: params.name,
        patLastName: params.patLastName,
        matLastName: params.matLastName,
        phone: params.phone ? params.phone : undefined,
        institution: params.institution,
        speciality: specialty !== 'Otro' ? specialty : params.otherSpecialty,
        country: country,
        email: params.email,
        rfc: params.rfc,
        zipCode: params.zipCode,
        fiscalRegime: isMexican ? register.fiscalRegime: undefined,
        socialReason: params.socialReason,
        socialReasonRfc: params.socialReasonRfc,
        fiscalRegimeForeigner: !isMexican ? params.fiscalRegimeForeigner : '',
        zipCodeSocialReason: params.zipCodeSocialReason,
        fiscalRegimeSocialReason: isMexican && fiscalRegimeSocialReason ? fiscalRegimeSocialReason : undefined,
        fiscalRegimeForeignerSocialReason: !isMexican && params.fiscalRegimeForeignerSocialReason
            ? params.fiscalRegimeForeignerSocialReason
            : undefined,
        fiscalAddress: params.fiscalAddress,
        event: eventIds,
    };
    return person;
}
