import React, {useCallback, useContext, useEffect, useState} from 'react'
import DeleteRegister from '../../DashLayout/components/DeleteRegister/DeleteRegister';
import PersonModal from './components/PersonModal/PersonModal';
import PersonPaymentsModal from './components/PersonPaymentsModal/PersonPaymentsModal';
import PersonsAdminHeader from './components/PersonsAdminHeader/PersonsAdminHeader';
import PersonsTable from './components/PersonsTableView/PersonsTable';
import "./PersonsIndex.scss";
import EditPersonModal from './components/EditPersonModal/EditPersonModal';
import { ApiContext } from '../../../services/api/api-config';
import { PaymentsRegistrySGroups } from './PersonsIndexUtils';
import { getNotifier } from '../../../services/notifier';
import { SecurityContext } from '../../../services/SecurityManager';

export default function PersonsIndex() {

  const [showPayments, setShowPayments] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [payments, setPayments] = useState([]);
  const [person, setPerson] = useState(null);
  const [filters, setFilters] = useState({'order[id]':'DESC'});
  const [remove, setRemove] = useState(false);
  const [registerToRemove, setRegisterToRemove] = useState();
  const [editPerson, setEditPerson] = useState(null)
  const [events, setEvents] = useState([])
  const [sponsors, setSponsors] = useState([])
  const [first, setFirst] = useState(true)

  const api = useContext(ApiContext);
  const security = useContext(SecurityContext);

  const getEvents = useCallback(() => {
    api.events.get().then(response => {
      setEvents(response);
    }).catch(
      error => getNotifier().error(error.message)
    );
  }, [api.events]);

  const getSponsors = useCallback(() => {
    api.sponsors.get().then(response => {
      setSponsors(response);
    }).catch(
        error => getNotifier().error(error.message)
    );
  }, [api.sponsors]);

  // Handle show payments modal
  const onPaymentsClick = useCallback((paymentsRegistry)=>{

    setPayments(paymentsRegistry);
    setShowPayments(true);
	},[]);

  // Handle show details modal
  const onDetailsClick = useCallback((person)=>{
    setPerson(person);
    setShowDetails(true);
	},[]);

  const onRemoveClick = useCallback( (person) => {
    setRemove(true);
    setRegisterToRemove(person);
  }, []);

  // Handle close payments modal
  const handleCloseModal = useCallback(()=>{
		setShowPayments(false);
	},[]);

   // Handle close details modal
  const handleClosePersonModal = useCallback(()=>{
		setShowDetails(false);
	},[]);

  const handleCloseRemoveModal = useCallback(() => {
    setRemove(false);
    setRegisterToRemove(null);
  }, []);

  const onEditPerson = useCallback((person) => {
    setEditPerson(person);
  }, []);

  const reload = useCallback(() => {
    api.people.get({params: {type: 'DOCTOR', 'order[createdDate]':'DESC', sGroups: PaymentsRegistrySGroups}})
  }, [api.people]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      getEvents();
      getSponsors();
    }
  }, [first, getEvents, getSponsors])
  

  return (
    <>
      {
        showPayments &&
        <div className="flex-column offcanvas-mobile mx-auto" id="kt_profile_aside">
          <PersonPaymentsModal
            payments={payments}
            handleCloseModal={handleCloseModal}
          />
        </div>
      }
      {
        remove && 
        <div className="flex-column offcanvas-mobile mx-auto" id="kt_profile_aside">
          <DeleteRegister
            entity='people'
            id={registerToRemove.id}
            title='Eliminar Registro'
            onClose={handleCloseRemoveModal}
          />
        </div>
      }
      {
        showDetails &&
        <div className="flex-column offcanvas-mobile mx-auto" id="kt_profile_aside">
          <PersonModal
            person={person}
            handleCloseModal={handleClosePersonModal}
          />
        </div>
      }
      <PersonsAdminHeader
        filters = {filters}
        onFiltersChange = {setFilters}
        onSuccessImportPerson={reload}
        events={events}
        sponsors={sponsors}
        securityManager={security}
      />
      <PersonsTable 
        onPaymentsClick = {onPaymentsClick}
        onDetailsClick = {onDetailsClick}
        onRemoveClick = {onRemoveClick}
        filters = {filters}
        onEditPerson={onEditPerson}
      />
      {
        editPerson &&
        <div className="flex-column offcanvas-mobile mx-auto" id="kt_profile_aside">
          <EditPersonModal
            person={editPerson}
            handleCloseModal={() => setEditPerson(null)}
          ></EditPersonModal>
        </div>
      }
    </>
  )
}
