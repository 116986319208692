import React, {useState, useCallback, useContext, useEffect} from 'react'
import SponsorsTable from './components/SponsorsTableView/SponsorsTable'
import useBoolean from '../../../hooks/useBoolean';
import SponsorForm from './components/SponsorForm/SponsorForm';
import SponsorsAdminHeader from './components/SponsorsAdminHeader/SponsorsAdminHeader';
import { ApiContext } from '../../../services/api/api-config';
import { getNotifier } from '../../../services/notifier';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import ScholarshipForm from '../Scholarships/components/ScholarshipForm/ScholarshipForm';
import ScholarshipList from './components/ScholarshipList/ScholarshipList';
import "./SponsorsIndex.scss";
import SponsorScholarshipAttended from './components/SponsorScholarshipAttended/SponsorScholarshipAttended';

export default function SponsorsIndex() {
  const [edit, setEdit] = useState();
  const [addingSponsor, startAddingSponsor, stopAddingSponsor] = useBoolean();
  const [filters, setFilters] = useState({'id':'ASC', type: 'PONENTE'});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const [showAddScholarship, setShowAddScholarship] = useState(false);
  const [sponsorSelected, setSponsorSelected] = useState(0)
  const [viewSponsorId, setViewSponsorId] = useState(0)
  const [events, setEvents] = useState([])
  const [first, setFirst] = useState(true)
  const [downloadEventSelected, setDownloadEventSelected] = useState([])
  const [sponsorAttended, setSponsorAttended] = useState([])
  const [eventForScholarships, setEventForScholarships] = useState(0)

  const api = useContext(ApiContext);

  // Handle close form modal
  const handleCloseForm = useCallback(()=>{
		setEdit(null);
		stopAddingSponsor();
	},[stopAddingSponsor]);

  /* Delete sponsor :: BEGIN */
  const deleteSponsor = useCallback(() => {
    api.sponsors.delete({id: idToDelete})
    .then(() => {
      setShowDeleteConfirm(false);
    })
    .catch((err) => {
      getNotifier().error(err.detail);
    });
  }, [api.sponsors, idToDelete]);
  /* Delete sponsor :: END */

  const onDeleteSponsor = useCallback((sponsor) => {
    setShowDeleteConfirm(true);
    setIdToDelete(sponsor.id);
  }, []);

  const onAddScholarship = useCallback((sponsor) => {
    setShowAddScholarship(true);
    setSponsorSelected(sponsor.id);
  }, []);

  const closeModalScholarship = useCallback(() => {
    setShowAddScholarship(false);
    setSponsorSelected(0);
  }, []);

  const onViewScholarship = useCallback((sponsor) => {
    setViewSponsorId(sponsor.id);
  }, []);

  const getEvents = useCallback(() => {
    api.events.get().then(response => {
      setEvents(response);
    }).catch((err) => {
      getNotifier().error(err.detail);
    });
  }, [api.events]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      getEvents();
    }
  }, [first, getEvents]);

  const handleEventSelected = useCallback((event, sponsorId) => {
    const eventId = event.target.value;
    let eventsSelected = [...downloadEventSelected];
    if (downloadEventSelected.length > 0) {
      const foundIndex = eventsSelected.findIndex(element => element.sponsorId === sponsorId);
      if (foundIndex >= 0) {
        eventsSelected[foundIndex].eventId = eventId;
        eventsSelected[foundIndex].sponsorId = sponsorId;
      } else {
        eventsSelected.push({
          eventId: eventId,
          sponsorId: sponsorId
        });
      }
    } else {
      eventsSelected.push({
        eventId: eventId,
        sponsorId: sponsorId
      });
    }
    setDownloadEventSelected(eventsSelected);
  }, [downloadEventSelected]);

  const downloadExcel = useCallback((sponsorId) => {
    const found = downloadEventSelected.find(element => element.sponsorId === sponsorId);
    if (!found) {
      return getNotifier().error('No ha seleccionado el evento');
    }
    window.location.href = api.sponsors.getDownloadExcelUrl(sponsorId, found.eventId);
  }, [api.sponsors, downloadEventSelected]);

  const downloadPdf = useCallback((sponsorId) => {
    const found = downloadEventSelected.find(element => element.sponsorId === sponsorId);
    if (!found) {
      return getNotifier().error('No ha seleccionado el evento');
    }
    window.location.href = api.sponsors.getDownloadPdfUrl(sponsorId, found.eventId);
  }, [api.sponsors, downloadEventSelected]);

  const seeAttended = useCallback((sponsorId) => {
    const found = downloadEventSelected.find(element => element.sponsorId === sponsorId);
    if (!found) {
      return getNotifier().error('No ha seleccionado el evento');
    }
    api.people.get({
      params: {
        'sponsor.id': sponsorId,
        type: 'BECADO',
        'eventAttendances.event.id': found.eventId,
        sGroups: [
          'person_read',
          'country_read',
          'person_read_event_attendance',
          'event_attendance_read',
          'event_attendance_read_event',
          'event_read'
        ]
      }
    }).then(response => {
      if (response.length === 0) {
        return getNotifier().error('No hay becados asignados a este evento');
      }
      setEventForScholarships(found.eventId);
      setSponsorAttended(response);
    }).catch(
      err => getNotifier().error(err.detail)
    );
  }, [api.people, downloadEventSelected]);

  return (
    <>
      <div className='SponsorsIndex'>
        <div className="d-flex flex-row">
          {
            !!(edit || addingSponsor) &&
            <div className="flex-column offcanvas-mobile mx-auto" id="kt_profile_aside">
              <SponsorForm
                edit={edit}
                closeModal={handleCloseForm}
              />
            </div>
          }
          <div className="flex-row-fluid ml-lg-8">
            <div className="card sponsors-index card-custom card-stretch gutter-b">
              <div className="card-body">
                <div className="mb-11">
                  <SponsorsAdminHeader
                    onNewSponsorClick = {startAddingSponsor}
                    filters = {filters}
                    onFiltersChange = {setFilters}
                  />
                  <SponsorsTable
                    onSponsorEdit = {setEdit}
                    deleteSponsor = {onDeleteSponsor}
                    onAddScholarship = {onAddScholarship}
                    onViewScholarship={onViewScholarship}
                    events={events}
                    handleEventSelected={handleEventSelected}
                    downloadExcel={downloadExcel}
                    downloadPdf={downloadPdf}
                    seeAttended={seeAttended}
                    filters = {filters}
                  />
                </div>
              </div>
            </div>
            {
              showDeleteConfirm &&
              <div className="flex-column offcanvas-mobile mx-auto SpeakerModal" id="kt_profile_aside">
                <div className="card card-custom gutter-b">
                  <div className="card-header border-0 pt-2">
                      <h3 className="card-title align-items-start flex-column mb-3">
                        <span className="card-label font-size-h3 font-weight-bolder text-dark">
                          Confirmar eliminar patrocinador
                        </span>
                      </h3>
                    <div
                      className="close-container d-flex justify-content-end pt-2 cursor-pointer"
                      onClick={() => setShowDeleteConfirm(false)}
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        className='close-icon pink-color'
                      />
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <h3>
                      ¿Está seguro de eliminar el patrocinador?
                    </h3>
                    <div>
                    <button
                      type="button"
                      onClick={deleteSponsor}
                      className="btn btn-primary font-weight-bolder mr-2 px-8"
                    >
                      Confirmar
                    </button>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          {
            showAddScholarship &&
            <ScholarshipForm
              sponsorId={sponsorSelected}
              closeModal={closeModalScholarship}
            ></ScholarshipForm>
          }
          {
            viewSponsorId > 0 &&
            <ScholarshipList
              sponsorId= {viewSponsorId}
              closeModal={ () => setViewSponsorId(0) }
            ></ScholarshipList>
          }
          {
            sponsorAttended.length > 0 &&
            <SponsorScholarshipAttended
              closeModal={() => setSponsorAttended([])}
              sponsorAttended={sponsorAttended}
              eventForScholarships={eventForScholarships}
            ></SponsorScholarshipAttended>
          }
        </div>
      </div>
    </>
    
  )
}
