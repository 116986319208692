import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit
} from '@fortawesome/free-solid-svg-icons';
import { moneyFormatter } from "../../../services/currencyUtils";

export const getAnnualitysTableColumns = (
    {api, deleteAnnuality, onAnnualityEdit}
  ) => [
    {
      Header: "ID",
      accessor: "id"
    },
    {
      Header: "Tipo de anualidad",
      accessor: annuality =>
        <div className="b-red max-w-25 text-wrap">
          <p className='mb-0 font-weight-bolder text-break'>
            { annuality.type || 'Sin información' }
          </p>
        </div>
    },
    {
        Header: "Concepto",
        accessor: annuality =>
            <div className="b-red max-w-25 text-wrap">
                <p className='mb-0 font-weight-bolder text-break'>
                    { annuality.concept|| 'Sin información' }
                </p>
            </div>
    },
    {
      Header: "Año",
      accessor: annuality =>
        <div>
          <p className='mb-0 font-weight-bolder'>
            { annuality.year || 'Sin información' }
          </p>
        </div>
    },
    {
      Header: "Costo",
      accessor: annuality =>
        <div className="max-w-25 text-wrap">
          <p className='mb-0 font-weight-bolder'>
            { moneyFormatter(annuality.amount) || 'Sin información' }
          </p>
        </div>
    },
    {
      Header: 'Acciones',
      accessor: annuality =>
        (
          <div className="actionButtonsContainer">
            <button
              className="btn btn-outline-danger font-weight-bolder py-1 mr-1"
              onClick={() => deleteAnnuality(annuality)}
            >
              <FontAwesomeIcon
                icon={faTrash}
                className='dashboard-logo icon-dash-logo text-dark'
              />
            </button>
            <button
              className="btn btn-outline-success font-weight-bolder py-1"
              onClick={() => onAnnualityEdit(annuality)}>
                <FontAwesomeIcon
                  icon={faEdit}
                  className='dashboard-logo icon-dash-logo text-dark'
                />
            </button>
          </div>
        )
    }
  ];
  
  
  /* sGroups used in Products CRUD from ProductsIndexComponent to ProductsForm :: BEGIN */
  export const annualitysSGroups = [
    'annuality_read'
  ];