import { copyPropertyIdOrThrow, copyPropertyOrThrow } from "../formUtils";

/**
 * Validate and Prepare data to send
 * 
 * @param {any} params 
 * @returns
 */
 export const prepareFormScholarships = (
  params, specialty, country, title, phone, sponsor, events
) => {
  // Register form validations
  let register = {}
  copyPropertyIdOrThrow( {title}, register, "title", "Por favor llena el campo de título" );
  if (title === 'Otro') {
      copyPropertyOrThrow( params, register, "otherTitle", "Por favor llena el campo de especificar título" );
  }
  copyPropertyOrThrow( params, register, "name", "Por favor llena el campo de nombre" );
  copyPropertyOrThrow( params, register, "patLastName", "Por favor llena el campo de apellido paterno" );
  if (specialty === 'Otro') {
      copyPropertyOrThrow( params, register, "otherSpecialty", "Por favor llena el campo de especificar especialidad" );
  }
  copyPropertyIdOrThrow( {country}, register, "country", "Por favor selecciona un país");
  copyPropertyOrThrow( params, register, "email", "Por favor llena el campo de correo electrónico" );
  copyPropertyIdOrThrow( {sponsor}, register, "sponsor", "Por favor selecciona un patrocinador");
  if (events.length === 0) {
    copyPropertyIdOrThrow( {}, register, "event", "Por favor selecciona un evento");
  }

  const eventIds = events.map(event => event.id);
  

  return {
    id: params.id ? params.id : null,
    title: title !== 'Otro' ? title : params.otherTitle,
    name: params.name,
    patLastName: params.patLastName,
    matLastName: params.matLastName,
    phone: phone,
    speciality: specialty !== 'Otro' ? specialty : params.otherSpecialty,
    country: country,
    email: params.email,
    type: 'BECADO',
    event: eventIds,
    sponsor: sponsor
  };
}

/* sGroups used in Products CRUD from ProductsIndexComponent to ProductsForm :: BEGIN */
export const ScholarshipSGroups = [
  'person_read',
  'person_read_country',
  'country_read',
  'person_read_event',
  'event_read',
  'person_read_sponsor',
  'sponsor_read',
  'person_read_event_attendance',
  'event_attendance_read',
  'event_attendance_read_event',
];
