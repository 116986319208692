import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEye,
    faFilePdf,
    faTrash
} from '@fortawesome/free-solid-svg-icons';
import {formatDate} from "../../../services/dateUtils";
import _ from "lodash";

export const getPaymentsTableColumns = (
    {onDetailsClick, onPdfClick, onRemoveClick}
) => {


    return [
        {
            Header: "Método de pago",
            accessor: paymentRegistry => {

                return <div>
                    {
                        (paymentRegistry.payments) &&
                        <ul>
                            {
                                _.map(paymentRegistry.payments, (detail, index) => (
                                    <li key={index}>
                                        {detail.paymentMethod.name} - {detail.amount}
                                    </li>
                                ))
                            }
                        </ul>
                    }
                </div>
            }
        },
        {
            Header: "Anualidades ",
            accessor: paymentRegistry =>
                <div>
                    {
                        ((paymentRegistry.registrations && paymentRegistry.registrations.length > 0) &&
                            <ul>
                                {
                                    _.map(paymentRegistry.registrations, (registration, index) => {
                                            if (!registration.annuality) return;
                                            return (
                                                <li key={index}>
                                                    {registration.annuality.type} - {registration.annuality.concept} - {registration.annuality.year} - {registration.amount}
                                                </li>
                                            )
                                        }
                                    )
                                }
                            </ul>) || 'Sin información'
                    }
                </div>
        },
        {
            Header: "Eventos",
            accessor: paymentRegistry =>
                <div>
                    {
                        ((paymentRegistry.registrations && paymentRegistry.registrations.length > 0) &&
                            <ul>
                                {
                                    _.map(paymentRegistry.registrations, (registration, index) => {
                                            if (!registration.eventPrice) return;
                                            return (
                                                <li key={index}>
                                                    {registration.eventPrice.event.name} - {registration.eventPrice.concept} - {registration.amount}
                                                </li>
                                            )
                                        }
                                    )
                                }
                            </ul>) || 'Sin información'
                    }
                </div>
        },
        {
            Header: "Persona",
            accessor: paymentRegistry =>
                <div>
                    <p className='mb-0 font-weight-bolder'>
                        {
                            `${paymentRegistry.person?.name} ${paymentRegistry.person?.patLastName} ${paymentRegistry.person?.matLastName}` ||
                            'n/a'
                        }
                    </p>
                </div>
        },
        {
            Header: "Patrocinador",
            accessor: paymentRegistry =>
                <div>
                    <p className='mb-0 font-weight-bolder'>
                        {
                            paymentRegistry.person?.sponsor ?
                                `${paymentRegistry.person?.sponsor.name}`
                                : 'n/a'
                        }
                    </p>
                </div>
        },
        {
            Header: "Fecha de Registro",
            accessor: paymentRegistry =>
                <div>
                    <p className='mb-0 font-weight-bolder'>
                        {paymentRegistry.createdDate ? formatDate(paymentRegistry.createdDate) : 'Sin información'}
                    </p>
                </div>
        },
        {
            Header: "Usuario",
            accessor: paymentRegistry =>
                <div>
                    <p className='mb-0 font-weight-bolder'>
                        {paymentRegistry.createdBy ? paymentRegistry.createdBy.username : 'Sin información'}
                    </p>
                </div>
        },
        {
            Header: 'Acciones',
            accessor: paymentRegistry =>
                (
                    <div className="actionButtonsContainer">
                        <button
                            className="btn btn-outline-success font-weight-bolder m-1"
                            onClick={() => onDetailsClick(paymentRegistry)}>
                            <FontAwesomeIcon icon={faEye} className='dashboard-logo icon-dash-logo text-dark'/>
                        </button>
                        <button
                            className="btn btn-outline-success font-weight-bolder m-1"
                            onClick={() => onPdfClick(paymentRegistry)}>
                            <FontAwesomeIcon icon={faFilePdf} className='dashboard-logo icon-dash-logo text-dark'/>
                        </button>
                        <button
                            className="btn btn-outline-danger font-weight-bolder m-1"
                            onClick={() => onRemoveClick(paymentRegistry)}>
                            <FontAwesomeIcon icon={faTrash} className='dashboard-logo icon-dash-logo text-dark'/>
                        </button>
                    </div>
                )
        }
    ];
};


export const PaymentSGroups = [
    'payment_read',
    'payment_read_payments_registry',
    'payments_registry_read',
    'payments_registry_read_person',
    'person_read',
    'payment_read_payment_method',
    'payment_method_read',
    'payment_read_sponsor',
    'sponsor_read',
    'payment_read_event',
    'event_read',
    'read_created_date',
    'person_read_country',
    'country_read',
    'person_read_fiscal_regime',
    'fiscal_regime_read',
    'read_created_by',
    'user_read_username',
    'payment_read_payment_methods_excel',
];