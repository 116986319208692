import React, {useMemo, useContext} from 'react'
import TideReactTable from '../../../../../components/TideReactTable/TideReactTable'
import useTideTable from '../../../../../hooks/useTideTable';
import { ApiContext } from '../../../../../services/api/api-config';
import { getScholarshipTableColumns } from '../../ScholarshipsUtilsIndex';
import { ScholarshipSGroups } from '../../../../../services/modelUtils/scholarshipUtils';
import "./ScholarshipTableView.scss"

export default function ScholarshipTableView(
  {onScholarshipEdit, onDeleteScholarship, filters, onScholarshipAttended}
) {

  const api = useContext(ApiContext);

  /**
   * Get columns for speakers table
   */
  const scholarshipTableColumns = useMemo(() => getScholarshipTableColumns(
    { api, onDeleteScholarship, onScholarshipEdit, onScholarshipAttended }
  ), [api, onDeleteScholarship, onScholarshipEdit, onScholarshipAttended]);

  /**
   * Set filters for speakers table
   */
  const scholarshipFilters = useMemo(()=>({
    pagination: true,
    sGroups: ScholarshipSGroups,
    ...filters
  }),[filters]);

  /**
   * Create speakers tide table
   */
  const scholarshipTable = useTideTable({
    entity: 'people',
    columns: scholarshipTableColumns,
    requestFilters: scholarshipFilters,
  });

  return (
    <div className='ScholarshipTableView card card-custom card-shadowless product-container'>
      <div className="card-body p-5">
        <TideReactTable {...scholarshipTable.tableProps}/>
      </div>
    </div>
  )
}
