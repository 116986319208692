import React, { useCallback, useState} from 'react'
import DeleteRegister from '../../DashLayout/components/DeleteRegister/DeleteRegister';
import PaymentsAdminHeader from './Components/PaymentsAdminHeader/PaymentsAdminHeader';
import PaymentsModal from './Components/PaymentsModal/PaymentsModal';
import PaymentsTable from './Components/PaymentsTableView/PaymentsTable'
import PdfModal from './Components/PdfModal/PdfModal';

export default function PaymentsIndex() {
  const [showDetails, setShowDetails] = useState(false);
  const [payment, setPayment] = useState(null);
  const [filters, setFilters] = useState({'order[createdDate]':'DESC'});
  const [remove, setRemove] = useState(false);
  const [registerToRemove, setRegisterToRemove] = useState();
  const [pdfToShow, setPdfToShow] = useState(0)

  /**
   * Modal to show payment details
   */
  const onDetailsClick = useCallback( (paymentRegistry) => {
    setPayment(paymentRegistry);
    setShowDetails(true);
  }, []);

  /**
   * Download pdf and send to person by email
   */
  const onPdfClick = useCallback( (paymentRegistry) => {
    setPdfToShow(paymentRegistry.id);
  }, []);

  /**
   * Handle remove payment click
   */
  const onRemoveClick = useCallback( (paymentRegistry) => {
    setRemove(true);
    setRegisterToRemove(paymentRegistry);
  }, []);
  
  // Handle close payments modal
  const handleCloseModal = useCallback(()=>{
		setShowDetails(false);
    setPdfToShow(0);
	},[]);

  /**
   * Handle close modal remove payment
   */
  const handleCloseRemoveModal = useCallback(() => {
    setRemove(false);
    setRegisterToRemove(null);
  }, []);

  return (
    <>
      {
        showDetails && 
        <PaymentsModal
          paymentRegistry={payment}
          handleCloseModal={handleCloseModal}
        />
      }
      {
        remove && 
        <div className="flex-column offcanvas-mobile mx-auto" id="kt_profile_aside">
          <DeleteRegister
            entity='paymentsRegistries'
            id={registerToRemove.id}
            title='Eliminar Registro'
            onClose={handleCloseRemoveModal}
          />
        </div>
      }
      {
        pdfToShow > 0 && 
        <PdfModal
          id={pdfToShow}
          handleCloseModal={handleCloseModal}
        />
      }
      <PaymentsAdminHeader
        filters = {filters}
        onFiltersChange = {setFilters}
      />
      <PaymentsTable 
        onDetailsClick={onDetailsClick}
        onPdfClick={onPdfClick}
        onRemoveClick={onRemoveClick}
        filters = {filters}
      />
    </>
  )
}
