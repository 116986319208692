import React, {useContext, useState, useCallback, useEffect} from 'react';
import "../../../../assets/styles/theme.scss";
import "./ProfileMenu.scss";
import ProfileEdit from "./components/ProfileEdit/ProfileEdit";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import { ApiContext } from '../../../../services/api/api-config';
import _ from 'lodash';
import arrayProfileImages from "../../../../assets/img/profileImgs/arrayProfileImages";

const ProfileMenu = ({showProfile, toggleProfile}) => {
	const api = useContext(ApiContext);
    const me=useSelector(({api})=>api.me);
    const [editProfileImage, setEditProfileImage] = useState(false);
    const [profileImage, setProfileImage] = useState();

    useEffect(() => {
		const theProfileImage = _.find(arrayProfileImages, (img) => img.name === me.avatar);
		setProfileImage(theProfileImage);
	}, [me]);

    const handleSelectProfileImage = useCallback((e) => {
		api.users.update({id: me.id + '', params:{avatar: e.currentTarget.name}}).then(() => {
			setEditProfileImage(editProfileImage => !editProfileImage);
			api.me.get();
		})
	}, [me, api]);

    return (
        <div className="ProfileMenu">
            <div id="kt_quick_user" className={"offcanvas offcanvas-left p-5" + (showProfile && ' offcanvas-on')}>
			{/*begin::Header*/}
			    <div className="offcanvas-header d-flex align-items-center justify-content-between pb-1">
			    	<h3 className="font-weight-bold m-0">Mi Perfil</h3>
			    	<span onClick={toggleProfile} className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
                        <FontAwesomeIcon icon={faTimes} className="close-icon text-danger"/>
			    	</span>
			    </div>
			    {/*end::Header*/}
			    {/*begin::Content*/}
			    <div className="offcanvas-content pr-5 mr-n5">
			    	{/*begin::Header*/}
			    	<div className="d-flex align-items-center mt-1">
			    		<div className="symbol symbol-100 mr-5">
			    			<div className="symbol-label" onClick={() => setEditProfileImage(!editProfileImage)}>
								{profileImage && <img src={profileImage.path}
													  alt={profileImage.alt}
													  name={profileImage.name}
													  style={{width: '100%'}}
													  className='cursor-pointer'/>}
							</div>
							<div  className="btn-sm text-center px-0 mt-1 btn-info cursor-pointer" onClick={() => setEditProfileImage(!editProfileImage)}>
								Cambiar foto
							</div>
			    		</div>
			    		<div className="d-flex flex-column">
			    			<span className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">{me && me.username}</span>
			    			<div className="text-muted">
                                {me.role ? 'Administradora' : 'Aliada'}
                            </div>
			    			<div className="navi mt-1">
			    				<span className="navi-item">
			    					<span className="navi-link p-0 pb-2">
			    						<span className="navi-icon mr-1">
			    							<span className="svg-icon svg-icon-lg svg-icon-primary">
			    								<FontAwesomeIcon icon={faEnvelope}/>
			    							</span>
			    						</span>
			    						<span className="navi-text text-muted text-hover-primary">{me.email}</span>
			    					</span>
			    				</span>
			    				<span onClick={api.logout} className="btn btn-sm btn-danger font-weight-bolder py-2 px-5">Cerrar sesión</span>
			    			</div>
			    		</div>
			    	</div>
					{editProfileImage &&
					<div className='select-profile-img-container'>
						{arrayProfileImages.map((img, i) =>
							(
								<img
									key={i}
									src={img.path}
								 	alt={img.alt}
								 	name={img.name}
								 	className='cursor-pointer'
								 	onClick={handleSelectProfileImage}/>
							))}
					</div>}
			    	{/*end::Header*/}
			    	{/*begin::Separator*/}
			    	<div className="separator separator-dashed mt-8 mb-5"></div>
			    	{/*end::Separator*/}
			    	{/*begin::Nav*/}
			    	<div className="navi navi-spacer-x-0 p-0">
			    	</div>
			    	{/*end::Notifications*/}
			    </div>
				<ProfileEdit me={me}/>
		    </div>
        </div>
    );
}

ProfileMenu.displayName="ProfileMenu";

export default ProfileMenu;
