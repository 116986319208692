import React, {useContext, useCallback} from 'react'
import TideTableFilters  from '../../../../../components/TideTableFilters/TideTableFilters';
import useCallbackCreator from "use-callback-creator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import { ApiContext } from '../../../../../services/api/api-config';

const PaymentsAdminHeader = (
  {filters, onFiltersChange}
) => {

  const api = useContext(ApiContext);

  /**
   * Handle filter change
   */
   const handleInputChange = useCallbackCreator( (field, event) => {
    const value = event.target.value;
    onFiltersChange( filters => {
      let newFilters = {...filters, [field]:value };
      if (field === 'paymentMethod') {
        newFilters = {
          ...newFilters,
          "paymentMethod.name" : value
        }
      }
      if (field === 'sponsor') {
        newFilters = {
          ...newFilters,
          "paymentsRegistry.person.sponsor.name" : value ? value : undefined
        }
      }
      if (field === 'event') {
        newFilters = {
          ...newFilters,
          "event.name" : value
        }
      }
      if (field === 'years') {
        newFilters = {
          ...newFilters,
          "years" : value.toString()
        }
      }
      if (field === 'person') {
        newFilters = {
          ...newFilters,
          "paymentsRegistry.person.name" : value
        }
      }
      if (field === 'patLastName') {
        newFilters = {
          ...newFilters,
          "paymentsRegistry.person.patLastName" : value
        }
      }
      if (field === 'matLastName') {
        newFilters = {
          ...newFilters,
          "paymentsRegistry.person.matLastName" : value
        }
      }
      if (field === 'createdDate') {
        newFilters = {
          ...newFilters,
          "createdDate" : value
        }
      }
      if (field === 'createdBy') {
        newFilters = {
          ...newFilters,
          "createdBy.username" : value
        }
      }
      if(value)
        return newFilters;
      delete newFilters[field];
      return newFilters;
    } );
  },[onFiltersChange]);

  /**
   * Handle order change
   */
  const handleOrderChange = useCallbackCreator( (field) => {
    onFiltersChange( filters => {
      const newFilters = {...filters};
      if(!filters[field])
        newFilters[field] = 'ASC' ;
      else if(filters[field]==='ASC')
        newFilters[field] = 'DESC' ;
      else
        delete newFilters[field];
      return newFilters;
    } );
  },[onFiltersChange]);

  /**
   * Config filters for table
   */
  const filtersConfig = [
    {
      name: 'Método de pago',
      selectPlaceholder: 'Método de pago',
      onChange: handleInputChange('paymentMethods'),
      value: filters.paymentMethods,
      type: 'select',
      placeholder: 'Filtrar por método de pago',
      orderOnChange: handleOrderChange('order[paymentMethod.name]'),
      orderStatus: filters['order[paymentMethod.name]'],
      options: ['EFECTIVO', 'TARJETA', 'BECADO', 'INSCRIPCIÓN WEB', 'TRANSFERENCIA', 'DEPOSITO', 'SIN PAGO'],
      optionsText: {
        'EFECTIVO': 'EFECTIVO',
        'TARJETA': 'TARJETA',
        'BECADO': 'BECADO',
        'INSCRIPCIÓN WEB': 'INSCRIPCIÓN WEB',
        'TRANSFERENCIA': 'TRANSFERENCIA',
        'DEPOSITO': 'DEPOSITO',
        'SIN PAGO': 'SIN PAGO',
      }
    },
    {
      name: 'Patrocinador',
      onChange: handleInputChange('sponsor'),
      value: filters.sponsor,
      type: 'text',
      placeholder: 'Filtrar por patrocinador',
      orderOnChange: handleOrderChange('order[sponsor.name]'),
      orderStatus: filters['order[sponsor.name]'],
    },
    {
      name: 'Evento',
      onChange: handleInputChange('eventDetails'),
      value: filters.eventDetails,
      type: 'text',
      placeholder: 'Filtrar por evento',
      orderOnChange: handleOrderChange('order[event.name]'),
      orderStatus: filters['order[event.name]'],
    },
    {
      name: 'Nombre',
      onChange: handleInputChange('person'),
      value: filters.person,
      type: 'text',
      placeholder: 'Filtrar por nombre',
      orderOnChange: handleOrderChange('order[paymentsRegistry.person.name]'),
      orderStatus: filters['order[paymentsRegistry.person.name]'],
    },
    {
      name: 'Apellido paterno',
      onChange: handleInputChange('patLastName'),
      value: filters.patLastName,
      type: 'text',
      placeholder: 'Filtrar por apellido paterno',
      orderOnChange: handleOrderChange('order[paymentsRegistry.person.patLastName]'),
      orderStatus: filters['order[paymentsRegistry.person.patLastName]'],
    },
    {
      name: 'Apellido materno',
      onChange: handleInputChange('matLastName'),
      value: filters.matLastName,
      type: 'text',
      placeholder: 'Filtrar por apellido materno',
      orderOnChange: handleOrderChange('order[paymentsRegistry.person.matLastName]'),
      orderStatus: filters['order[paymentsRegistry.person.matLastName]'],
    },
    {
      name: 'Fecha de Registro de Pago',
      onChange: handleInputChange('createdDate'),
      value: filters.createdDate,
      type: 'date',
      placeholder: 'Filtrar por fecha',
      orderOnChange: handleOrderChange('order[createdDate]'),
      orderStatus: filters['order[createdDate]'],
    },
    {
      name: 'Usuario',
      onChange: handleInputChange('createdBy'),
      value: filters.createdBy,
      type: 'text',
      placeholder: 'Filtrar por usuario',
      orderOnChange: handleOrderChange('order[createdBy]'),
      orderStatus: filters['order[createdDate]'],
    }
  ];

  const excelUrl = useCallback( () => {
    window.location.href = api.payments.exportExcelUrl(filters);
  }, [api.payments, filters]
);

  return (
    <div className="SponsorsAdminHeader d-flex flex-row w-100">
      <div className="card-toolbar mt-6 ml-2">
        <ul className="nav nav-pills nav-pills-sm nav-dark-75 justify-content-center justify-content-md-end">
          <li className="nav-item btn-amcemig">
            <span
              onClick={excelUrl}
              className="excelButton btn d-flex justify-content-center"
            >
              <FontAwesomeIcon icon={faFileExcel} className="text-white" />
            </span>
          </li>
        </ul>
      </div>
      <div className="d-flex justify-content-md-start">
        <TideTableFilters inputsObject={filtersConfig} />
      </div>
    </div>
  )
}

export default PaymentsAdminHeader;

