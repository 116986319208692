import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import "./PaymentsModal.scss"

export default function PaymentsModal(
  {paymentRegistry, handleCloseModal}
) {
  return (
    <div className='PaymentsModal'>
      <div className="card card-custom gutter-b">
        <div className="card-header border-0 pt-2">
          <h3 className="card-title align-items-start flex-column mb-1">
            <span className="card-label font-size-h3 font-weight-bolder text-dark">
              Información del registro
            </span>
          </h3>
          <div
            className="close-container d-flex justify-content-end pt-2 cursor-pointer"
            onClick={handleCloseModal}
          >
            <FontAwesomeIcon icon={faTimes} className='close-icon pink-color'/>
          </div>
        </div>
        <div className="card-body pt-0">
            <ul className='list-group'>
              <li className="list-group-item">
                <span className='text-dark text-uppercase field-name'>
                  {paymentRegistry.person.title}&nbsp;
                  {paymentRegistry.person.name}&nbsp;
                  {paymentRegistry.person.patLastName}&nbsp;
                  {paymentRegistry.person.matLastName}
                </span>
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  Teléfono:
                </span>
                {paymentRegistry.person.phone}
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  Institución:
                </span>
                {paymentRegistry.person.institution}
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  Especialidad:
                </span>
                {paymentRegistry.person.speciality}
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  País:
                </span>
                {paymentRegistry.person.country ? paymentRegistry.person.country.name : ''}
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  Correo electrónico:
                </span>
                {paymentRegistry.person.email}
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  RFC:
                </span>
                {paymentRegistry.person.rfc}
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  Código postal:
                </span>
                {paymentRegistry.person.zipCode}
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  Régimen fiscal:
                </span>
                {paymentRegistry.person.fiscalRegime ? paymentRegistry.person.fiscalRegime.name : ''}
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  Régimen fiscal extranjero:
                </span>
                {paymentRegistry.person.fiscalRegimeForeigner ? paymentRegistry.person.fiscalRegimeForeigner : ''}
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  Razón social:
                </span>
                {paymentRegistry.person.socialReason}
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  RFC Razón social:
                </span>
                {paymentRegistry.person.socialReasonRfc}
              </li>
            </ul>
        </div>
      </div>
    </div>
  )
}
