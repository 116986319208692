import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faEdit,
} from '@fortawesome/free-solid-svg-icons';

export const getCoordinatorsTableColumns = (
    {api, deleteCoordinator, onCoordinatorEdit, onCoordinatorAttended}
) => [
    {
        Header: "Id de registro",
        accessor: "id"
    },
    {
        Header: "Secuencial",
        accessor: person =>
            <div className="text-wrap">
                <p className='mb-0 font-weight-bolder'>
                    {person.sequential || 'Sin información'}
                </p>
            </div>
    },
    {
        Header: "Título",
        accessor: person =>
            <div className="text-wrap">
                <p className='mb-0 font-weight-bolder'>
                    {person.title || 'Sin información'}
                </p>
            </div>
    },
    {
        Header: "Nombre",
        accessor: person =>
            <div className="text-wrap">
                <p className='mb-0 font-weight-bolder'>
                    {person.name || 'Sin información'}
                </p>
            </div>
    },
    {
        Header: "Apellido Paterno",
        accessor: person =>
            <div className="text-wrap">
                <p className='mb-0 font-weight-bolder'>
                    {person.patLastName || 'Sin información'}
                </p>
            </div>
    },
    {
        Header: "Apellido Materno",
        accessor: person =>
            <div className="text-wrap">
                <p className='mb-0 font-weight-bolder'>
                    {person.matLastName || 'Sin información'}
                </p>
            </div>
    },
    {
        Header: "País",
        accessor: person =>
            <div className="text-wrap">
                <p className='mb-0 font-weight-bolder'>
                    {person.country ? person.country.name : 'Sin información'}
                </p>
            </div>
    },
    {
        Header: "Teléfono",
        accessor: person =>
            <div className="text-wrap">
                <p className='mb-0 font-weight-bolder'>
                    {person.phone || 'Sin información'}
                </p>
            </div>
    },
    {
        Header: "Correo electrónico",
        accessor: person =>
            <div className="text-wrap">
                <p className='mb-0 font-weight-bolder'>
                    {person.email || 'Sin información'}
                </p>
            </div>
    },
    {
        Header: 'Acciones',
        accessor: speaker =>
            (
                <div className="actionButtonsContainer">
                    <button
                        className="btn btn-outline-danger font-weight-bolder py-1 mr-1"
                        onClick={() => deleteCoordinator(speaker)}
                    >
                        <FontAwesomeIcon icon={faTrash} className='dashboard-logo icon-dash-logo text-dark'/>
                    </button>
                    <button
                        className="btn btn-outline-success font-weight-bolder py-1 mr-1"
                        onClick={() => onCoordinatorEdit(speaker)}>
                        <FontAwesomeIcon icon={faEdit} className='dashboard-logo icon-dash-logo text-dark'/>
                    </button>
                    {/*
                        speaker.eventAttendances && speaker.eventAttendances.length > 0 && !speaker.eventAttendances[speaker.eventAttendances.length - 1].attended &&
                        <button
                            className="btn btn-outline-warning font-weight-bolder py-1"
                            title='Marcar Asistencia'
                            onClick={() =>onCoordinatorAttended(speaker.eventAttendances[speaker.eventAttendances.length - 1])}>
                            <FontAwesomeIcon icon={faSquare} className='dashboard-logo icon-dash-logo'/>
                        </button>
                        */
                    }
                    {/* {
                        speaker.eventAttendances && speaker.eventAttendances.length > 0 && speaker.eventAttendances[speaker.eventAttendances.length - 1].attended &&
                        <button
                            className="btn btn-outline-warning font-weight-bolder py-1"
                            title='Quitar Asistencia'
                            onClick={() =>onCoordinatorAttended(speaker.eventAttendances[speaker.eventAttendances.length - 1])}>
                            <FontAwesomeIcon icon={faCheckSquare} className='dashboard-logo icon-dash-logo'/>
                        </button>
                    } */}
                </div>
            )
    }
]