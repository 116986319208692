import React, {useContext, useCallback} from 'react'
import TideTableFilters from '../../../../../components/TideTableFilters/TideTableFilters'
import { ApiContext } from '../../../../../services/api/api-config'
import useCallbackCreator from "use-callback-creator";
import ImportPerson from '../../../../../components/ImportPerson/ImportPerson';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import "./ScholarshipAdminHeader.scss"

export default function ScholarshipAdminHeader(
  { onNewScholarshipClick, filters, onFiltersChange, onSuccessImportPerson, sponsors }
) {

  const api = useContext(ApiContext)

  const getSponsorsList = useCallback(() => {
    let optionsStr = {};
    sponsors.forEach(sponsor => {
      optionsStr[sponsor.name] = sponsor.name;
    });
    return optionsStr;
  }, [sponsors]);

  const handleInputChange = useCallbackCreator( (field, event) => {
    const value = event.target.value;
    onFiltersChange( filters => {
      const newFilters = {...filters, [field]:value };
      if(value)
        return newFilters;
      delete newFilters[field];
      return newFilters;
    } );
  },[onFiltersChange]);

  const handleOrderChange = useCallbackCreator( (field) => {
    onFiltersChange( filters => {
      const newFilters = {...filters};
      if(!filters[field])
        newFilters[field] = 'ASC' ;
      else if(filters[field]==='ASC')
        newFilters[field] = 'DESC' ;
      else
        delete newFilters[field];
      return newFilters;
    } );
  },[onFiltersChange]);

  const filtersConfig = [
    {
      name: 'Id de registro',
      onChange: handleInputChange('id'),
      value: filters['id'],
      type: 'text',
      placeholder: 'Filtrar por Id de registro',
      orderOnChange: handleOrderChange('id'),
      orderStatus: filters['id'],
    },

    {
      name: 'nombre',
      onChange: handleInputChange('name'),
      value: filters['name'],
      type: 'text',
      placeholder: 'Filtrar por nombre',
      orderOnChange: handleOrderChange('order[name]'),
      orderStatus: filters['order[name]'],
    },
    {
      name: 'apellido paterno',
      onChange: handleInputChange('patLastName'),
      value: filters['patLastName'],
      type: 'text',
      placeholder: 'Filtrar por apellido paterno',
      orderOnChange: handleOrderChange('order[patLastName]'),
      orderStatus: filters['order[patLastName]'],
    },
    {
      name: 'apellido materno',
      onChange: handleInputChange('matLastName'),
      value: filters['matLastName'],
      type: 'text',
      placeholder: 'Filtrar por apellido materno',
      orderOnChange: handleOrderChange('order[matLastName]'),
      orderStatus: filters['order[matLastName]'],
    },
    {
      name: 'Patrocinador',
      onChange: handleInputChange('sponsor.name'),
      value: filters['sponsor.name'],
      type: 'select',
      options: sponsors.map(sponsor => sponsor.name),
      optionsText: getSponsorsList(),
      label: 'Patrocinador'
    },
  ];

  const excelUrl = useCallback( () => {
    window.location.href = api.people.exportUrl(filters, 'Becados', 'xlsx');
  }, [api.people, filters]);

  return (
    <div className='ScholarshipAdminHeader d-flex justify-content-between w-100'>
      <div className='d-flex'>
        <div>
          <div className="card-toolbar mt-5 ml-2">
            <ul className="nav nav-pills nav-pills-sm nav-dark-75 ">
              <li className="nav-item btn-amcemig">
                <span
                  onClick={excelUrl}
                  className="excelButton btn d-flex justify-content-center"
                >
                  <FontAwesomeIcon icon={faFileExcel} className="text-white" />
                </span>
              </li>
              <li className="nav-item btn-amcemig">
                <span
                  className="nav-link text-light py-2 px-4 btn-info cursor-pointer"
                  data-toggle="tab"
                  onClick={onNewScholarshipClick}
                >
                  Añadir Becado
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="d-flex justify-content-md-start">
          <TideTableFilters inputsObject={filtersConfig} />
        </div>
        <div>
          <ImportPerson
            type="BECADO"
            onSuccess={onSuccessImportPerson}
          ></ImportPerson>
        </div>
      </div>
      
    </div>
  )
}
