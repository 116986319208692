import React from 'react'
import "./SponsorScholarshipAttended.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import _ from "lodash"

export default function SponsorScholarshipAttended(
  {sponsorAttended, closeModal, eventForScholarships}
) {
  return (
    <div className='SponsorScholarshipAttended'>
      <div className="card card-custom gutter-b">
        <div className="card-header border-0 pt-2">
            <h3 className="card-title align-items-start flex-column mb-1">
              <span className="card-label font-size-h3 font-weight-bolder text-dark">
                Lista de Becados del evento
              </span>
            </h3>
          <div
            className="close-container d-flex justify-content-end pt-2 cursor-pointer"
            onClick={closeModal}
          >
            <FontAwesomeIcon icon={faTimes} className='close-icon pink-color'/>
          </div>
        </div>
        <div className="card-body pt-0">
        <table className='table table-responsive'>
            <thead>
              <tr>
                <th>
                  Título
                </th>
                <th>
                  Nombre(s)
                </th>
                <th>
                  Apellido Paterno
                </th>
                <th>
                  Apellido Materno
                </th>
                <th>
                  Correo Electrónico
                </th>
                <th>
                  Teléfono
                </th>
                <th>
                  País
                </th>
                <th>
                  ¿Asistió?
                </th>
              </tr>
            </thead>
            <tbody>
              {
                _.map(sponsorAttended, (scholarship) => (
                  <tr key={scholarship.id}>
                    <td>
                      {scholarship.title}
                    </td>
                    <td>
                      {scholarship.name}
                    </td>
                    <td>
                      {scholarship.patLastName}
                    </td>
                    <td>
                      {scholarship.matLastName}
                    </td>
                    <td>
                      {scholarship.email}
                    </td>
                    <td>
                      {scholarship.phone}
                    </td>
                    <td>
                      {scholarship.country ? scholarship.country.name : ''}
                    </td>
                    <td>
                      {
                        scholarship.eventAttendances &&
                        <div>
                          {
                            _.map(scholarship.eventAttendances, (attendance, index) => (
                              <span key={index}>
                                {
                                attendance.event.id === parseInt(eventForScholarships)
                                  ? attendance.attended ? 'Si' : 'No'
                                  : ''
                                }
                              </span>
                            ))
                          }
                        </div>
                      }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
