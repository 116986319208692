import {copyPropertyIdOrNull, copyPropertyIdOrThrow, copyPropertyOrNull, copyPropertyOrThrow} from "../formUtils";

/**
 * Validate and Prepare data to send
 * 
 * @param {any} params 
 * @returns
 */
 export const prepareFormSpeakers = (
  params, specialty, country, title,  event, events
) => {
  // Register form validations
  let register = {}
  copyPropertyIdOrThrow( {title}, register, "title", "Por favor llena el campo de título" );
  if (title === 'Otro') {
      copyPropertyOrThrow( params, register, "otherTitle", "Por favor llena el campo de especificar título" );
  }
  copyPropertyOrThrow( params, register, "name", "Por favor llena el campo de nombre" );
  copyPropertyOrThrow( params, register, "patLastName", "Por favor llena el campo de apellido paterno" );
  copyPropertyOrNull( params, register, "matLastName", "Por favor llena el campo de apellido materno" );
  copyPropertyOrNull( params, register, "phone", "Por favor llena el campo de teléfono" );
  copyPropertyIdOrNull( {specialty}, register, "specialty", "Por favor selecciona una especialidad");
  if (specialty === 'Otro') {
      copyPropertyOrThrow( params, register, "otherSpecialty", "Por favor llena el campo de especificar especialidad" );
  }
  copyPropertyIdOrThrow( {country}, register, "country", "Por favor selecciona un país");
  copyPropertyOrThrow( params, register, "email", "Por favor llena el campo de correo electrónico" );
  if (events.length === 0) {
    copyPropertyIdOrThrow( {}, register, "event", "Por favor selecciona un evento");
  }

  const eventIds = events.map(event => event.id);

  let spec=null;
  if(specialty !== ''){
    spec =specialty;
  }

  return {
    id: params.id ? params.id : null,
    title: title !== 'Otro' ? title : params.otherTitle,
    name: params.name,
    patLastName: params.patLastName,
    matLastName: params.matLastName,
    phone: params.phone,
    speciality: spec !== 'Otro' ? spec : params.otherSpecialty,
    country: country,
    email: params.email,
    type: 'PONENTE',
    event: eventIds
  };
}

/* sGroups used in Products CRUD from ProductsIndexComponent to ProductsForm :: BEGIN */
export const SpeakersSGroups = [
  'person_read',
  'person_read_country',
  'country_read',
  'person_read_fiscal_regime',
  'fiscal_regime_read',
  'person_read_sponsor',
  'sponsor_read',
  'person_read_event_attendance',
  'event_attendance_read',
  'event_attendance_read_event',
  'event_read'
];
