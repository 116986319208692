import React, {useContext} from 'react'
import { useMemo } from 'react';
import TideReactTable from '../../../../../components/TideReactTable/TideReactTable'
import useTideTable from '../../../../../hooks/useTideTable';
import { ApiContext } from '../../../../../services/api/api-config';
import { SpeakersSGroups } from '../../../../../services/modelUtils/SpeakerUtils';
import { getSpeakersTableColumns } from '../../SpeakersUtilsIndex';
import "./SpeakersTableView.scss"

export default function SpeakersTableView(
  {onSpeakerEdit, onDeleteSpeaker, filters, onSpeakerAttended, securityManager}
) {

  const api = useContext(ApiContext);

  /**
   * Get columns for speakers table
   */
   const speakersTableColumns = useMemo(() => getSpeakersTableColumns(
    { api, onDeleteSpeaker, onSpeakerEdit, onSpeakerAttended, securityManager }
  ), [api, onDeleteSpeaker, onSpeakerEdit, onSpeakerAttended, securityManager]);

  /**
   * Set filters for speakers table
   */
   const speakerFilters = useMemo(()=>({
    pagination: true,
    sGroups: SpeakersSGroups,
    ...filters
  }),[filters]);

  /**
   * Create speakers tide table
   */
   const sponsorsTable = useTideTable({
    entity: 'people',
    columns: speakersTableColumns,
    requestFilters: speakerFilters,
  });

  return (
    <div
      className="SpeakersTableView card card-custom card-shadowless product-container"
    >
      <div className="card-body p-5">
        <TideReactTable {...sponsorsTable.tableProps}/>
      </div>
    </div>
  )
}
