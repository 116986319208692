import { faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react';
import "./TideTableFilters.scss";
import useBoolean from "../../hooks/useBoolean";
import SelectInput from './components/SelectInput/SelectInput';
import DigitalInput from './components/DigitalInput/DigitalInput';
import BooleanButton from './components/BooleanButton/BooleanButton';

/**
 *
 * @param inputsObject
 * @param icon
 * @param title
 * @returns {JSX.Element}
 * @constructor
 */
// NO COPIAR A OTROS PROYECTOS.
const TideTableFilters = ({ inputsObject, icon = faSearch, title = 'Filtrar tabla' }) => {
    const [displayTable,,,toggleTable] = useBoolean();

    return (
        <div className="TideTableFilters d-flex flex-column w-100">
            <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                <div className="input-group headerContainer" onClick={toggleTable}>
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroupPrepend3"><FontAwesomeIcon icon={icon} /> </span>
                    </div>
                    <div className="form-control d-flex align-items-center justify-content-between" id="validationServerUsername" aria-describedby="inputGroupPrepend3">{title}<FontAwesomeIcon icon={displayTable?faChevronDown:faChevronUp} /> </div>
                </div>
            </div>
            { displayTable &&
            <div className="d-flex inputsContainer bg-white shadow-sm rounded justify-content-start">
                {inputsObject &&
                    _.map(inputsObject, (input, index) => (
                        <div key={index}>
                            {input.type === 'select' &&
                                <SelectInput input={input} />
                            }

                            {(input.type === 'text' || input.type === 'number' || input.type === 'date') &&
                                <DigitalInput input={input} />
                            }

                            {input.type === 'boolean' &&
                                <BooleanButton input={input} />
                            }
                        </div>
                    ))
                }
            </div>
            }
        </div>
    );
}

export default TideTableFilters;
