import React, {useContext, useState, useCallback, useEffect} from 'react';
import "./RegisterSearch.scss";
import {ApiContext} from '../../../../../services/api/api-config';
import {getNotifier} from '../../../../../services/notifier';
import {PersonSGroups} from '../../RegisterIndexUtils';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faIdCard, faSquare, faCheckSquare} from '@fortawesome/free-solid-svg-icons';
import _ from "lodash";
import {Modal} from 'react-bootstrap';

export default function RegisterSearch(
    {handleCloseSearchModal, handlePersonSelected, handleEditPaymentSelected}
) {
    const api = useContext(ApiContext);
    const [name, setName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [persons, setPersons] = useState([]);
    const [showIframe, setShowIframe] = useState(false)
    const [pdfUrl, setPdfUrl] = useState('')

    /**
     * Handle name change
     */
    const handleNameChange = useCallback((event) => {
        setName(event.target.value);
    }, []);


    const loadPersonsByName = useCallback(() => {
        api.people.get(
            {
                params: {
                    search: name,
                    pagination: false,
                    sGroups: PersonSGroups
                }
            }
        ).then(persons => {
            setPersons(persons);
            setIsLoading(false);
        });
    }, [api.people, name])

    /**
     * Handle the submit
     */
    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        setIsLoading(true);
        const errorMessage = 'Debe ingresar el nombre, apellidos o ID.';
        if (name === '') {
            setIsLoading(false);
            return getNotifier().error(errorMessage);
        }
        loadPersonsByName();

    }, [name, loadPersonsByName]);


    /**
     * Handle submit on key enter pressed, add a listener to the component on mount and remove listener on unmount
     */
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 13) {
                handleSubmit(event);
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleSubmit])


    const markAttended = useCallback((eventAttendance) => {
        api.eventAttendances.update({
            id: eventAttendance.id,
            params: {
                attended: !eventAttendance.attended
            }
        }).then(response => {
            loadPersonsByName();
            getNotifier().success('Se actualizó correctamente');
        }).catch(
            error => getNotifier().error('Ocurrió un error al actualizar la asistencia')
        );
    }, [api, loadPersonsByName]);

    const handlePrintTag = useCallback(async (person) => {
        try {
            const fileLocation = await api.people.printTag(person.id);
            setPdfUrl(`${fileLocation}`);
            // setPdfUrl(`https://registro-back.amcemig.com/${fileLocation}`);
            setShowIframe(true);
        } catch (error) {
            console.error('Error printing file:', error);
        }
    }, [api.people]);

    return (
        <div className='RegisterSearch'>
            <div className="card card-custom gutter-b">
                <div className="card-header border-0 pt-2">
                    <div className='d-flex justify-content-between'>
                        <div>
                            <h3 className="card-title align-items-start flex-column mb-1">
                <span className="card-label font-size-h3 font-weight-bolder text-dark">
                  Búsqueda por nombre
                </span>
                            </h3>
                        </div>
                    </div>
                    <div>
                        <FontAwesomeIcon
                            icon={faTimes}
                            className='close-icon pink-color cursor-pointer'
                            onClick={handleCloseSearchModal}
                        />
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div className="mt-6">
                        <div className="form-group mb-8">
                            <label className="font-weight-bolder">Nombre completo</label>
                            <input
                                type="text"
                                className="form-control form-control-solid form-control-lg"
                                value={name}
                                onChange={handleNameChange}
                                placeholder="Ingresa el nombre de la persona"
                            />
                        </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <button
                            type="button"
                            disabled={isLoading}
                            onClick={handleSubmit}
                            className="btn btn-primary font-weight-bolder mr-2 px-8"
                        >
                            Buscar
                        </button>

                        <button
                            type="button"
                            disabled={isLoading}
                            onClick={handleCloseSearchModal}
                            className="btn btn-primary font-weight-bolder mr-2 px-8"
                        >
                            Crear Nuevo Registro
                        </button>
                    </div>
                    {
                        persons && persons.length > 0 &&
                        <>
                            <ul className='list-group mb-2'>
                                <li className="list-group-item">
                                    <div className='d-flex justify-content-between'>
                                        <div>&nbsp;</div>
                                        <div>
                                            Registro de Asistencia
                                        </div>
                                    </div>
                                </li>
                                {_.map(persons, (person, index) => (
                                    <li key={person.id} className="list-group-item">
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex'>
                                                <div>
                                                    <FontAwesomeIcon
                                                        icon={faIdCard}
                                                        className='cursor-pointer mr-2 print-icon'
                                                        onClick={() => handlePrintTag(person)}
                                                    />
                                                </div>
                                                <div>

                                                    <div  className='cursor-pointer' onClick={() => handlePersonSelected(person)}>
                                                    <span
                                                        className='name'>{`${person.name} ${person.patLastName} ${person.matLastName}`}</span>
                                                        <br/>
                                                        <span className='id'>Id de registro: {person.id}</span>
                                                        <br/>
                                                        <span
                                                            className='font-weight-bolder mt-1 text-primary'>{person.email}</span>
                                                        <br/>
                                                        <span className='category'>Categoría: {person.type}</span>
                                                        <br/>
                                                        {
                                                            person.type === 'BECADO' && <>
                                                            <span>
                                                                Patrocinado por: {person.sponsor ? person.sponsor.name : ''}
                                                            </span>
                                                                <br/>
                                                            </>
                                                        }
                                                    </div>
                                                    {person.paymentsRegistries.length > 0 &&
                                                        <div>
                                                            <ul>
                                                                Pagos:
                                                                {person.paymentsRegistries.map(payment => (
                                                                    <li key={payment.id}>
                                                                        <span>{payment.resume}
                                                                            <button className={'btn'}
                                                                                    onClick={() => handleEditPaymentSelected(person, payment.id)}>Editar
                                                                            </button>
                                                                        </span>
                                                                    </li>
                                                                ))}

                                                            </ul>
                                                        </div>
                                                    }

                                                </div>
                                            </div>

                                            <div>
                                                {
                                                    person.eventAttendances.map(eventAttendance => (
                                                        <div className='event-attendance'>
                                                            <FontAwesomeIcon
                                                                icon={eventAttendance.attended ? faCheckSquare : faSquare}
                                                                className={'close-icon cursor-pointer ' + (eventAttendance.attended ? 'green-color' : '')}
                                                                onClick={(e) => markAttended(eventAttendance)}
                                                            />
                                                            <span>{eventAttendance.event.name}</span>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div className='mt-2'>&nbsp;</div>
                        </>
                    }
                </div>
                {
                    showIframe &&
                    <Modal
                        show={showIframe} onHide={() => setShowIframe(false)}
                    >
                        <div
                            className="d-flex p-2 cursor-pointer icon-container"
                            onClick={() => setShowIframe(false)}
                        >
                            <FontAwesomeIcon icon={faTimes} className='custom-close-icon pink-color'/>
                        </div>
                        <iframe
                            src={`${pdfUrl}#view=fitH`}
                            title="tagPdf"
                            height="500"
                            width="500"
                        />
                    </Modal>
                }
            </div>
        </div>
    )
}
