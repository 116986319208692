import React, {useCallback, useContext, useState, useEffect} from 'react';
import "./UsersAdminEdit.scss";
import {ApiContext} from '../../../../../services/api/api-config';
import useFormState from '../../../../../hooks/useFormState';
import {
  userListSGroups
} from '../../../../../services/modelUtils/userUtils';
import {getNotifier} from '../../../../../services/notifier';
import Modal from "../../../../../components/Modal/Modal";
import {useSelector} from "react-redux";
import _ from "lodash";

const UsersAdminEdit = ({isEdit, user, onClose}) => {
  const api = useContext(ApiContext);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstTime, setFirstTime] = useState(true);
  const [role, setRole] = useState({})
  const [isSuperAdmin, setIsSuperAdmin] = useState(false)
  const [rolesOptions, setRolesOptions] = useState([])

  const me = useSelector(({api})=>api.me);

  const {form, handleInputChange, setForm} = useFormState({});

  /**
   * Handle confirm password validation
   */
  const handleConfirmPassword = useCallback( (e) => {
    if (confirmPassword !== '') {
      if (confirmPassword !== form.password) {
        return getNotifier().error('La confirmación de la contraseña no es correcta');
      }
    }
  }, [confirmPassword, form.password]);

  /**
   * Handle save user
   */
  const handleSave = useCallback(() => {
    if (form.password && form.password !== '' && confirmPassword !== form.password) {
      return getNotifier().error('La confirmación de la contraseña no es correcta');
    }

    if (!form.password) {
      if ((role.name !== 'SUPER_ADMIN' && !isEdit) || (role.name === 'SUPER_ADMIN' && !isEdit)) {
        return getNotifier().error('La contraseña es requerida');
      }
    }
  
    const params = {
      username: form.username,
      password: form.password,
      role: isEdit && user.role?.id === '1' ? user.role.id : form.role,
      sGroups: userListSGroups,
    }

    api.users[ isEdit ? 'update' : 'create' ](
      {
        id: user?.id,
        params,
        loadingId:'UsersAdminEdit.users'
      }
    ).then(onClose);
  }, [confirmPassword, form, isEdit, user, api.users, onClose, role.name]);

  useEffect(() => {
    if (me && me.role) {
      setRole(me.role);
    }
    if (firstTime) {
      setFirstTime(false);
      api.roles.get().then(rolesResponse => {
        setRolesOptions(rolesResponse);
      }, error => {
        console.log('error: ', error);
      });
    }
    if (isEdit) {
      if (me.id !== user.id) {
        if (me.role && me.role.name === 'SUPER_ADMIN') {
          setIsSuperAdmin(true);
        }
        setForm(
          {
            username: user.username,
            email: user.email,
            role: user.role?.id
          }
        );
      } else {
        onClose();
        return getNotifier().error('No puede actualizar su mismo usuario');
      }
    }
  }, [api, firstTime, isEdit, me, onClose, setForm, user]);

  const loading = useSelector( s => !!s.loadingIds["UsersAdminEdit.users"]);

    return (
      <Modal
        title={(isEdit ? 'Editar' : 'Añadir')+' usuario'}
        onClose={onClose}
        className={"UsersAdminEdit"}
      >
        {/*begin::Form*/}
        <form className="form">
          <div className="card-body">
            <div className="form-group row mb-2">
              <div className="input-group col-lg-12 mb-2">
                <div className="input-group-prepend input-label">
                  <span className="input-group-text input-label">Usuario</span>
                </div>
                <input
                  type="text"
                  value={form.username || ''}
                  onChange={handleInputChange('username')}
                  className="form-control"
                  placeholder={'Ingresa el usuario'}
                  id="username"
                />
              </div>
            </div>
            { (!isEdit || isSuperAdmin) &&
              <>
                <div className="form-group row mb-2">
                  <div className="input-group col-lg-12 mb-2">
                    <div className="input-group-prepend input-label">
                      <span className="input-group-text input-label">Contraseña</span>
                    </div>
                    <input
                      type="password" value={form.password || ''}
                      onChange={handleInputChange('password')}
                      className="form-control"
                      placeholder="Ingresa una contraseña"
                      id="password"
                    />
                  </div>
                </div>
                <div className="form-group row mb-2">
                  <div className="input-group col-lg-12 mb-2">
                    <div className="input-group-prepend input-label">
                      <span className="input-group-text input-label">Repetir Contraseña</span>
                    </div>
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      onBlur={handleConfirmPassword}
                      className="form-control"
                      placeholder="repetir la contraseña"
                      id="confirmPassword"
                    />
                  </div>
                </div>
              </>
            }
            <div className='row'>
              <div className='col'>
                <div className="form-group row mb-2">
                  <div className="input-group col-lg-12 mb-2">
                    <div className="input-group-prepend input-label">
                      <label className="input-group-text input-label" htmlFor="selectRole">
                        Rol
                      </label>
                    </div>
                    <select
                      className="form-control " 
                      id="role"
                      value={form.role || 'Selecciona una opción'}
                      onChange={handleInputChange('role')}
                    >
                      <option value="">
                        Seleccionar
                      </option>
                      {_.map(rolesOptions, (role) =>
                        (
                          role.assignable &&
                          <option
                            className='font-bold p-2'
                            value={role.id}
                            key={role.id}
                          >
                            {role.title}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="separator separator-dashed my-2" />
          </div>
          <div className="card-footer">
            <button
              type="reset"
              className="btn btn-primary mr-2"
              disabled={loading}
              onClick={handleSave}
            >
              Aceptar
            </button>
            <button
              type="reset"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Cancelar
            </button>
          </div>
        </form>
        {/*end::Form*/}
      </Modal>
    );
}

export default UsersAdminEdit;
