export const PersonSGroups = [
    'person_read',
    'person_read_country',
    'country_read',
    'person_read_fiscal_regime',
    'fiscal_regime_read',
    'person_read_sponsor',
    'sponsor_read',
    'person_read_event_attendance',
    'event_attendance_read',
    'event_attendance_read_event',
    'event_read',
    'person_read_payments_registry_read',
    'payments_registry_read',
];
