import React, {useContext, useCallback} from 'react'
import TideTableFilters from '../../../../../components/TideTableFilters/TideTableFilters';
import useCallbackCreator from "use-callback-creator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {ApiContext} from '../../../../../services/api/api-config';
import ImportPerson from '../../../../../components/ImportPerson/ImportPerson';

const PersonsAdminHeader = (
    {filters, onFiltersChange, onSuccessImportPerson, events, sponsors, securityManager}
) => {

    const api = useContext(ApiContext);

    const getEventsList = useCallback(() => {
        let optionsStr = {};
        events.forEach(event => {
            optionsStr[event.name] = event.name;
        });
        return optionsStr;
    }, [events]);

    const getSponsorsList = useCallback(() => {
        let optionsStr = {};
        sponsors.forEach(sponsor => {
            optionsStr[sponsor.name] = sponsor.name;
        });
        return optionsStr;
    }, [sponsors]);

    /**
     * Handle filter change
     */
    const handleInputChange = useCallbackCreator((field, event) => {
        const value = event.target.value;
        onFiltersChange(filters => {
            let newFilters = {...filters, [field]: value};
            if (field === 'sponsor') {
                if (value) {
                    newFilters = {...newFilters, "sponsor.name": value}
                } else {
                    delete newFilters['sponsor.name'];
                }
            }
            if (field === 'eventAttendance') {
                if (value) {
                    newFilters = {...newFilters, "eventAttendances.event.name": value}
                    newFilters = {...newFilters, "eventAttendances.attended": true}
                } else {
                    delete newFilters['eventAttendances.event.name'];
                    delete newFilters['eventAttendances.attended'];
                }
            }

            if (field === 'eventAttendanceRegistered') {
                if (value) {
                    newFilters = {...newFilters, "eventAttendances.event.name": value}
                } else {
                    delete newFilters['eventAttendances.event.name'];
                }
            }
            if (value)
                return newFilters;
            delete newFilters[field];
            return newFilters;
        });
    }, [onFiltersChange]);

    /**
     * Handle order change
     */
    const handleOrderChange = useCallbackCreator((field) => {
        onFiltersChange(filters => {
            const newFilters = {...filters};
            if (!filters[field])
                newFilters[field] = 'ASC';
            else if (filters[field] === 'ASC')
                newFilters[field] = 'DESC';
            else
                delete newFilters[field];
            return newFilters;
        });
    }, [onFiltersChange]);

    /**
     * Config filters for table
     */
    const filtersConfig = [
        {
            name: 'Id de registro',
            onChange: handleInputChange('id'),
            value: filters.id,
            type: 'text',
            placeholder: 'Filtrar por Id de registro',
            orderOnChange: handleOrderChange('order[id]'),
            orderStatus: filters['order[id]'],
        },

        {
            name: 'nombre',
            onChange: handleInputChange('name'),
            value: filters.name,
            type: 'text',
            placeholder: 'Filtrar por nombre',
            orderOnChange: handleOrderChange('order[name]'),
            orderStatus: filters['order[name]'],
        },
        {
            name: 'Apellido paterno',
            onChange: handleInputChange('patLastName'),
            value: filters.patLastName,
            type: 'text',
            placeholder: 'Filtrar por apellido paterno',
            orderOnChange: handleOrderChange('order[patLastName]'),
            orderStatus: filters['order[patLastName]'],
        },
        {
            name: 'Apellido materno',
            onChange: handleInputChange('matLastName'),
            value: filters.matLastName,
            type: 'text',
            placeholder: 'Filtrar por apellido materno',
            orderOnChange: handleOrderChange('order[matLastName]'),
            orderStatus: filters['order[matLastName]'],
        },

        {
            name: 'Institución',
            onChange: handleInputChange('institution'),
            value: filters.institution,
            type: 'text',
            placeholder: 'Filtrar por institución',
            orderOnChange: handleOrderChange('order[institution]'),
            orderStatus: filters['order[institution]'],
        },
        {
            name: 'Especialidad',
            onChange: handleInputChange('specialty'),
            value: filters.specialty,
            type: 'text',
            placeholder: 'Filtrar por especialidad',
            orderOnChange: handleOrderChange('order[specialty]'),
            orderStatus: filters['order[specialty]'],
        },

        {
            name: 'Tipo',
            onChange: handleInputChange('type'),
            value: filters.type,
            type: 'select',
            options: ['BECADO', 'COORDINADOR', 'DOCTOR', 'PONENTE'],
            optionsText: {
                'BECADO': 'BECADO',
                'COORDINADOR': 'COORDINADOR',
                'DOCTOR': 'DOCTOR',
                'PONENTE': 'PONENTE'
            },
            placeholder: 'Filtrar por tipo',
            orderOnChange: handleOrderChange('order[type]'),
            orderStatus: filters['order[type]'],
            label: 'Tipo'
        },
        {
            name: 'Patrocinador',
            onChange: handleInputChange('sponsor'),
            value: filters.sponsor,
            type: 'select',
            options: sponsors.map(sponsor => sponsor.name),
            optionsText: getSponsorsList(),
            placeholder: 'Filtrar por patrocinador',
            orderOnChange: handleOrderChange('order[sponsor.name]'),
            orderStatus: filters['order[sponsor.name]'],
            label: 'Patrocinador'
        },
        {
            name: 'eventAttendance',
            onChange: handleInputChange('eventAttendance'),
            value: filters.eventAttendance,
            type: 'select',
            options: events.map(event => event.name),
            optionsText: getEventsList(),
            label: 'Asistencia a evento'
            // orderOnChange: handleOrderChange('order[sponsor.name]'),
            // orderStatus: filters['order[sponsor.name]'],
        },
        {
            name: 'eventAttendanceRegistered',
            onChange: handleInputChange('eventAttendanceRegistered'),
            value: filters.eventAttendanceRegistered,
            type: 'select',
            options: events.map(event => event.name),
            optionsText: getEventsList(),
            label: 'Registro a evento'
            // orderOnChange: handleOrderChange('order[sponsor.name]'),
            // orderStatus: filters['order[sponsor.name]'],
        },
    ];

    const excelUrl = useCallback(() => {
            window.location.href = api.people.exportUrl(filters, 'Doctores', 'xlsx');
        }, [api.people, filters]
    );

    return (
        <div className="SponsorsAdminHeader d-flex flex-row w-100">
            <div className="card-toolbar mt-5 ml-2">
                <ul className="nav nav-pills nav-pills-sm nav-dark-75 justify-content-center justify-content-md-end">
                    <li className="nav-item btn-amcemig">
            <span
                onClick={excelUrl}
                className="excelButton btn d-flex justify-content-center"
            >
              <FontAwesomeIcon icon={faFileExcel} className="text-white"/>
            </span>
                    </li>
                    <li className="nav-item btn-amcemig">
            <a
                href={api.people.exportUrl(filters, 'Doctores', 'pdf')}
                target={"_blank"}
                className="excelButton btn d-flex justify-content-center"
                rel="noreferrer"
            >
              <FontAwesomeIcon icon={faFilePdf} className="text-white"/>
            </a>
                    </li>
                </ul>
            </div>
            <div className="d-flex justify-content-md-start">
                <TideTableFilters inputsObject={filtersConfig}/>
            </div>
            {
                securityManager.role !== 'ROLE_GENERAL_USER' &&
                <div>
                    <ImportPerson
                        type="DOCTOR"
                        onSuccess={onSuccessImportPerson}
                    ></ImportPerson>
                </div>
            }
        </div>
    )
}

export default PersonsAdminHeader;
