import moment from "moment";

export const formatDate = ( date ) => {
  return moment(date).format("DD/MM/YYYY HH:mm");
};

export const parseDate = (date) => {
  const dateSplitted = date.split('T');
  const hourSplitted = dateSplitted[1].split('+');
  return moment(dateSplitted[0] + 'T' +hourSplitted[0]).format("DD/MM/YYYY HH:mm");
};