import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import "./PersonModal.scss"

export default function PersonModal(
  {person, handleCloseModal}
) {
  return (
    <div className='PersonModal'>
      <div className="card card-custom gutter-b">
        <div className="card-header border-0 pt-2">
          <h3 className="card-title align-items-start flex-column mb-1">
            <span className="card-label font-size-h3 font-weight-bolder text-dark">
              Información del registro
            </span>
          </h3>
          <div
            className="close-container d-flex justify-content-end pt-2 cursor-pointer"
            onClick={handleCloseModal}
          >
            <FontAwesomeIcon icon={faTimes} className='close-icon pink-color'/>
          </div>
        </div>
        <div className="card-body pt-0">
            <ul className='list-group'>
              <li className="list-group-item">
                <span className='text-dark text-uppercase field-name'>
                  {person.title} {person.name} {person.patLastName} {person.matLastName}
                </span>
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  Teléfono:
                </span>
                {person.phone}
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  Institución:
                </span>
                {person.institution}
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  Especialidad:
                </span>
                {person.speciality}
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  País:
                </span>
                {person.country?.name}
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  Correo electrónico:
                </span>
                {person.email}
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  RFC:
                </span>
                {person.rfc}
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  Código postal:
                </span>
                {person.zipCode}
              </li>
              {
                person.fiscalRegime &&
                <li className="list-group-item">
                  <span className='text-dark field-name'>
                    Régimen fiscal:
                  </span>
                  {person.fiscalRegime.name}
                </li>
              }
              {
                person.fiscalRegimeForeigner &&
                <li className="list-group-item">
                  <span className='text-dark field-name'>
                    Régimen fiscal Foráneo:
                  </span>
                  {person.fiscalRegimeForeigner}
                </li>
              }
              
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  Razón social:
                </span>
                {person.socialReason}
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  RFC Razón social:
                </span>
                {person.socialReasonRfc}
              </li>
              <li className="list-group-item">
                <span className='text-dark field-name'>
                  Dirección fiscal:
                </span>
                {person.fiscalAddress}
              </li>
            </ul>
        </div>
      </div>
    </div>
  )
}
