import React from 'react';
import _ from 'lodash';

const SelectInput = ( { input } ) => {
  return (
    <div className="SelectInput inputContainer">
      {input.label &&
        <label htmlFor={input.name} className={`font-bold ${input.labelColor||"text-info"}`}>
          {input.label}
        </label>
      }
      <select
        className="form-control"
        id={input.name}
        value={input.value}
        onChange={input.onChange}
      >
        <option value=''>
          {input.selectPlaceholder ? input.selectPlaceholder : 'Selecciona una opción'}
        </option>
        {_.map( input.options, (val)=>
          <option value={val} key={val}>{input.optionsText[val]}
          </option>
        )}
      </select>
    </div>
  );
}
 
export default SelectInput;