import React, {useContext, useCallback, useEffect} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import useFormState from '../../../../../hooks/useFormState';
import {ApiContext} from '../../../../../services/api/api-config';
import {useSelector} from "react-redux";
import {getNotifier} from '../../../../../services/notifier';
import "./AnnualitysForm.scss"

export default function AnnualitysForm(
    {edit, closeModal}
) {

    const api = useContext(ApiContext);
    const {form, handleInputChange, setForm} = useFormState({});

    useEffect(() => {
        if (edit) {
            setForm({
                type: edit.type,
                concept: edit.concept,
                year: edit.year,
                amount: edit.amount
            });
        }
    }, [edit, setForm]);

    const annualityAreValid = useCallback(() => {
        let result = true;

        if (form.year === '') {
            return result = false
        }
        if (form.type === '') {
            return result = false
        }
        if (form.amount === '') {
            return result = false
        }

        return result;
    }, [form]);

    const handleSaveAnnuality = useCallback(() => {
        const apiMethod = edit ? 'update' : 'create';
        api.annualities[apiMethod]({
            ...(edit && {id: edit.id + ''}),
            loadingId: 'AnnualitysForm',
            params: {
                ...form
            },
        }).then(closeModal);

    }, [api, form, closeModal, edit]);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        const errorMessage = 'La información completa de la anualidad es requerida.';
        if (!annualityAreValid()) {
            return getNotifier().error(errorMessage);
        }
        handleSaveAnnuality();
    }, [annualityAreValid, handleSaveAnnuality]);

    const isLoading = useSelector(s => !!s.loadingIds['AnnualitysForm'])

    return (
        <div className='AnnualitysForm'>
            <div className="card card-custom gutter-b">
                <div className="card-header border-0 pt-2">
                    <h3 className="card-title align-items-start flex-column mb-3">
              <span className="card-label font-size-h3 font-weight-bolder text-dark">
                {edit ? 'Editar Anualidad' : 'Añadir Anualidad'}
              </span>
                    </h3>
                    <div
                        className="close-container d-flex justify-content-end pt-2 cursor-pointer"
                        onClick={closeModal}
                    >
                        <FontAwesomeIcon
                            icon={faTimes}
                            className='close-icon pink-color'
                        />
                    </div>
                </div>
                <div className="card-body pt-0">
                    <form>
                        <div className="mt-6">
                            <div className="form-group mb-8">
                                <label className="font-weight-bolder">Tipo de Anualidad</label>
                                {/* <select className="form-control"
                                        id="typeSelect"
                                        value={form.type || 'Selecciona una opción'}
                                        onChange={handleInputChange('type')}
                                >
                                    <option value="">Seleccionar...</option>
                                    <option value="AMCEMIG">
                                        AMCEMIG
                                    </option>
                                    <option value="IHS">
                                        IHS
                                    </option>
                                </select> */}
                                <input
                                    type="text"
                                    className="form-control form-control-solid form-control-lg"
                                    value={form.type || ''}
                                    onChange={handleInputChange('type')}
                                    placeholder="Ingresa el tipo"
                                    maxLength={200}
                                />
                            </div>
                        </div>
                        <div className="mt-6">
                            <div className="form-group mb-8">
                                <label className="font-weight-bolder">Concepto</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid form-control-lg"
                                    value={form.concept || ''}
                                    onChange={handleInputChange('concept')}
                                    placeholder="Ingresa el concepto"
                                    maxLength={200}
                                />
                            </div>
                        </div>
                        <div className="mt-6">
                            <div className="form-group mb-8">
                                <label className="font-weight-bolder">Año</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid form-control-lg"
                                    value={form.year || ''}
                                    onChange={handleInputChange('year')}
                                    placeholder="Ingresa el año"
                                    maxLength={200}
                                />
                            </div>
                        </div>
                        <div className='mt-6'>
                            <div className="form-group mb-8">
                                <label className="font-weight-bolder">Costo de la anualidad</label>
                                <input
                                    type="number"
                                    min={1}
                                    className="form-control form-control-solid form-control-lg"
                                    value={form.amount || ''}
                                    onChange={handleInputChange('amount')}
                                    placeholder="Ingresa el costo"
                                />
                            </div>
                        </div>
                        <button
                            type="submit"
                            disabled={isLoading}
                            onClick={handleSubmit}
                            className="btn btn-primary font-weight-bolder mr-2 px-8"
                        >
                            Guardar
                        </button>
                        <button
                            type="reset"
                            className="btn btn-clear font-weight-bolder text-muted px-8"
                            onClick={closeModal}
                        >
                            Cancelar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}
