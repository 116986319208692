import React, { useCallback, useContext, useEffect, useState} from 'react';
import useBoolean from '../../../../../../hooks/useBoolean';
import useFormState from '../../../../../../hooks/useFormState';
import { ApiContext } from '../../../../../../services/api/api-config';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./ProfileEdit.scss";
import useDatePickerState from "../../../../../../hooks/useDatePickerState";
import {SingleDatePicker} from "react-dates";
import moment from "moment";
import 'moment/locale/es';
import { renderMonthElement } from '../../../../../../services/modelUtils/userUtils';
import _ from 'lodash';
import { getNotifier } from '../../../../../../services/notifier';
import {neighbourhoodSelectorSGroups} from "../../../../../../services/modelUtils/neighbourhoodUtils";


const ProfileEdit = ({me}) => {
    const {form, handleInputChange, setForm} = useFormState({});
    const [editing, editingOn, editingOff] = useBoolean();
    const [ neighbourhoods, setNeighbourhoods ] = useState([]);
    const api = useContext(ApiContext);
    const [isLoading, loadingOn, loadingOff] = useBoolean(false);
    const {props: birthdayProps, date} = useDatePickerState(me.profile?.birthday ? moment(me.profile?.birthday) : null);

    const submitProfileButton = useCallback(() => {
        if (!neighbourhoods || !form.neighbourhoodId)
           return getNotifier().error('Por favor completa tu información');

        const neighbourhoodName = _.find(neighbourhoods, (neighbourhood) =>  neighbourhood.id === parseInt(form.neighbourhoodId))?.name

        const params = {
            street: form.street,
            zipCode: form.zipCode,
            state: form.states,
            city: form.cities,
            suburb: neighbourhoodName,
            addressReference: form.ref,
            neighbourhood: form.neighbourhoodId + '',
            phone: form.phone,
            birthday: date?.format("YYYY-MM-DD") || null,
            lastNames: form.lastNames
        };

        if (!me.profile) {
            loadingOn();
            api.profiles.create({params: {user: me.id+'', ...params}}).then(() => {
                loadingOff();
                editingOff();
            }).catch(() => loadingOff());
        } else {
            loadingOn();
            api.users.update({id: me.id+'', params:{name: form.name, lastNames: form.lastNames, profile:{id: me.profile.id+'',...params}}}).then(() => {
                loadingOff();
                editingOff();
                api.me.get();
            }).catch(() => loadingOff());
        }

    }, [api, date, form, loadingOn, loadingOff, editingOff, me, neighbourhoods]);

    const setNewZipCode = useCallback((me) => {
        if (me.profile.zipCode) {
            api.neighbourhoods.get({params: { postalCode: me.profile.zipCode, sGroups: neighbourhoodSelectorSGroups } }).then((neighbourhoods) => {
                if (_.isEmpty(neighbourhoods)) { getNotifier().error('Por favor actualiza tu código postal') }
    
                const myNeighbourhood = _.find(neighbourhoods, (neighbourhood) => neighbourhood.id === me.profile.neighbourhood.id );
    
                setNeighbourhoods(neighbourhoods);
                setForm({
                    cities: myNeighbourhood?.municipality.name,
                    states: myNeighbourhood?.municipality.state.name,
                    neighbourhoodId: myNeighbourhood?.id,
                    street: me.profile.street,
                    suburb: me.profile.suburb,
                    zipCode: me.profile.zipCode,
                    ref: me.profile.addressReference,
                    lastNames: me.lastNames,
                    phone: me.profile.phone,
                    birthday: me.profile.birthday,
                    name: me.name
                })
            });
        } else {
            setForm({
                street: me.profile.street,
                suburb: me.profile.suburb,
                zipCode: me.profile.zipCode,
                ref: me.profile.addressReference,
                lastNames: me.lastNames,
                phone: me.profile.phone,
                birthday: me.profile.birthday,
                name: me.name
            })
        }
    }, [api, setForm]);

    useEffect(() => {
        if (!me.profile) { return editingOn() }

        setNewZipCode(me);
    }, [setNewZipCode, me, editingOn]);

    const handleZipCodeChange = useCallback((input) => {
        const postalCode = input.target.value;

        if (postalCode.length === 5) {
            api.neighbourhoods.get({ params: { postalCode: postalCode, sGroups: neighbourhoodSelectorSGroups } }).then((neighbourhoods) => {
                if (_.isEmpty(neighbourhoods)) { getNotifier().error('No encontramos tu código postal') }

                const randomNeighbourhood = _.sample(neighbourhoods);

                setNeighbourhoods(neighbourhoods);

                setForm({
                    ...form,
                    zipCode: postalCode,
                    states: randomNeighbourhood?.municipality.state.name,
                    cities: randomNeighbourhood?.municipality.name
                })
            });
        } else {
            setForm({...form, zipCode: postalCode});
        }
    }, [api, form, setForm]);

    return (
        <div className="ProfileEdit">
            <div className="mb-3">
                <div className="d-flex justify-content-between">
                    <h4 className="font-weight-bold magenta-color">
                        Mi información
                    </h4>
                    { (!editing) &&
                        <span className="btn btn-icon btn-light btn-sm" onClick={() => editingOn()}>
			            	<span className="svg-icon svg-icon-md svg-icon-success">
                                <FontAwesomeIcon icon={faEdit} className="h-75"/>
			            	</span>
			            </span>
                    }
                </div>
                {editing ?
                    <div className='text-center'>
                        <div className="mb-2 row">
                            <div className="col-lg-6 mt-4">
                                <h6 className='font-weight-bolder mb-0 text-info'>Nombre</h6>
                                <input type="text" className="form-control" name="billing_city" onChange={handleInputChange('name')} value={form.name||''} placeholder="Nombre(s)"/>
                            </div>
                            <div className="col-lg-6 mt-4">
                                <h6 className='font-weight-bolder mb-0 text-info'>Apellidos</h6>
                                <input type="text" className="form-control" onChange={handleInputChange('lastNames')} value={form.lastNames||''} placeholder="Apellidos"/>
                            </div>
                        </div>
                        <div className="mb-2 row">
                            <div className="col-lg-6 mt-5">
                                <h6 className='font-weight-bolder mb-0 text-info'>Teléfono</h6>
                                <input type="text" className="form-control" onChange={handleInputChange('phone')} name="billing_zip" placeholder="Teléfono" value={form.phone||''}/>
                            </div>
                            <div className="col-lg-6 mt-5">
                                <h6 className='font-weight-bolder mb-0 p-0 text-info'>Fecha de nto.</h6>
                                <SingleDatePicker
                                    {...birthdayProps}
                                    placeholder='Fecha de Nacimiento'
                                    id='birthdate_date'
                                    isOutsideRange={() => false}
                                    renderMonthElement={renderMonthElement}
                                    numberOfMonths={1}
                                />
                            </div>
                        </div>
                        <div className="row addressStart">
                            <div className="col-lg-6 mt-4">
                                <h6 className='font-weight-bolder mb-0 text-info'>C.P</h6>
                                <input type="text" className="form-control" onChange={handleZipCodeChange} placeholder="Código Postal" value={form.zipCode||''}/>
                            </div>
                            <div className="col-lg-6 mt-4">
                                <h6 className='font-weight-bolder mb-0 text-info'>Estado</h6>
                                <input type="text" readOnly={true} className="form-control bg-secondary" onChange={handleInputChange('states')} value={form.states||''}/>
                            </div>

                            <div className="col-lg-6 mt-4">
                                <h6 className='font-weight-bolder mb-0 text-info'>Ciudad</h6>
                                <input type="text" readOnly={true} className="form-control bg-secondary" onChange={handleInputChange('cities')} value={form.cities||''}/>
                            </div>
                            <div className="col-lg-6 mt-4">
                                <h6 className='font-weight-bolder mb-0 text-info'>Colonia</h6>
                                <select className="form-control" value={form.neighbourhoodId||'null'} onChange={handleInputChange('neighbourhoodId')}>
                                    <option value='null' disabled={true}>Selecciona</option>
                                    {_.map( neighbourhoods, (neighbourhood)=>
                                        <option value={neighbourhood.id} key={neighbourhood.id}>
                                            {neighbourhood.name}
                                        </option>
                                    )}
                                </select>
                            </div>
                            <div className="col-lg-12 mt-4">
                                <h6 className='font-weight-bolder mb-0 text-info'>Calle y número</h6>
                                <input type="text" name="billing_address_1" className="form-control" onChange={handleInputChange('street')} placeholder="Escribe tu calle" value={form.street||''} />
                            </div>

                            <div className="col-lg-12 mt-4">
                                <h6 className='font-weight-bolder mb-0 text-info'>Referencias</h6>
                                <input type="text" className="form-control" onChange={handleInputChange('ref')} name="billing_zip" placeholder="Añade referencias" value={form.ref||''}/>
                            </div>
                        </div>
                        {editing &&
                        <div className="card-footer">
				    		<button type="reset" className="btn btn-info mr-2" disabled={isLoading} onClick={submitProfileButton}>Aceptar</button>
				    		<button type="reset" className="btn btn-danger" onClick={() => editingOff()}>Cancelar</button>
				    	</div>
                        }
                    </div> :
                    <div className="container-fluid text-center">
                        <div className="row mt-4">
                            <div className="col-6 pl-0">
                                <h6 className='font-weight-bolder text-info'>
                                    Nombre
                                </h6>
                                <p className='text-danger'>
                                    {me.name||'Sin información'}
                                </p>
                            </div>
                            <div className="col-6 pl-0">
                                <h6 className='font-weight-bolder text-info'>
                                    Apellidos
                                </h6>
                                <p className='text-danger'>
                                    {me.lastNames||'Sin información'}
                                </p>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-6 pl-0">
                                <h6 className='font-weight-bolder text-info'>
                                    Teléfono
                                </h6>
                                <p className='text-danger'>
                                    {me.profile?.phone||'Sin información'}
                                </p>
                            </div>
                            <div className="col-6 pl-0">
                                <h6 className='font-weight-bolder text-info'>
                                    Fecha de nacimiento
                                </h6>
                                <p className='text-danger'>
                                    {moment(me.profile?.birthday).format("LL")||'Sin información'}
                                </p>
                            </div>
                        </div>
                        <div className="row mt-2 addressStart">
                            <div className="col-6 pl-0">
                                <h6 className='font-weight-bolder text-info'>
                                    Calle
                                </h6>
                                <p className='text-danger'>
                                    {me.profile?.street||'Sin información'}
                                </p>
                            </div>
                            <div className="col-6 pl-0">
                                <h6 className='font-weight-bolder text-info'>
                                    Colonia
                                </h6>
                                <p className='text-danger'>
                                    {me.profile?.suburb||'Sin información'}
                                </p>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-6 pl-0">
                                <h6 className='font-weight-bolder text-info'>
                                    Ciudad
                                </h6>
                                <p className='text-danger'>
                                    {me.profile?.city||'Sin información'}
                                </p>
                            </div>
                            <div className="col-6 pl-0">
                                <h6 className='font-weight-bolder text-info'>
                                    Estado
                                </h6>
                                <p className='text-danger'>
                                    {me.profile?.state||'Sin información'}
                                </p>
                            </div>
                        </div>
                        <div className="row mt-4 addressEnd">
                            <div className="col-6 pl-0">
                                <h6 className='font-weight-bolder text-info'>
                                    C.P
                                </h6>
                                <p className='text-danger'>
                                    {me.profile?.zipCode||'Sin información'}
                                </p>
                            </div>
                            <div className="col-6 pl-0">
                                <h6 className='font-weight-bolder text-info'>
                                    Referencias
                                </h6>
                                <p className='text-danger'>
                                    {me.profile?.addressReference||'Sin información'}
                                </p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

ProfileEdit.displayName="ProfileEdit";

export default ProfileEdit;
