import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEye,
    faTrash,
    faEdit, faCheck, faTimes,
} from '@fortawesome/free-solid-svg-icons';

export const getPeopleTableColumns = (
    {api, onPaymentsClick, onDetailsClick, onRemoveClick, onEditPerson}
) => [
    {
        Header: "Id de registro",
        accessor: "id"
    },
    {
        Header: "Secuencial",
        accessor: "sequential"
    },
    {
        Header: "Tipo",
        accessor: "type"
    },
    {
        Header: "Título",
        accessor: person =>
            <div>
                <p className='mb-0 font-weight-bolder'>
                    {person.title || 'Sin información'}
                </p>
            </div>
    },
    {
        Header: "Nombre(s)",
        accessor: person =>
            <div>
                <p className='mb-0 font-weight-bolder text-break'>
                    {person.name || 'Sin información'}
                </p>
            </div>
    },
    {
        Header: "Apellido paterno",
        accessor: person =>
            <div>
                <p className='mb-0 font-weight-bolder'>
                    {person.patLastName || 'Sin información'}
                </p>
            </div>
    },
    {
        Header: "Apellido materno",
        accessor: person =>
            <div>
                <p className='mb-0 font-weight-bolder'>
                    {person.matLastName || 'Sin información'}
                </p>
            </div>
    },
    {
        Header: "Institución",
        accessor: person =>
            <div>
                <p className='mb-0 font-weight-bolder text-break'>
                    {person.institution || 'Sin información'}
                </p>
            </div>
    },
    {
        Header: "Especialidad",
        accessor: person =>
            <div>
                <p className='mb-0 font-weight-bolder text-break'>
                    {person.speciality || 'Sin información'}
                </p>
            </div>
    },
    {
        Header: "Patrocinador",
        accessor: person =>
            <div>
                <p className='mb-0 font-weight-bolder'>
                    {person.sponsor ? person.sponsor.name : 'sin información'}
                </p>
            </div>
    },
    {
        Header: "Registros",
        accessor: person =>
            <div>
                <ul className='mb-0 font-weight-bolder'>
                    {person.eventAttendances && person.eventAttendances.map((eventAttendance) => (
                        <li>{eventAttendance.attended? <FontAwesomeIcon icon={faCheck} className='add-icon mr-3'/>:
                            <FontAwesomeIcon icon={faTimes} className='add-icon mr-3'/>
                        } {eventAttendance.event.name }</li>
                    ))}

                </ul>
            </div>
    },
    {
        Header: 'Acciones',
        accessor: person =>
            (
                <div className="actionButtonsContainer d-flex flex-row">
                    {/* {
            person.paymentsRegistries && person.paymentsRegistries.length > 0 &&
            <button
              className="btn btn-outline-primary font-weight-bolder m-1"
              onClick={() => onPaymentsClick(person.paymentsRegistries[0].payments)}
            >
              <FontAwesomeIcon icon={faDollarSign} className='dashboard-logo icon-dash-logo text-dark'/>
            </button>
          } */}
                    <button
                        className="btn btn-outline-success font-weight-bolder m-1"
                        onClick={() => onDetailsClick(person)}>
                        <FontAwesomeIcon icon={faEye} className='dashboard-logo icon-dash-logo text-dark'/>
                    </button>
                    <button
                        className="btn btn-outline-danger font-weight-bolder m-1"
                        onClick={() => onRemoveClick(person)}>
                        <FontAwesomeIcon icon={faTrash} className='dashboard-logo icon-dash-logo text-dark'/>
                    </button>
                    <button
                        className="btn btn-outline-secondary font-weight-bolder m-1"
                        onClick={() => onEditPerson(person)}>
                        <FontAwesomeIcon icon={faEdit} className='dashboard-logo icon-dash-logo text-dark'/>
                    </button>
                </div>
            )
    }
];

/* sGroups used in Products CRUD from ProductsIndexComponent to ProductsForm :: BEGIN */
export const peopleSGroups = [
    'person_read',
    'person_read_country',
    'country_read',
    'person_read_fiscal_regime',
    'fiscal_regime_read',
    'person_read_payments_registry_read',
    'payments_registry_read',
    'payments_registry_read_payments',
    'payment_read',
    'payment_read_payment_method',
    'payment_method_read',
    'payment_read_sponsor',
    'sponsor_read',
    'payments_registry_read_registrations',
    'registration_read',
    'registration_read_anuality',
    'registration_read_event_price',
    'person_read_sponsor',
    'sponsor_read',
    'person_read_event_attendance',
    'event_attendance_read',
    'event_attendance_read_event',
    'event_read'
];

export const PaymentsRegistrySGroups = [
    'payments_registry_read',
    'payments_registry_read_person',
    'payments_registry_read_registrations',
    'registration_read',
    'registration_read_anuality',
    'annuality_read',
    'registration_read_event_price',
    'event_price_read',
    'event_price_read_event',
    'event_read',
    'person_read',
    'payments_registry_read_payments',
    'payment_read',
    'payment_read_payment_method',
    'payment_method_read',
    'payment_read_sponsor',
    'sponsor_read',
    'event_read',
    'person_read_country',
    'country_read',
    'person_read_fiscal_regime',
    'fiscal_regime_read',
    'blame_creation',
    'creation_date',
    'user_read_username',
    'person_read_event_attendance',
    'event_attendance_read',
    'event_attendance_read_event',
    'event_read'
];