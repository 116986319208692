import React, {useState, useContext, useCallback, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { ApiContext } from '../../../services/api/api-config';
import { getNotifier } from '../../../services/notifier';
import useBoolean from '../../../hooks/useBoolean';
import ScholarshipForm from './components/ScholarshipForm/ScholarshipForm';
import ScholarshipAdminHeader from './components/ScholarshipAdminHeader/ScholarshipAdminHeader';
import ScholarshipTableView from './components/ScholarshipTableView/ScholarshipTableView';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import { ScholarshipSGroups } from '../../../services/modelUtils/scholarshipUtils';
import "./Scholarships.scss"

export default function Scholarships() {

  let { id } = useParams();
  const [edit, setEdit] = useState();
  const [addingScholarship, startAddingScholarship, stopAddingScholarship] = useBoolean();
  const [filters, setFilters] = useState({'order[createdDate]':'DESC', 'type':'BECADO'});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const [sponsors, setSponsors] = useState([])
  const [first, setFirst] = useState(true)

  const api = useContext(ApiContext);

  const getSponsors = useCallback(() => {
    api.sponsors.get().then(response => {
      setSponsors(response);
    }).catch((err) => {
      getNotifier().error(err.detail);
    });
  }, [api.sponsors]);

  // Handle close form modal
  const handleCloseForm = useCallback(()=>{
		setEdit(null);
		stopAddingScholarship();
	},[stopAddingScholarship]);

  const deleteScholarship = useCallback(() => {
    api.people.delete({id: idToDelete})
    .then(() => {
      setShowDeleteConfirm(false);
    })
    .catch((err) => {
      getNotifier().error(err.detail);
    });
  }, [api.people, idToDelete]);

  const onDeleteScholarship = useCallback((scholarship) => {
    setShowDeleteConfirm(true);
    setIdToDelete(scholarship.id);
  }, []);

  const reload = useCallback(() => {
    api.people.get({params: {type: 'BECADO', 'order[createdDate]':'DESC', sGroups: ScholarshipSGroups}})
  }, [api.people]);

  const getScholarships = useCallback(() => {
    api.people.get({
      params: {
        type: 'BECADO',
        'order[createdDate]':'DESC',
        sGroups: ScholarshipSGroups
      }
    }).then(response => {
    }).catch((err) => {
      getNotifier().error(err.detail);
    });
  }, [api.people]);

  const updateAttended = useCallback((eventAttendance, maxSequential) => {
    api.eventAttendances.update({
      id: eventAttendance.id,
      params: {
        attended: true,
        sequential: maxSequential + 1
      }
    }).then(response => {
      getNotifier().success('Se actualizó correctamente');
      getScholarships();
    }).catch(
      error => getNotifier().error('Ocurrió un error al actualizar la asistencia')
    );
  }, [api.eventAttendances, getScholarships]);

  const getMaxSequential = useCallback((eventAttendance) => {
    api.eventAttendances.get({
      params: {
        'event.id': eventAttendance.event.id,
        attended: true
      }
    }).then(response => {
      updateAttended(eventAttendance, response.length);
    }).catch(
      error => getNotifier().error('Ocurrió un error al actualizar la asistencia')
    );
  }, [api.eventAttendances, updateAttended]);

  const onScholarshipAttended = useCallback((eventAttendance) => {
    if (!eventAttendance.attended) {
      getMaxSequential(eventAttendance);
    } else {
      api.eventAttendances.update({
        id: eventAttendance.id,
        params: {
          attended: !eventAttendance.attended
        }
      }).then(response => {
        getNotifier().success('Se actualizó correctamente');
        getScholarships();
      }).catch(
        error => getNotifier().error('Ocurrió un error al actualizar la asistencia')
      );
    }
  }, [api.eventAttendances, getMaxSequential, getScholarships]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      getSponsors();
    }
  }, [first, getSponsors])
  

  return (
    <div className='Scholarships'>
      <div className="d-flex flex-row">
        {
            !!(edit || addingScholarship) &&
            <div className="flex-column offcanvas-mobile mx-auto" id="kt_profile_aside">
              <ScholarshipForm
                edit={edit}
                closeModal={handleCloseForm}
                sponsorId={id}
              />
            </div>
          }
          <div className="flex-row-fluid">
            <div className="card speakers-index card-custom card-stretch gutter-b">
              <div className="card-body">
                <div className="mb-11">
                  <ScholarshipAdminHeader
                    onNewScholarshipClick = {startAddingScholarship}
                    filters = {filters}
                    onFiltersChange = {setFilters}
                    onSuccessImportPerson={reload}
                    sponsors={sponsors}
                  />
                  <ScholarshipTableView
                    onScholarshipEdit = {setEdit}
                    onDeleteScholarship = {onDeleteScholarship}
                    filters = {filters}
                    onScholarshipAttended={onScholarshipAttended}
                  />
                </div>
                
                {
                  showDeleteConfirm &&
                  <div className="flex-column offcanvas-mobile mx-auto SpeakerModal" id="kt_profile_aside">
                    <div className="card card-custom gutter-b">
                      <div className="card-header border-0 pt-2">
                          <h3 className="card-title align-items-start flex-column mb-3">
                            <span className="card-label font-size-h3 font-weight-bolder text-dark">
                              Confirmar eliminar Becado
                            </span>
                          </h3>
                        <div
                          className="close-container d-flex justify-content-end pt-2 cursor-pointer"
                          onClick={() => setShowDeleteConfirm(false)}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            className='close-icon pink-color'
                          />
                        </div>
                      </div>
                      <div className="card-body pt-0">
                        <h3>
                          ¿Está seguro de eliminar el becado?
                        </h3>
                        <div>
                        <button
                          type="button"
                          onClick={deleteScholarship}
                          className="btn btn-primary font-weight-bolder mr-2 px-8"
                        >
                          Confirmar
                        </button>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}
