import React, {useContext, useMemo} from 'react'
import TideReactTable from '../../../../../components/TideReactTable/TideReactTable';
import useTideTable from '../../../../../hooks/useTideTable';
import { ApiContext } from '../../../../../services/api/api-config';
import {CoordinatorsSGroups} from '../../../../../services/modelUtils/CoordinatorUtils';
import { getCoordinatorsTableColumns } from '../../CoordinatorsIndexUtils';
import './CoordinatorsTableView.scss'

export default function CoordinatorsTableView(
  {onCoordinatorEdit, deleteCoordinator, filters, onCoordinatorAttended}
) {

  const api = useContext(ApiContext);

  /**
   * Get columns for speakers table
   */
   const coordinatorsTableColumns = useMemo(() => getCoordinatorsTableColumns(
    { api, deleteCoordinator, onCoordinatorEdit, onCoordinatorAttended }
  ), [api, deleteCoordinator, onCoordinatorEdit, onCoordinatorAttended]);

  /**
   * Set filters for speakers table
   */
   const coordinatorsFilters = useMemo(()=>({
    pagination: true,
    sGroups: CoordinatorsSGroups,
    ...filters
  }),[filters]);

  /**
   * Create speakers tide table
   */
   const coordinatorsTable = useTideTable({
    entity: 'people',
    columns: coordinatorsTableColumns,
    requestFilters: coordinatorsFilters,
  });

  return (
    <div
      className="CoordinatorsTableView card card-custom card-shadowless product-container"
    >
      <div className="card-body p-5">
        <TideReactTable {...coordinatorsTable.tableProps}/>
      </div>
    </div>
  )
}
