import React, {useContext, useEffect, useCallback} from 'react'
import { ApiContext } from '../../../../../services/api/api-config';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import useFormState from '../../../../../hooks/useFormState';
import {useSelector} from "react-redux";
import { getNotifier } from '../../../../../services/notifier';
import "./SponsorForm.scss";

const SponsorForm = (
  {edit, closeModal}
) => {
  const api = useContext(ApiContext);
  const {form, handleInputChange, setForm} = useFormState({});

  useEffect(() => {
		if (edit) {
			setForm({
				name: edit.name
			});
			//setDistributorPrices(edit.productPrices||[]);
		}
	}, [edit, setForm]);

  const handleSaveSponsor = useCallback(() => {
		const apiMethod = edit ? 'update' : 'create';
		api.sponsors[apiMethod]({
			...(edit && {id: edit.id+''}),
			loadingId:'SponsorForm',
			params: {
				...form
			},
		}).then(closeModal);

	}, [api, form, closeModal, edit]);

  const handleSubmit = useCallback((e) => {
		e.preventDefault();
		const errorMessage = 'El nombre del patrocinador no es válido.';
		if (!form.name) {
      return getNotifier().error(errorMessage)
    }
		handleSaveSponsor();
	}, [handleSaveSponsor, form]);

  const isLoading = useSelector(s=>!!s.loadingIds['SponsorForm'])

  return (
    <div className='SponsorForm'>
      <div className="card card-custom gutter-b">
        <div className="card-header border-0 pt-2">
            <h3 className="card-title align-items-start flex-column mb-1">
              <span className="card-label font-size-h3 font-weight-bolder text-dark">
                { edit ? 'Editar patrocinador' : 'Añadir nuevo patrocinador' }
              </span>
            </h3>
          <div
            className="close-container d-flex justify-content-end pt-2 cursor-pointer"
            onClick={closeModal}
          >
            <FontAwesomeIcon icon={faTimes} className='close-icon pink-color'/>
          </div>
        </div>
        <div className="card-body pt-0">
          <form>
            <div className="mt-6">
              <div className="form-group mb-8">
                <label className="font-weight-bolder">Nombre</label>
                <input
                  type="text"
                  className="form-control form-control-solid form-control-lg"
                  value={form.name || ''}
                  onChange={handleInputChange('name')}
                  placeholder="Ingresa el nombre del patrocinador"
                  maxLength={200}
                />
              </div>
            </div>
            <button
              type="submit"
              disabled={isLoading}
              onClick={handleSubmit}
              className="btn btn-primary font-weight-bolder mr-2 px-8"
            >
              Guardar
            </button>
			    	<button
              type="reset"
              className="btn btn-clear font-weight-bolder text-muted px-8"
              onClick={closeModal}
            >
              Cancelar
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SponsorForm;