import React from 'react';
import semver from 'semver-lite';
import {version} from '../../pckg';
import qs from "qs";
import {getNotifier} from "../notifier";
import {PaymentsRegistrySGroups} from '../../scenes/Logged/Persons/PersonsIndexUtils'
import {PaymentSGroups} from '../../scenes/Logged/Payments/PaymentsIndexUtils'
import {SpeakersSGroups} from '../../services/modelUtils/SpeakerUtils';
import {CoordinatorsSGroups} from '../../services/modelUtils/CoordinatorUtils';
import moment from "moment";

function errorHandler(e, callParams) {

    //If the user's session ended, do not show the error notifier
    if (e?.status === 401 || e?.code === 401) {
        return;
    }
    if (!callParams?.config?.preventNotifier)
        getNotifier().error((e?.detail) || 'Parece que hubo un error', '¡Oh no!');
}

const querySerialize = (obj) => qs.stringify(obj, {arrayFormat: 'brackets'});

const config = {
    host: 'https://registro-back.amcemig.com',
    commonPath: 'api',
    credentials: 'include',
    saveTokenToLocalStorage: true,
    getDataFromResponse: handleResponse,
    getMetaDataFromResponse: (r) => r.meta,
    onError: errorHandler,
    strictMode: false,
    handleUnknownMethods: true,
    appendHeaders: function (headers, callConfig) {//Create a requestLog so we can send it with the crash data
        if (!this.requestLog)
            this.requestLog = [];
        const callInfo = callConfig.path + ' PARAMS ' + JSON.stringify(callConfig.params);
        this.requestLog.unshift(callInfo);
        this.requestLog = this.requestLog.slice(0, 10);
    },
    endpoints: [
        'me',
        'payment_methods',
        {
            name: 'sponsors',
            customMethods: {
                exportExcelUrl: function (filters) {
                    const reportConfig = {
                        name: `patrocinadores.xlsx`,
                        title: 'Patrocinadores AMCEMIG',
                        headers: {
                            'id': 'Id',
                            'name': 'Nombre'
                        }
                    };
                    const query = querySerialize({
                        ...filters,
                        pagination: false,
                        token: this.token,
                        reportConfig:
                            JSON.stringify(reportConfig),
                    });
                    return `${config.host}/api/sponsors.xlsx?${query}`;
                },
                getDownloadPdfUrl: function (id, eventId) {
                    return `${this.host}/api/sponsors/pdf/${id}/${eventId}?token=${this.token}`
                },
                getDownloadExcelUrl: function (id, eventId) {
                    return `${this.host}/api/sponsors/excel/${id}/${eventId}?token=${this.token}`
                },
            }
        },
        {
            name: 'events',
            customMethods: {
                exportExcelUrl: function (filters) {
                    const reportConfig = {
                        name: `eventos.xlsx`,
                        title: 'Eventos AMCEMIG',
                        headers: {
                            'id': 'Id',
                            'name': 'Nombre',
                            'date': 'Fecha',
                            'place': 'Lugar'
                        }
                    };
                    const query = querySerialize({
                        ...filters,
                        pagination: false,
                        token: this.token,
                        reportConfig:
                            JSON.stringify(reportConfig),
                    });
                    return `${config.host}/api/events.xlsx?${query}`;
                },
                getRegisters: function (id) {
                    return this.apiCall('/events/getRegisters/' + id, "error_sent", {}, {
                        method: "GET",
                        queueable: true,
                        useCommonPath: true
                    })
                }
            }
        },
        'eventPrices',
        'countries',
        {
            name: 'people',
            customMethods: {
                exportUrl: function (filters, filename, type) {
                    const currentDateTimeAsString = moment().format('DD-MM-YYYY HH:mm:ss');
                    let title = `Doctores registrados AMCEMIG ${currentDateTimeAsString}`;

                    if (filters?.eventAttendance) {
                        title= title + ` | ${filters.eventAttendance}`;
                    }

                    if(filters?.sponsor){
                        title= title + ` | ${filters.sponsor}`;
                    }


                    const reportConfig = {
                        name: `${filename}.xlsx`,
                        title: title,
                        headers: {
                            'id': 'Id',
                            'sequential': 'Secuencial',
                            'type': 'Tipo',
                            'title': 'Título',
                            'name': 'Nombre',
                            'patLastName': 'Apellido paterno',
                            'matLastName': 'Apellido materno',
                            'phone': 'Teléfono',
                            'institution': 'Institución',
                            'speciality': 'Especialidad',
                            'email': 'Correo electrónico',
                            'rfc': 'RFC',
                            'sponsor.name': 'Patrocinador',
                            'country.name': 'País',
                            'zipCode': 'Código postal',
                            'fiscalRegime.name': 'Régimen fiscal',
                            'socialReason': 'Razón social',
                            'socialReasonRfc': 'RFC Razón social'
                        }
                    };
                    const query = querySerialize({
                        ...filters,
                        sGroups: PaymentsRegistrySGroups,
                        pagination: false,
                        token: this.token,
                        reportConfig:
                            JSON.stringify(reportConfig),
                    });
                    return `${config.host}/api/people.${type}?${query}`;
                },
                importExcelPersons: function ({params, files, ...conf}) {
                    return this.apiCall(
                        '/persons/import',
                        conf.customProp || 'importPersons',
                        params,
                        {
                            ...conf,
                            method: "POST"
                        },
                        files
                    )
                },
                printTag: function (id) {
                    return this.apiCall('/persons/print/' + id, "error_sent", {}, {
                        method: "GET",
                        queueable: true,
                        useCommonPath: true
                    })
                },
                getToken: function () {
                    return `${this.token}`;
                },
                printTagOnly: function (params, ...conf) {
                    return this.apiCall('/persons/print_tag_only/', "error_sent", params, {...conf, method: "POST"})
                },
            }
        },
        'fiscal_regimes',
        {
            name: 'payments',
            customMethods: {
                exportExcelUrl: function (filters) {
                    const reportConfig = {
                        name: `pagos.xlsx`,
                        title: 'Pagos AMCEMIG',
                        headers: {
                            'id': 'Id',
                            'paymentsRegistry.paymentMethodsExcel': 'Método de pago',
                            'paymentsRegistry.annuitiesExcel': 'Anualidades',
                            'paymentsRegistry.total': 'Total',
                            'paymentsRegistry.eventsExcel': 'Eventos',
                            'paymentsRegistry.person.title': 'Título',
                            'paymentsRegistry.person.name': 'Nombre',
                            'paymentsRegistry.person.patLastName': 'Apellido paterno',
                            'paymentsRegistry.person.matLastName': 'Apellido materno',
                            'paymentsRegistry.person.sponsor.name': 'Patrocinador',
                            'createdDateExcel': 'Fecha de registro',
                            'createdBy.username': 'Usuario',
                            'paymentsRegistry.person.phone': 'Teléfono',
                            'paymentsRegistry.person.institution': 'Institución',
                            'paymentsRegistry.person.speciality': 'Especialidad',
                            'paymentsRegistry.person.country.name': 'País',
                            'paymentsRegistry.person.email': 'Correo electrónico',
                            'paymentsRegistry.person.rfc': 'RFC/Identificador fiscal extranjero',
                            'paymentsRegistry.person.zipCode': 'Código postal',
                            'paymentsRegistry.person.fiscalRegime.name': 'Regimen fiscal',
                            'paymentsRegistry.person.fiscalRegimeForeigner': 'Regimen fiscal extranjero',
                            'paymentsRegistry.person.socialReason': 'Razón social',
                            'paymentsRegistry.person.socialReasonRfc': 'RFC Razón social',
                            'paymentsRegistry.person.zipCodeSocialReason': 'Código postal',
                            'paymentsRegistry.person.fiscalRegimeSocialReason.name': 'Regimen fiscal',
                            'paymentsRegistry.person.fiscalRegimeForeignerSocialReason': 'Regimen fiscal extranjero',
                            'paymentsRegistry.person.fiscalAddress': 'Comentarios'
                        }
                    };
                    const query = querySerialize({
                        ...filters,
                        sGroups: PaymentSGroups,
                        pagination: false,
                        token: this.token,
                        reportConfig:
                            JSON.stringify(reportConfig),
                    });
                    return `${config.host}/api/payments.xlsx?${query}`;
                }
            }
        },
        {
            name: 'error',
            preventDefaultMethods: true,
            customMethods: {
                send: function (error, stack, user, additionalInfo) {
                    let params = {
                        stack,
                        error: error?.message,
                        user: user && {id: user.id, username: user.username},
                        ...additionalInfo
                    };
                    return this.apiCall('/frontend_error', "error_sent", params, {
                        method: "POST",
                        queueable: true,
                        useCommonPath: false
                    })
                }
            }
        },
        {
            name: 'appFiles',
            customMethods: {
                getUrl: function (id) {
                    return `${this.host}/file/${id}`
                },
            }
        },
        {
            name: 'payments_registry',
            customMethods: {
                getDownloadPdfUrl: function (id) {
                    return `${this.host}/api/payments_registry/pdf/${id}?token=${this.token}`
                },
                getPdfUrl: function ({id, customProp = "_", params, ...config}) {
                    return this.apiCall(`/payments_registry/pdf_url/${id}`, customProp, params, {
                        method: "GET",
                        useCommonPath: true, ...config
                    })
                },
                sendPdf: function ({id, customProp = "_", params, ...config}) {
                    return this.apiCall(`/payments_registry/send_pdf/${id}`, customProp, params, {
                        method: "GET",
                        useCommonPath: true, ...config
                    })
                },
                exportExcelUrl: function (filters, filename) {
                    const reportConfig = {
                        name: `${filename}.xlsx`,
                        title: 'Doctores AMCEMIG',
                        headers: {
                            'id': 'Id',
                            'person.title': 'Título',
                            'person.name': 'Nombre',
                            'person.patLastName': 'Apellido paterno',
                            'person.matLastName': 'Apellido materno',
                            'person.email': 'Correo electrónico',
                            'person.phone': 'Teléfono'
                        }
                    };
                    const query = querySerialize({
                        ...filters,
                        sGroups: PaymentSGroups,
                        pagination: false,
                        token: this.token,
                        reportConfig:
                            JSON.stringify(reportConfig),
                    });
                    return `${config.host}/api/payments_registries.xlsx?${query}`;
                }
            }
        },
        {
            name: 'users',
            customMethods: {
                passwordRecovery: function ({customProp = "_", params, ...config}) {
                    return this.apiCall('/recover_password_request', customProp, params, {
                        method: "POST",
                        useCommonPath: false, ...config
                    })
                },
                resetPassword: function ({customProp = "_", params, ...config}) {
                    return this.apiCall('/reset_password', customProp, params, {
                        method: "POST",
                        useCommonPath: false, ...config
                    })
                },
            }
        },
        {
            name: 'valid_supervisor',
            customMethods: {
                validate: function ({customProp = "_", params, ...config}) {
                    return this.apiCall('/api/valid_supervisor', customProp, params, {
                        method: 'POST',
                        useCommonPath: false,
                        ...config
                    })
                }
            }
        },
        {
            name: 'speakers',
            customMethods: {
                exportExcelUrl: function (filters) {
                    const reportConfig = {
                        name: `ponentes.xlsx`,
                        title: 'Ponentes AMCEMIG',
                        headers: {
                            'id': 'Id',
                            'person.title': 'Título',
                            'person.name': 'Nombre',
                            'person.patLastName': 'Apellido Paterno',
                            'person.matLastName': 'Apellido Materno',
                            'person.phone': 'Teléfono',
                            'person.institution': 'Institución',
                            'person.speciality': 'Especialidad',
                            'person.country.name': 'País',
                            'person.email': 'Correo electrónico',
                            'person.rfc': 'RFC',
                            'person.zipCode': 'Código Postal',
                            'person.fiscalRegime.name': 'Régimen Fiscal',
                            'person.socialReason': 'Razón Social',
                            'person.socialReasonRfc': 'RFC Razón Social',
                            'event.name': 'Evento'
                        }
                    };
                    const query = querySerialize({
                        ...filters,
                        sGroups: SpeakersSGroups,
                        pagination: false,
                        token: this.token,
                        reportConfig:
                            JSON.stringify(reportConfig),
                    });
                    return `${config.host}/api/speakers.xlsx?${query}`;
                }
            }
        },
        {
            name: 'coordinators',
            customMethods: {
                exportExcelUrl: function (filters) {
                    const reportConfig = {
                        name: `coordinadores.xlsx`,
                        title: 'Coordinadores AMCEMIG',
                        headers: {
                            'id': 'Id',
                            'person.title': 'Título',
                            'person.name': 'Nombre',
                            'person.patLastName': 'Apellido Paterno',
                            'person.matLastName': 'Apellido Materno',
                            'person.phone': 'Teléfono',
                            'person.institution': 'Institución',
                            'person.speciality': 'Especialidad',
                            'person.country.name': 'País',
                            'person.email': 'Correo electrónico',
                            'person.rfc': 'RFC',
                            'person.zipCode': 'Código Postal',
                            'person.fiscalRegime.name': 'Régimen Fiscal',
                            'person.socialReason': 'Razón Social',
                            'person.socialReasonRfc': 'RFC Razón Social',
                            'event.name': 'Evento'
                        }
                    };
                    const query = querySerialize({
                        ...filters,
                        sGroups: CoordinatorsSGroups,
                        pagination: false,
                        token: this.token,
                        reportConfig:
                            JSON.stringify(reportConfig),
                    });
                    return `${config.host}/api/coordinators.xlsx?${query}`;
                }
            }
        }
    ],
    login: {
        createBody: (username, password) => {
            let credentials = new FormData();
            credentials.append("_username", username);
            credentials.append("_password", password);
            credentials.append("client_data", window?.navigator?.userAgent || 'no-data');
            return credentials;
        },
    },
};


function handleResponse(response, headers) {

    try {
        let server = headers.get("X-App-Version");
        let force = !!server && server[server.length - 1] === 'f';
        if (force) server = server.slice(0, server.length - 1);

        if (semver.validate(version) && semver.validate(version) && semver.gt(server, version)) {
            if (!this.newerVersion)
                console.log("Newer version detected " + server);
            if (!this.newerVersion && window.swRegistration) {
                window.swRegistration.update()
                    .then(() => {
                        //This will broadcast the refresh message to all active tabs of the app through the sw
                        if (force)
                            window.navigator.serviceWorker.controller.postMessage('force_refresh');
                    });
            }
            this.newerVersion = version;
            if (force && !window.swRegistration)
                window.location.reload();
        }
    } catch (e) {
        console.error('Error reading versions: ' + e);
    }

    return response.data;
}

if (process.env.REACT_APP_BUILD === 'dev')
    config.host = localStorage.host || 'https://registroamcemigback.tide.company';

if (process.env.REACT_APP_BUILD === 'prod')
    config.host = 'https://registro-back.amcemig.com';

export default config;

export const ApiContext = React.createContext(null);
