import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import "./PersonPaymentsModal.scss";

export default function PersonPaymentsModal(
    {payments, handleCloseModal}
) {

    return (
        <div className='PersonPaymentsModal'>
            <div className="card card-custom gutter-b">
                <div className="card-header border-0 pt-2">
                    <h3 className="card-title align-items-start flex-column mb-1">
                    <span className="card-label font-size-h3 font-weight-bolder text-dark">
                        Pagos
                    </span>
                    </h3>
                    <div
                        className="close-container d-flex justify-content-end pt-2 cursor-pointer"
                        onClick={handleCloseModal}
                    >
                        <FontAwesomeIcon icon={faTimes} className='close-icon pink-color'/>
                    </div>
                </div>
                <div className="card-body pt-0">
                    {/* {
                        payments && payments.length > 0 &&
                        <div>
                            {
                                payments[0].yearsAmcemig && payments[0].yearsAmcemig.length > 0 &&
                                <div>
                                    <h4>
                                        Anualidades AMCEMIG
                                    </h4>
                                    <ul>
                                        {
                                            _.map(payments[0].yearsAmcemig, (detail) => (
                                                <li key={detail.id}>
                                                    {detail.year} - ${moneyFormatter(detail.amount)}
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            }
                            {
                                payments[0].yearsIhs && payments[0].yearsIhs.length > 0 &&
                                <div>
                                    <h4>
                                        Anualidades IHS
                                    </h4>
                                    <ul>
                                        {
                                            _.map(payments[0].yearsIhs, (detail) => (
                                                <li key={detail.id}>
                                                    {detail.year} - ${moneyFormatter(detail.amount)}
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            }
                            {
                                payments[0].eventDetails && payments[0].eventDetails.length > 0 &&
                                <div>
                                    <h4>
                                        Eventos
                                    </h4>
                                    <ul>
                                        {
                                            _.map(payments[0].eventDetails, (detail) => (
                                                <li key={detail.id}>
                                                    {detail.name} - ${moneyFormatter(detail.amount)}
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            }{
                            payments[0].paymentMethods && payments[0].paymentMethods.length > 0 &&
                            <div>
                                <h4>
                                    Método de Pago
                                </h4>
                                <ul>
                                    {
                                        _.map(payments[0].paymentMethods, (detail) => (
                                            <li key={detail.id}>
                                                {detail.name} - ${moneyFormatter(detail.amount)}
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        }
                        </div>
                    } */}
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>Método de pago</th>
                                <th>Monto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {payments.map( (payment) => {
                                return (
                                    <tr key={payment.id}>
                                        <td>
                                            {payment.paymentMethod.name}
                                        </td>
                                        <td>
                                            {payment.amount}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
