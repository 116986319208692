import React, { useContext } from 'react';
import "./SideBar.scss";
import {ReactComponent as DisplaySideBarIcon} from "../../../../assets/icons/dashboard/aside-bar/display-sidebar.svg";
import getAppRoutes from "../../../../services/routes/appRoutes";
import {ApiContext} from "../../../../services/api/api-config";
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import classNames from 'classnames';
import {SecurityContext} from "../../../../services/SecurityManager";
import {version} from '../../../../pckg.json';
import {faArrowUp} from "@fortawesome/free-solid-svg-icons/faArrowUp";
import amcemigLogo from '../../../../assets/img/logo-big.jpg'
import { uniqueId } from 'lodash';

const SideBar = ({hidden, toggleHidden, toggleProfile, hideAside, toggleHideAside}) => {
    const security = useContext(SecurityContext);
    const api = useContext(ApiContext);
    const routes = getAppRoutes(security);
    const location = useLocation();

    const isActiveRoute = (path) => {
        if (!path) return '';
        const pathToRegex = path.replace(/\/:[^/]*/g, "[^/]*") + "($|/)";
        const regex = new RegExp(pathToRegex);
        return location.pathname.match(regex) ? 'active' : '';
    };

    return (
        <div className={"MenuSidebar caracol " + (hideAside && 'hiddenAside')}>
            <div className={'menu-container ' + (hidden === true ? 'hide' : 'discover')}>
                <div className={'logo d-flex align-items-center ' + (hidden && 'justify-content-center')}>
                    <h4 className={'dark-color font-bold ' + (hidden && 'd-none')}>Menú</h4>
                    <DisplaySideBarIcon className={'display-sidebar-icon'} onClick={toggleHidden}/>
                </div>
                <div className="lists-container d-flex flex-column justify-content-between">
                    <ul className='menu-list'>
                        <div className="logo-container">
                            {/* <Icon className={'logo-icon mb-4'}/> */}
                            <img src={amcemigLogo} className={'logo-icon mb-4'} alt="AMCEMIG"/>
                        </div>
                        {routes.map((route, i) => {
                                if (route.hidden) return null;
                                return (
                                    <li className={!route.hasSubmenu ? classNames('menu-item', isActiveRoute(route.path)) : 'is-submenu'} key={i}>
                                        <Link
                                            to={
                                                route.path + '/loaded?id=' + uniqueId() 
                                            }
                                            onClick={ window.innerWidth < 700? toggleHideAside : ()=>{}}
                                            className={'menu-link d-flex align-items-center ' + (hidden && 'justify-content-center')}
                                        >
                                            <div className={hidden ? '' : "pr-1"}>
                                                {route.activeIcon}
                                                {route.icon}
                                            </div>
                                            <p className={"text-box p-0 m-0 ml-2 " + (hidden && 'font-mini')}>{route.name}</p>
                                        </Link>
                                        {
                                            route.hasSubmenu &&
                                            (
                                                <ul className='menu-list mr-2'>
                                                    {
                                                        route.submenus.map((submenu, indexSubmenu) =>
                                                        <li
                                                            key={indexSubmenu}
                                                            className={isActiveRoute(route.path) ? 'menu-item menu-item--active' : 'menu-item'}
                                                        >
                                                            <Link
                                                                to={submenu.path + '/loaded?id=' + uniqueId()}
                                                                onClick={ window.innerWidth < 700? toggleHideAside : ()=>{}}
                                                                className={'menu-item-row d-flex align-items-center ' + (hidden && 'justify-content-center')}
                                                            >
                                                                <div className={hidden ? '' : "pr-1"}>
                                                                    {submenu.activeIcon}
                                                                    {submenu.icon}
                                                                </div>
                                                                <p className={"text-box p-0 m-0 ml-2 " + (hidden && 'font-mini')}>{submenu.name}</p>
                                                            </Link>
                                                        </li>  
                                                        )
                                                    }
                                                </ul>
                                            )
                                        }
                                    </li>
                                );
                            }
                        )}
                    </ul>
                    <ul className="menu-list">
                        <li className='menu-item'>
                            <span onClick={api.logout}
                                  className={'menu-link d-flex align-items-center ' + (hidden && 'justify-content-center')}>
                                <FontAwesomeIcon icon={faSignOutAlt} className="dashboard-logo icon-dash-logo text-danger"/>
                                <FontAwesomeIcon icon={faSignOutAlt} className="active-icon icon-dash-logo text-info"/>
                                <div className={'text-box m-0 ml-2 ' + (hidden && 'font-mini')}>
                                Cerrar sesión
                                </div>
                            </span>
                        </li>
                    </ul>
                </div>

                <span className="app-version">{version}{ !!api.newerVersion && <FontAwesomeIcon icon={faArrowUp}/> }</span>
            </div>

        </div>
    );
}

SideBar.displayName="SideBar";

export default SideBar;
