import React, {useState, useCallback, useContext} from 'react'
import "./SpeakersIndex.scss";
import SpeakersForm from './components/SpeakersForm/SpeakersForm'
import SpeakersAdminHeader from './components/SpeakersAdminHeader/SpeakersAdminHeader'
import SpeakersTableView from './components/SpeakersTableView/SpeakersTableView'
import useBoolean from '../../../hooks/useBoolean';
import { ApiContext } from '../../../services/api/api-config';
import { getNotifier } from '../../../services/notifier';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import { SpeakersSGroups } from '../../../services/modelUtils/SpeakerUtils';
import {SecurityContext} from "../../../services/SecurityManager";

export default function SpeakersIndex() {
  const [edit, setEdit] = useState();
  const [addingSpeaker, startAddingSpeaker, stopAddingSpeaker] = useBoolean();
  const [filters, setFilters] = useState({'order[createdDate]':'DESC', 'type':'PONENTE'});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  
  const api = useContext(ApiContext);
  const security = useContext(SecurityContext);

  // Handle close form modal
  const handleCloseForm = useCallback(()=>{
		setEdit(null);
		stopAddingSpeaker();
	},[stopAddingSpeaker]);

  const deleteSpeaker = useCallback(() => {
    api.people.delete({id: idToDelete})
    .then(() => {
      setShowDeleteConfirm(false);
    })
    .catch((err) => {
      getNotifier().error(err.detail);
    });
  }, [api.people, idToDelete]);

  const onDeleteSpeaker = useCallback((speaker) => {
    setShowDeleteConfirm(true);
    setIdToDelete(speaker.id);
  }, []);

  const getSpeakers = useCallback(() => {
    api.people.get({
      params: {
        type: 'PONENTE',
        'order[createdDate]':'DESC',
        sGroups: SpeakersSGroups
      }
    }).then(response => {
    }).catch((err) => {
      getNotifier().error(err.detail);
    });
  }, [api.people]);

  const updateAttended = useCallback((eventAttendance, maxSequential) => {
    api.eventAttendances.update({
      id: eventAttendance.id,
      params: {
        attended: true,
        sequential: maxSequential + 1
      }
    }).then(response => {
      getNotifier().success('Se actualizó correctamente');
      getSpeakers();
    }).catch(
      error => getNotifier().error('Ocurrió un error al actualizar la asistencia')
    );
  }, [api.eventAttendances, getSpeakers]);

  const getMaxSequential = useCallback((eventAttendance) => {
    api.eventAttendances.get({
      params: {
        'event.id': eventAttendance.event.id,
        attended: true
      }
    }).then(response => {
      updateAttended(eventAttendance, response.length);
    }).catch(
      error => getNotifier().error('Ocurrió un error al actualizar la asistencia')
    );
  }, [api.eventAttendances, updateAttended]);

  const onSpeakerAttended = useCallback((eventAttendance) => {
    if (!eventAttendance.attended) {
      getMaxSequential(eventAttendance);
    } else {
      api.eventAttendances.update({
        id: eventAttendance.id,
        params: {
          attended: !eventAttendance.attended
        }
      }).then(response => {
        getNotifier().success('Se actualizó correctamente');
        getSpeakers();
      }).catch(
        error => getNotifier().error('Ocurrió un error al actualizar la asistencia')
      );
    }
  }, [api.eventAttendances, getMaxSequential, getSpeakers]);

  const reload = useCallback(() => {
    api.people.get({params: {type: 'PONENTE', 'order[createdDate]':'DESC', sGroups: SpeakersSGroups}})
  }, [api.people]);

  return (
    <div className='SpeakersIndex'>
      <div className="d-flex flex-row">
        {
            !!(edit || addingSpeaker) &&
            <div className="flex-column offcanvas-mobile mx-auto" id="kt_profile_aside">
              <SpeakersForm
                edit={edit}
                closeModal={handleCloseForm}
              />
            </div>
          }
          <div className="flex-row-fluid">
            <div className="card speakers-index card-custom card-stretch gutter-b">
              <div className="card-body">
                <div className="mb-11">
                  <SpeakersAdminHeader
                    onNewSpeakerClick = {startAddingSpeaker}
                    filters = {filters}
                    onFiltersChange = {setFilters}
                    onSuccessImportPerson={reload}
                  />
                  <SpeakersTableView
                    onSpeakerEdit = {setEdit}
                    onDeleteSpeaker = {onDeleteSpeaker}
                    onSpeakerAttended={onSpeakerAttended}
                    filters = {filters}
                    securityManager={security}
                  />
                </div>
                
                {
                  showDeleteConfirm &&
                  <div className="flex-column offcanvas-mobile mx-auto SpeakerModal" id="kt_profile_aside">
                    <div className="card card-custom gutter-b">
                      <div className="card-header border-0 pt-2">
                          <h3 className="card-title align-items-start flex-column mb-3">
                            <span className="card-label font-size-h3 font-weight-bolder text-dark">
                              Confirmar eliminar ponente
                            </span>
                          </h3>
                        <div
                          className="close-container d-flex justify-content-end pt-2 cursor-pointer"
                          onClick={() => setShowDeleteConfirm(false)}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            className='close-icon pink-color'
                          />
                        </div>
                      </div>
                      <div className="card-body pt-0">
                        <h3>
                          ¿Está seguro de eliminar el ponente?
                        </h3>
                        <div>
                        <button
                          type="button"
                          onClick={deleteSpeaker}
                          className="btn btn-primary font-weight-bolder mr-2 px-8"
                        >
                          Confirmar
                        </button>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

