import React from "react";
import {getEventPhotoUrl} from "../../../services/modelUtils/eventUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faEdit,
    faUsers
} from '@fortawesome/free-solid-svg-icons';
import {parseDate} from '../../../services/dateUtils';
import {
    copyPropertyOrThrow
} from "../../../services/formUtils";

export const getEventsTableColumns = (
    {api, deleteEvent, onEventEdit, showRegisters, type}
) => {
    let columns =
        [
            {
                Header: "ID",
                accessor: "id"
            },
            {
                Header: "Nombre",
                accessor: event =>
                    <div className="b-red max-w-25 text-wrap">
                        <p className={event.isActiveCongress ? `mb-0 font-weight-bolder text-break text-green` : 'mb-0 font-weight-bolder text-break'}>
                            {event.name || 'Sin información'}
                        </p>
                    </div>
            },
            {
                Header: "Fecha",
                accessor: event =>
                    <div>
                        <p className='mb-0 font-weight-bolder'>
                            {event.date ? parseDate(event.date) : 'Sin información'}
                        </p>
                    </div>
            },
            {
                Header: "Imagen",
                accessor: product => <div
                    style={{
                        backgroundImage: `url(${getEventPhotoUrl(product, api)})`,
                        width: '100px',
                        height: '100px',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}
                ></div>
            },
            {
                Header: "Lugar",
                accessor: event =>
                    <div className="max-w-25 text-wrap">
                        <p className='mb-0 font-weight-bolder text-break'>
                            {event.place || 'Sin información'}
                        </p>
                    </div>
            },
            {
                Header: "Costos",
                accessor: event => {
                      return <div className="max-w-25 text-wrap">
                        <p className='mb-0 font-weight-bolder'>
                            {renderCosts(event)}
                        </p>
                    </div>
                }
            }];

    if (type === 'events') {
        columns.push({
            Header: "Congreso",
            accessor: event =>
                <div className="max-w-25 text-wrap">
                    <p className='mb-0 font-weight-bolder'>
                        {event.congress ? event.congress.name : 'Sin información'}
                    </p>
                </div>
        })
    }
    columns.push({
        Header: "Está activo",
        accessor: event =>
            <div className="max-w-25 text-wrap">
                <p className={event.isActiveCongress ? 'mb-0 font-weight-bolder text-green' : 'mb-0 font-weight-bolder'}>
                    {event.isActiveCongress ? 'Si' : 'No'}
                </p>
            </div>
    });
    columns.push({
        Header: "Registrados",
        accessor: event =>
            <div className="max-w-25 text-wrap">
                <p className='mb-0 font-weight-bolder'>
          <span
              className="pointer"
              title={`Ver Registrados`}
              onClick={() => showRegisters(event.id)}
          >
            <button
                className="btn font-weight-bolder py-1 mr-1"
            >
              <FontAwesomeIcon
                  icon={faUsers}
                  className={event.isActiveCongress ? 'dashboard-logo icon-dash-logo text-green' : 'dashboard-logo icon-dash-logo text-dark'}
              />
            </button>
          </span>
                </p>
            </div>
    });


    columns.push({
        Header: 'Acciones', accessor:
            event =>
                (
                    <div className="actionButtonsContainer">
                        <button
                            className="btn btn-outline-danger font-weight-bolder py-1 mr-1"
                            onClick={() => deleteEvent(event)}
                        >
                            <FontAwesomeIcon
                                icon={faTrash}
                                className='dashboard-logo icon-dash-logo text-dark'
                            />
                        </button>
                        <button
                            className="btn btn-outline-success font-weight-bolder py-1"
                            onClick={() => onEventEdit(event)}>
                            <FontAwesomeIcon
                                icon={faEdit}
                                className='dashboard-logo icon-dash-logo text-dark'
                            />
                        </button>
                    </div>
                )
    });
    return columns;
}

function renderCosts(event) {

    if (!event.eventPrices || event.eventPrices.length === 0) {
        return "Sin costos";
    }

    return event.eventPrices.map((price, index) => {
        return <p>{price.concept + " - $" + price.amount}</p>
    })

}

/* sGroups used in Products CRUD from ProductsIndexComponent to ProductsForm :: BEGIN */
export const eventsSGroups = [
    'event_read',
    'event_read_payment',
    'payment_read',
    'event_read_speakers',
    'event_read_coordinators',
    'coordinator_read',
    'event_read_event_attendances',
    'event_attendance_read',
    'event_read_prices',
    'event_price_read'
];

/**
 * Validate and Prepare data to send
 *
 * @param {any} params
 * @param {string} specialty
 * @param {string} country
 * @param {string} fiscalRegime
 * @returns
 */
export const prepareFormEvent = (
    params, file, edit, congress, costs, type
) => {



    // Register form validations
    let register = {}
    copyPropertyOrThrow(params, register, "name", "Por favor llena el campo de nombre");
    copyPropertyOrThrow(params, register, "date", "Por favor llena el campo de fecha");
    if (!edit && !file) {
        copyPropertyOrThrow(file, register, "file", "Por favor selecciona una imagen del evento");
    }
    copyPropertyOrThrow(params, register, "place", "Por favor llena el campo de lugar del evento");
    if (congress) {
        register.congress = congress;
    }

    if (type === 'congress') {
        register.isCongress = true;
    }

    register.eventPrices = costs;
    register.isActiveCongress = params.isActiveCongress;

    return register;
}