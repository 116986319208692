import {copyPropertyIdOrNull,  copyPropertyOrNull } from "../formUtils";


/**
 * Validate and Prepare data to send
 * 
 * @param {any} params 
 * @param {string} specialty 
 * @param {string} country 
 * @param {string} fiscalRegime 
 * @returns
 */
export const prepareForm = (
    params, title, country
) => {
    // Register form validations
    let register = {}
    copyPropertyIdOrNull( {title}, register, "title", "Por favor llena el campo de título" );
    if (title === 'Otro') {
        copyPropertyOrNull( params, register, "otherTitle", "Por favor llena el campo de especificar título" );
    }
    copyPropertyOrNull( params, register, "name", "Por favor llena el campo de nombre" );
    copyPropertyOrNull( params, register, "patLastName", "Por favor llena el campo de apellido paterno" );
    // copyPropertyOrThrow( params, register, "matLastName", "Por favor llena el campo de apellido materno" );
    copyPropertyOrNull( params, register, "country", "Por favor llena el campo de subtítulo");
    
    // Prepare person object to send
    const person = {
        title: title !== 'Otro' ? title : params.otherTitle,
        name: params.name,
        patLastName: params.patLastName,
        matLastName: params.matLastName ? params.matLastName : undefined,
        country: params.country
    };
    return {
        person
    };
}