import React, {useContext, useCallback} from 'react'
import TideTableFilters from '../../../../../components/TideTableFilters/TideTableFilters'
import { ApiContext } from '../../../../../services/api/api-config';
import useCallbackCreator from 'use-callback-creator';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import "./AnnualitysAdminHeader.scss"

export default function AnnualitysAdminHeader(
  { onNewAnnualityClick, filters, onFiltersChange }
) {

  const api = useContext(ApiContext);

  const handleInputChange = useCallbackCreator( (field, event) => {
    const value = event.target.value;
    onFiltersChange( filters => {
      const newFilters = {...filters, [field]:value };
      if(value)
        return newFilters;
      delete newFilters[field];
      return newFilters;
    } );
  },[onFiltersChange]);

  const handleOrderChange = useCallbackCreator( (field) => {
    onFiltersChange( filters => {
      const newFilters = {...filters};
      if(!filters[field])
        newFilters[field] = 'ASC' ;
      else if(filters[field]==='ASC')
        newFilters[field] = 'DESC' ;
      else
        delete newFilters[field];
      return newFilters;
    } );
  },[onFiltersChange]);

  const filtersConfig = [
    {
      name: 'Año',
      onChange: handleInputChange('year'),
      value: filters.year,
      type: 'text',
      placeholder: 'Filtrar por año',
      orderOnChange: handleOrderChange('order[year]'),
      orderStatus: filters['order[year]'],
    },
    {
      name: 'Concepto',
      onChange: handleInputChange('concept'),
      value: filters.concept,
      type: 'text',
      placeholder: 'Filtrar por concepto',
      orderOnChange: handleOrderChange('concept'),
      orderStatus: filters['concept'],
    },
    {
      name: 'Tipo de Anualidad',
      onChange: handleInputChange('type'),
      value: filters.type,
      type: 'text',
      placeholder: 'Filtrar por tipo de Anualidad',
      orderOnChange: handleOrderChange('order[type]'),
      orderStatus: filters['order[type]'],
    }
  ];

  const excelUrl = useCallback( () => {
    window.location.href = api.events.exportExcelUrl(filters);
  }, [api.events, filters]);

  return (
    <div className='AnnualitysAdminHeader d-flex flex-row w-100'>
      <div className="card-toolbar mt-5">
        <ul className="nav nav-pills nav-pills-sm nav-dark-75 justify-content-center justify-content-md-end">
          <li className="nav-item btn-amcemig">
            <span
              onClick={excelUrl}
              className="excelButton btn d-flex justify-content-center"
            >
              <FontAwesomeIcon icon={faFileExcel} className="text-white" />
            </span>
          </li>
          <li className="nav-item btn-amcemig">
            <span
              className="nav-link text-light py-2 px-4 btn-info cursor-pointer"
              data-toggle="tab"
              onClick={onNewAnnualityClick}
            >
              Añadir Anualidad
            </span>
          </li>
        </ul>
      </div>
      <div className="d-flex justify-content-md-start">
          <TideTableFilters inputsObject={filtersConfig} />
      </div>
    </div>
  )
}
