import Login from "../../scenes/Public/Login/Login";
import ForgotPassword from "../../scenes/Public/ForgotPassword/ForgotPassword";
import DashLayout from "../../scenes/DashLayout/DashLayout";
import ResetPassword from "../../scenes/Public/ResetPassword/ResetPassword";

export const paths={
    login:'/login',
    forgotPassword: '/recover_password',
    resetPassword: '/reset_password',
};

const getMainRoutes = (loggedIn) => {

    // Logged in routes. Another router inside DashLayout
    if (loggedIn) {
        return [
            {
                path:'/',
                component: DashLayout
            }
        ];
    } else {
        // Public routes
        return [
            {
                path:paths.login,
                component: Login
            },
            {
                path: paths.forgotPassword,
                component: ForgotPassword
            },
            {
                path: paths.resetPassword,
                component: ResetPassword
            }
        ];
    }
};


export default getMainRoutes;
