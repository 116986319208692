import React, {useState, useContext, useCallback} from 'react'
import './CoordinatorsIndex.scss'
import useBoolean from '../../../hooks/useBoolean';
import { ApiContext } from '../../../services/api/api-config';
import { getNotifier } from '../../../services/notifier';
import CoordinatorsForm from './components/CoordinatorsForm/CoordinatorsForm'
import CoordinatorsAdminHeader from './components/CoordinatorsAdminHeader/CoordinatorsAdminHeader'
import CoordinatorsTableView from './components/CoordinatorsTableView/CoordinatorsTableView'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import { CoordinatorsSGroups } from '../../../services/modelUtils/CoordinatorUtils';

export default function CoordinatorsIndex() {

  const [edit, setEdit] = useState();
  const [addingCoordinator, startAddingCoordinator, stopAddingCoordinator] = useBoolean();
  const [filters, setFilters] = useState({'order[createdDate]':'DESC', type: 'COORDINADOR'});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);

  const api = useContext(ApiContext);

  // Handle close form modal
  const handleCloseForm = useCallback(()=>{
		setEdit(null);
		stopAddingCoordinator();
	},[stopAddingCoordinator]);

  const deleteCoordinator = useCallback(() => {
    api.people.delete({id: idToDelete})
    .then(() => {
      setShowDeleteConfirm(false);
    })
    .catch((err) => {
      getNotifier().error(err.detail);
    });
  }, [api.people, idToDelete]);

  const onDeleteCoordinator = useCallback((coordinator) => {
    setShowDeleteConfirm(true);
    setIdToDelete(coordinator.id);
  }, []);

  const reload = useCallback(() => {
    api.people.get({params: {type: 'COORDINADOR', 'order[createdDate]':'DESC', sGroups: CoordinatorsSGroups}})
  }, [api.people]);

  const getCoordinators = useCallback(() => {
    api.people.get({
      params: {
        type: 'COORDINADOR',
        'order[createdDate]':'DESC',
        sGroups: CoordinatorsSGroups
      }
    }).then(response => {
    }).catch((err) => {
      getNotifier().error(err.detail);
    });
  }, [api.people]);

  const updateAttended = useCallback((eventAttendance, maxSequential) => {
    api.eventAttendances.update({
      id: eventAttendance.id,
      params: {
        attended: true,
        sequential: maxSequential + 1
      }
    }).then(response => {
      getNotifier().success('Se actualizó correctamente');
      getCoordinators();
    }).catch(
      error => getNotifier().error('Ocurrió un error al actualizar la asistencia')
    );
  }, [api.eventAttendances, getCoordinators]);

  const getMaxSequential = useCallback((eventAttendance) => {
    api.eventAttendances.get({
      params: {
        'event.id': eventAttendance.event.id,
        attended: true
      }
    }).then(response => {
      updateAttended(eventAttendance, response.length);
    }).catch(
      error => getNotifier().error('Ocurrió un error al actualizar la asistencia')
    );
  }, [api.eventAttendances, updateAttended]);

  const onCoordinatorAttended = useCallback((eventAttendance) => {
    if (!eventAttendance.attended) {
      getMaxSequential(eventAttendance);
    } else {
      api.eventAttendances.update({
        id: eventAttendance.id,
        params: {
          attended: !eventAttendance.attended
        }
      }).then(response => {
        getNotifier().success('Se actualizó correctamente');
        getCoordinators();
      }).catch(
        error => getNotifier().error('Ocurrió un error al actualizar la asistencia')
      );
    }
  }, [api.eventAttendances, getCoordinators, getMaxSequential]);

  return (
    <div className='CoordinatorsIndex'>
      <div className="d-flex flex-row">
        {
            !!(edit || addingCoordinator) &&
            <div className="flex-column offcanvas-mobile mx-auto" id="kt_profile_aside">
              <CoordinatorsForm
                edit={edit}
                closeModal={handleCloseForm}
              />
            </div>
          }
          <div className="flex-row-fluid">
            <div className="card coordinators-index card-custom card-stretch gutter-b">
              <div className="card-body">
                <div className="mb-11">
                  <CoordinatorsAdminHeader
                    onNewCoordinatorClick = {startAddingCoordinator}
                    filters = {filters}
                    onFiltersChange = {setFilters}
                    onSuccessImportPerson={reload}
                  />
                  <CoordinatorsTableView
                    onCoordinatorEdit = {setEdit}
                    deleteCoordinator = {onDeleteCoordinator}
                    filters = {filters}
                    onCoordinatorAttended={onCoordinatorAttended}
                  />
                </div>
                {
                  showDeleteConfirm &&
                  <div className="flex-column offcanvas-mobile mx-auto SpeakerModal" id="kt_profile_aside">
                    <div className="card card-custom gutter-b">
                      <div className="card-header border-0 pt-2">
                          <h3 className="card-title align-items-start flex-column mb-3">
                            <span className="card-label font-size-h3 font-weight-bolder text-dark">
                              Confirmar eliminar coordinador
                            </span>
                          </h3>
                        <div
                          className="close-container d-flex justify-content-end pt-2 cursor-pointer"
                          onClick={() => setShowDeleteConfirm(false)}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            className='close-icon pink-color'
                          />
                        </div>
                      </div>
                      <div className="card-body pt-0">
                        <h3>
                          ¿Está seguro de eliminar el coordinador?
                        </h3>
                        <div>
                        <button
                          type="button"
                          onClick={deleteCoordinator}
                          className="btn btn-primary font-weight-bolder mr-2 px-8"
                        >
                          Confirmar
                        </button>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}
