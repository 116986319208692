import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {getNotifier} from '../../../../../services/notifier';
import _ from "lodash";
import {moneyFormatter} from '../../../../../services/currencyUtils';
import "./PaymentFormGeneral.scss"

export default function PaymentFormGeneral(
    {api, onPaymentValid, editPaymentRegistry}
) {

    const [annualities, setAnnualities] = useState([])
    const [eventPrices, setEventPrices] = useState([])
    const [paymentsAnualities, setPaymentsAnualities] = useState([])
    const [paymentsEvents, setPaymentsEvents] = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])
    const [fetchedPaymentMethods, setFetchedPaymentMethods] = useState([])
    const [totalAmount, setTotalAmount] = useState(0)
    const [pendignAmount, setPendignAmount] = useState(-1)
    const [paymentValid, setPaymentValid] = useState(false)
    const [first, setFirst] = useState(true)



    useEffect(() => {
        if(!editPaymentRegistry){
            return;
        }

        if (editPaymentRegistry && eventPrices.length>0 && annualities.length>0 && fetchedPaymentMethods.length>0 ){
            
            const eventRegistrations = _.filter(editPaymentRegistry.registrations, (registration) => {
                return registration.eventPrice
            });
            const prices = _.map(eventRegistrations, (registration) => {
                return registration.eventPrice
            });
            setPaymentsEvents(prices);


            const anulaitiesRegistrations = _.filter(editPaymentRegistry.registrations, (registration) => {
                return registration.annuality
            });
            const anPrices = _.map(anulaitiesRegistrations, (registration) => {
                return registration.annuality
            });
            setPaymentsAnualities(anPrices);


            const newPaymentMethods = fetchedPaymentMethods.map((pm)=>{
                const enabledPM = _.find(editPaymentRegistry.payments, (payment) => payment.paymentMethod.id===pm.id);
                if (enabledPM){
                    pm.selected=true;
                    pm.amount=enabledPM.amount;
                }
                return pm;
            });
            setPaymentMethods(newPaymentMethods);
        }


    }, [editPaymentRegistry, eventPrices, annualities, fetchedPaymentMethods ])

    const withoutPaymentIsSelected = useMemo(() => {
        return !!_.find(paymentMethods, (paymentMethod) => paymentMethod.name === "SIN PAGO" && paymentMethod.selected);
    }, [paymentMethods]);


    /**
     * Get Event Prices
     */
    const getEventPrices = useCallback(() => {
        api.eventPrices.get({
            params: {
                'order[date]': 'DESC',
                'sGroups': ['event_price_read', 'event_price_read_event', 'event_read']
            }
        }).then(response => {
            setEventPrices(response);
        }).catch(
            error => getNotifier().error(error.message)
        );

    }, [api]);
    /**
     * Get AMCEMIG Annualities
     */
    const getAnnualities = useCallback(() => {
        api.annualities.get({
            params: {
                'order[type]': 'ASC',
                'order[year]': 'ASC'
            },
            preventNotifier: true
        }).then(response => {
            getEventPrices();
            setAnnualities(response);
        }).catch(
            (error) => {
                getNotifier().error("Acceso denegado a anualidades AMCEMIG.");
            }
        );
    }, [api, getEventPrices]);


    /**
     * Get Payment Methods
     */
    const getPaymentMethods = useCallback(() => {
        let methods = [];
        api.paymentMethods.get().then(response => {
            response.forEach(element => {
                methods.push({
                    id: element.id,
                    name: element.name,
                    selected: false,
                    amount: ''
                });
            });
            setFetchedPaymentMethods(methods);
            setPaymentMethods(methods);
        }).catch(
            error => getNotifier().error(error.message)
        );
    }, [api.paymentMethods]);

    /**
     * Update Total amount
     */
    const updateTotal = useCallback(() => {
        let total = 0;
        paymentsAnualities.forEach(payment => {
            total += parseFloat(payment.amount);
        });
        paymentsEvents.forEach(payment => {
            total += parseFloat(payment.amount);
        });
        setTotalAmount(total);
    }, [paymentsAnualities, paymentsEvents]);

    const updatePending = useCallback(() => {
        let totalPayment = 0;
        let pending = 0;
        for (const element of paymentMethods) {
            if (parseFloat(element.amount) > 0) {
                totalPayment += parseFloat(element.amount);
            }
        }
        let total = 0;

        paymentsAnualities.forEach(payment => {
            total += parseFloat(payment.amount);
        });
        paymentsEvents.forEach(payment => {
            total += parseFloat(payment.amount);
        });
        pending = total - totalPayment;
        if (withoutPaymentIsSelected) {
            setPendignAmount(total);
        } else {
            setPendignAmount(pending);
        }
        setPaymentValid(false);
        if (!withoutPaymentIsSelected && pending > 0) {
            onPaymentValid(null);
        }

    }, [onPaymentValid, paymentMethods, paymentsAnualities, paymentsEvents, withoutPaymentIsSelected]);

    /**
     * Update payments AMCEMIG Annualities
     */
    const updateAnnualitiesPayments = useCallback((isChecked, value) => {
        let payments = [...paymentsAnualities];
        if (!isChecked) {
            const theIndex = payments.findIndex((payment) => payment.id === value.id);
            if (theIndex !== undefined) {
                payments.splice(theIndex, 1);
            }
        } else {
            payments.push(value);
        }
        setPaymentsAnualities(payments);
        // updatePending();
    }, [paymentsAnualities]);


    /**
     * Update Payments Events
     */
    const updatePaymentsEvents = useCallback((isChecked, value) => {
        let payments = [...paymentsEvents];
        if (!isChecked) {
            const theIndex = payments.findIndex((payment) => payment.id === value.id);
            if (theIndex !== undefined) {
                payments.splice(theIndex, 1);
            }
        } else {
            payments.push(value);
        }
        setPaymentsEvents(payments);
    }, [paymentsEvents]);


    const anualityIsChecked = useCallback((anuality)=>{
        return _.find(paymentsAnualities, (an) => an.id === anuality.id);
    },[paymentsAnualities]);

    const eventIsChecked = useCallback((event)=>{
        return _.find(paymentsEvents, (paymentEvent) => paymentEvent.id === event.id);

    },[paymentsEvents]);
    /**
     * Handle Checkbox Checked
     */
    const handleChecboxChange = useCallback((e, value, type) => {
        const isChecked = e.target.checked;
        if (type === 'event') {
            updatePaymentsEvents(isChecked, value);
        } else {
            updateAnnualitiesPayments(isChecked, value);
        }
    }, [updateAnnualitiesPayments, updatePaymentsEvents]);

    /**
     * Handle Payment Method Change
     */
    const handlePaymentMethodChange = useCallback((e, value) => {
        let paymentMethodsTemp = [...paymentMethods];
        const theIndex = paymentMethodsTemp.findIndex(element => element.id === value.id);
        if (e.target.checked) {
            paymentMethodsTemp[theIndex].selected = true;
        } else {
            paymentMethodsTemp[theIndex].selected = false;
            paymentMethodsTemp[theIndex].amount = 0;
        }
        setPaymentMethods(paymentMethodsTemp);
        //updatePendingAmount(paymentMethodsTemp);
        // document.getElementById(`amount${value.id}`).focus();
    }, [paymentMethods]);

    /**
     * Update Pending Amount
     */
    useEffect(() => {

        let totalPayment = 0;
        paymentMethods.forEach(element => {
            if (parseFloat(element.amount) > 0) {
                totalPayment += parseFloat(element.amount);
            }
        });

        if (!withoutPaymentIsSelected && (totalPayment > totalAmount) && totalAmount>0) {
            getNotifier().error('El monto del pago excede el total');
            setPaymentValid(false);
        } else {

            if ((totalAmount - totalPayment === 0) || withoutPaymentIsSelected) {
                setPaymentValid(true);
                let paymentMethodList = [];
                paymentMethods.forEach(element => {
                    if (element.selected && element.amount !== '') {
                        paymentMethodList.push(element);
                    }
                });
                const params = {
                    paymentsAnualities: paymentsAnualities,
                    paymentsEvents: paymentsEvents,
                    paymentMethods: paymentMethodList
                };
                onPaymentValid(params);
            } else {
                setPaymentValid(false);
            }

            if (withoutPaymentIsSelected) {
                const selected = _.filter(paymentMethods, {selected: true});

                if (selected.length > 1) {
                    const newPaymentMethods = paymentMethods.map((method) => {
                        if (method.name !== "SIN PAGO") {
                            method.selected = false;
                            method.amount = 0;
                        }
                        return method;
                    })
                    setPaymentMethods(newPaymentMethods);
                }

                setPendignAmount(totalAmount);
            } else {
                setPendignAmount(totalAmount - totalPayment);
            }
        }
    }, [onPaymentValid, paymentMethods, paymentsAnualities, paymentsEvents, totalAmount, withoutPaymentIsSelected]);

    /**
     * Update Payment Method Amount
     */
    const updateMethodPaymentAmount = useCallback((e, paymentMethod) => {
        const regex = /^\d+(\.)?(\d+)?$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            let paymentMethodsTemp = [...paymentMethods];
            const theIndex = paymentMethodsTemp.findIndex(element => element.id === paymentMethod.id);
            paymentMethodsTemp[theIndex].amount = e.target.value.toString();
            setPaymentMethods(paymentMethodsTemp);
        }
    }, [paymentMethods]);

    useEffect(() => {
        if (first) {
            setFirst(false);
            getAnnualities();
            getPaymentMethods();
        }
        updateTotal();
    }, [paymentsAnualities.length, eventPrices.length, first, getAnnualities, getPaymentMethods, paymentMethods, updateTotal])

    useEffect(() => {
        updatePending();
    }, [updatePending])

    const annualitiesByTypeChunk = useMemo(() => {
        return _.groupBy(annualities, 'type');
    }, [annualities]);

    const eventCostsByEventChunk = useMemo(() => {
        return _.groupBy(eventPrices, 'event.id');
    }, [eventPrices]);


    return (
        <div className='PaymentFormGeneral'>
            <div className='card'>
                <div className="row">
                    <div className="col">
                        <h3>Anualidades</h3>
                        <div className="d-flex flex-column">
                            {
                                _.map(annualitiesByTypeChunk, (annualities, key) => (
                                    <div className='events-block row' key={key}>
                                        {_.map(annualities, (annuality) => (
                                            <div
                                                className='col-12 p-2'
                                                key={annuality.id}
                                            >
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => handleChecboxChange(e, annuality, 'anuality')}
                                                    className="mr-2"
                                                    checked={anualityIsChecked(annuality)}
                                                    id={`${annuality.id}`}
                                                />
                                                <label htmlFor={`${annuality.id}`}>
                                                    {annuality.type} - {annuality.year} - {annuality.concept}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h3>Eventos</h3>
                        <div className="d-flex flex-column">
                            {
                                _.map(eventCostsByEventChunk, (eventPrices, key) => (
                                    <div className='events-block row' key={key}>
                                        {_.map(eventPrices, (eventPrice) => (
                                            eventPrice.event.isActiveCongress &&
                                            <div
                                                className='col-12 p-2'
                                                key={eventPrice.id}
                                            >
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => handleChecboxChange(e, eventPrice, 'event')}
                                                    className="mr-2"
                                                    checked={eventIsChecked(eventPrice)}
                                                    id={`event${eventPrice.id}`}
                                                />
                                                <label htmlFor={`event${eventPrice.id}`}>
                                                    {eventPrice.event.name} - {eventPrice.concept}
                                                </label>
                                            </div>
                                        ))}

                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h3>Cuotas</h3>
                        {
                            _.map(paymentsAnualities, (payment) => (
                                <div key={payment.id}>
                                    {payment.year} - {payment.concept} - <span>{payment.type}</span> -
                                    ${moneyFormatter(payment.amount)} MXN
                                </div>
                            ))
                        }
                        {
                            _.map(paymentsEvents, (eventPrice) => (
                                <div key={eventPrice.id}>
                                    {eventPrice.event.name} - {eventPrice.concept} -
                                    ${moneyFormatter(eventPrice.amount)} MXN
                                </div>
                            ))
                        }

                        {
                            totalAmount > 0 &&
                            <div className='mt-2'>
                                <h3>Método de pago</h3>
                                {
                                    _.map(paymentMethods, (paymentMethod) => (
                                        <div key={paymentMethod.id}
                                             className={(withoutPaymentIsSelected && paymentMethod.name !== "SIN PAGO") ? "hide" : ""}>
                                            <input
                                                type="checkbox"
                                                onChange={(e) => handlePaymentMethodChange(e, paymentMethod)}
                                                checked={paymentMethod.selected}
                                                className={"mr-2"}
                                                id={`method${paymentMethod.id}`}
                                            />
                                            <label
                                                htmlFor={`method${paymentMethod.id}`}
                                                className={"mr-2"}
                                            >
                                                {paymentMethod.name}
                                            </label>
                                            {
                                                paymentMethod.selected && !withoutPaymentIsSelected &&
                                                <input
                                                    type="text"
                                                    value={paymentMethod.amount || ''}
                                                    onChange={(e) => updateMethodPaymentAmount(e, paymentMethod)}
                                                    autoFocus
                                                    id={`amount${paymentMethod.id}`}
                                                />
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        }
                        {
                            totalAmount > 0 &&
                            <div>
                                <div className="row mt-2">
                                    <div className="col total">
                                        Total: ${moneyFormatter(totalAmount)} MXN
                                    </div>
                                </div>
                                {
                                    !paymentValid &&
                                    <div className='row'>
                                        <div className='col total'>
                                            Resta: $
                                            {
                                                pendignAmount >= 0 ? moneyFormatter(pendignAmount) : moneyFormatter(totalAmount)
                                            } MXN
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
