import { 
  faGripHorizontal,
  // faSortDown,
  // faSortUp,
  faArrowUp,
  faArrowDown
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const DigitalInput = ({ input }) => {
  return (
    <div className="DigitalInput inputContainer d-flex">
      {input.label &&
        <label for={input.name} className={`font-bold ${input.labelColor||"text-info"}`}>
          {input.label}
        </label>
      }
      <input
        type={input.type}
        className='form-control'
        placeholder={input.placeholder}
        id={input.name}
        onChange={input.onChange}
        value={typeof input.value !== 'undefined'? input.value : ''}
      />
      {input.orderOnChange &&
        (
          <button
            type="button"
            className="btn btn-white mt-2 w-auto p-0 btn-outline-secondary shadow-sm"
            onClick={input.orderOnChange}
          >
            {/* Ordenar por {input.name} */}
            {
              input.orderStatus
              ?
              (
                <>
                  {input.orderStatus === 'ASC' && (
                      <FontAwesomeIcon className="pt-1 ml-5" icon={faArrowUp} />
                  )}
                  {input.orderStatus === 'DESC' && (
                      <FontAwesomeIcon className="pt-1 ml-5" icon={faArrowDown} />
                  )}
                </>
              )
              :
              (
                <FontAwesomeIcon className="pt-1 ml-5" icon={faGripHorizontal} />
              )
            }
          </button>
        )
      }
    </div>
  );
}

export default DigitalInput;
