import moment from "moment";
import {copyPropertyIdOrThrow, copyPropertyOrNull, copyPropertyOrThrow} from "../formUtils";

export const getUserFullName = u=>`${u?.name||''} ${u?.lastNames||''}`;

export const normalizePhoneInput = (value, form) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!form.phone || value.length > form.phone.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
};

export const prepareRegisterFormForServer = ( form )=>{
    const user = {};
    copyPropertyOrThrow(form, user, 'name', 'Por favor llena el campo de nombre', {trim: true});
    copyPropertyOrNull(form, user, 'referredByCode');
    copyPropertyOrThrow(form, user, 'lastNames', 'Por favor llena el campo de apellidos', {trim: true});
    copyPropertyOrThrow(form, user, 'phone', 'Por favor llena el campo de teléfono', {trim: true});
    copyPropertyOrThrow(form, user, 'email', 'Por favor llena el campo de email', {trim: true});
    copyPropertyOrThrow(form, user, 'password', 'Por favor llena el campo de contraseña', {trim: true});
    copyPropertyOrThrow(form, user, 'street', 'Por favor llena el campo de calle y número', {trim: true});
    copyPropertyOrThrow(form, user, 'zipCode', 'Por favor llena el campo de código postal', {trim: true});
    copyPropertyOrThrow(form, user, 'addressReference', 'Por favor llena el campo de referencia de tu dirección', {trim: true});
    copyPropertyIdOrThrow(form, user, 'neighbourhood', 'El código postal introducido no es válido', {trim: true});
    user.username = user.email;

    if(form.password !== form.passConfirmation){
        throw new Error("Las contraseñas no coinciden, verifica que estén escritas correctamente");
    }
    const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if( !user.email?.match(emailPattern) ){
        throw new Error("El email introducido no es válido");
    }

    return user;
}

export const years = (back) => {
    const year = new Date().getFullYear();
    return Array.from({length: back}, (v, i) => year - back + i + 1);
  }

//Esto no va aquí, hay que moverlo a un componente.
// los servicios no se supone que tengan componentes adentro
export const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) =>
<div style={{ display: 'flex', justifyContent: 'center' }}>
  <div className='mr-1'>
    <select className='rounded date-button btn btn-info'
      value={month.month()}
      onChange={(e) => onMonthSelect(month, e.target.value)}
    >
      {moment.months().map((label, value) => (
        <option value={value}>{label}</option>
      ))}
    </select>
  </div>
  <div>
    <select className='rounded date-button btn btn-danger-outline' value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
        {years(100).map((value) => (
        <option value={value}>{value}</option>
        ))}
    </select>
  </div>
</div>

export const userListSGroups = [
    'user_read',
    'user_read_role',
    'role_read',
    'user_read_permission_groups',
    'permission_group_read'
];

export const userWithBalanceAndAddressSGroups = [
    'user_read',
    'user_read_profile',
    'profile_read',
    'profile_read_formatted_address',
    'profile_read_neighbourhood',
        'neighbourhood_read',
    'user_read_role',
    'role_read',
    'user_read_orders_count'
];
export const userForOrderFilters = { sGroups:userWithBalanceAndAddressSGroups, isActive: true };

export const userProfileIdSGroups = [
    'user_read_name',
    'user_read_last_names',
    'user_read_profile',
        'profile_read_id',
];
export const userProfileIdFilters = { sGroups: userProfileIdSGroups, 'order[name]':'ASC' };

export const userReferralasListSGroups = [
    'user_read_name',
    'user_read_last_names',
    'user_read_generated_balance',
    'user_read_used_balance',
    'user_read_income_moves_balance',
    'user_read_profile',
    'profile_read'
];

export const referredsTableSGroups = [
    'profile_read',
    'profile_read_user',
    'profile_read_phone',
    'user_read',
    'user_read_name',
    'user_read_email',
    'user_read_last_names',
];

export const mexicoStates = [
    ['Ciudad de México', 'CDMX'],
    ['Edo. de México', 'edo_mex'],
    ['Aguascalientes', 'aguascalientes'],
    ['Baja California', 'baja_california'],
    ['Baja California Sur', 'baja_california_sur'],
    ['Campeche', 'campeche'],
    ['Coahuila', 'coahuila'],
    ['Colima', 'colima'],
    ['Chiapas', 'chiapas'],
    ['Chihuahua', 'chihuahua'],
    ['Durango', 'durango'],
    ['Guanajuato', 'guanajuato'],
    ['Guerrero', 'guerrero'],
    ['Hidalgo', 'hidalgo'],
    ['Jalisco', 'jalisco'],
    ['Michoacán', 'michoacan'],
    ['Morelos', 'morelos'],
    ['Nayarit', 'nayarit'],
    ['Nuevo León', 'nuevo_leon'],
    ['Oaxaca', 'oaxaca'],
    ['Puebla', 'puebla'],
    ['Querétaro', 'queretaro'],
    ['Quintana Roo', 'quintana_roo'],
    ['San Luis Potosí', 'san_luis_potosi'],
    ['Sinaloa', 'sinaloa'],
    ['Sonora', 'sonora'],
    ['Tabasco', 'tabasco'],
    ['Tamaulipas', 'tamaulipas'],
    ['Tlaxcala', 'tlaxcala'],
    ['Veracruz', 'veracruz'],
    ['Yucatán', 'yucatan'],
    ['Zacatecas', 'zacatecas']
]

export const userPermissionsGroup = [
  {
    name: '04961af4feed16be325cf50a0354b174',
    label: 'Administrar usuarios'
  },
  {
    name: 'ADMIN_SPONSORS',
    label: 'Administrar patrocinadores'
  },
  {
    name: 'ADMIN_EVENTS',
    label: 'Administrar eventos'
  },
  {
    name: 'LIST_PERSONS',
    label: 'Listar personas'
  },
  {
    name: 'ADMIN_PAYMENTS',
    label: 'Administrar pagos'
  },
  {
    name: 'PAYMENTS_REGISTRY',
    label: 'Registrar pagos'
  },
];