import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faPlusCircle,
  faEye
} from '@fortawesome/free-solid-svg-icons';
import _ from "lodash";

export const getSponsorsTableColumns = (
  {deleteSponsor, onSponsorEdit, onAddScholarship, onViewScholarship, events, handleEventSelected, downloadExcel, downloadPdf, seeAttended, me}
) => [
    {
      Header: "ID",
      accessor: sponsor =>
        <div className="text-wrap max-w-80">
          <p className='mb-0 font-weight-bolder'>
              { sponsor.id || 'Sin información' }
          </p>
        </div>
    },
    {
      Header: "Nombre",
      accessor: sponsor =>
        <div className="text-wrap max-w-80">
          <p className='mb-0 font-weight-bolder'>
              { sponsor.name || 'Sin información' }
          </p>
        </div>
    },
    {
      Header: 'Acciones',
      accessor: sponsor =>
        (
          <div className="d-flex flex-justify-between gap-2">
            <button
              className="btn btn-outline-success font-weight-bolder py-1 mr-2"
              title="Ver Becados"
              onClick={() =>onViewScholarship(sponsor)}>
                <FontAwesomeIcon icon={faEye} className='dashboard-logo icon-dash-logo text-dark'/> Ver Becados
            </button>
            <button
              className="btn btn-outline-success font-weight-bolder py-1 mr-2"
              title="Becados"
              onClick={() =>onAddScholarship(sponsor)}>
                <FontAwesomeIcon icon={faPlusCircle} className='dashboard-logo icon-dash-logo text-dark'/> Agregar Becado
            </button>
            {
              me && me.role.name !== 'ROLE_GENERAL_USER' &&
              <>
                <button
                  className="btn btn-outline-danger font-weight-bolder py-1 mr-2"
                  title="Eliminar Patrocinador"
                  onClick={() => deleteSponsor(sponsor)}
                >
                  <FontAwesomeIcon icon={faTrash} className='dashboard-logo icon-dash-logo text-dark'/>
                </button>
                <button
                  className="btn btn-outline-success font-weight-bolder py-1"
                  title="Editar Patrocinador"
                  onClick={() =>onSponsorEdit(sponsor)}>
                    <FontAwesomeIcon icon={faEdit} className='dashboard-logo icon-dash-logo text-dark'/>
                </button>
              </>
            }
            
            <div className="ml-2 d-flex">
              <select
                className="form-control form-control-lg"
                onChange={(e) => handleEventSelected(e, sponsor.id)}
              >
                <option value="">Seleccionar Evento</option>
                {
                  _.map(events, (event) => (
                    <option value={event.id} key={event.id}>
                      {event.name}
                    </option>
                  ))
                }
              </select>
              <div
                className='btn btn-sm btn-success cursor-pointer ml-2 cursor-pointer'
                onClick={() => downloadExcel(sponsor.id)}
              >
                EXCEL
              </div>
              <div
                className='btn btn-sm btn-danger cursor-pointer ml-2 cursor-pointer'
                onClick={() => downloadPdf(sponsor.id)}
              >
                PDF
              </div>
              <div
                className='btn btn-sm btn-success cursor-pointer ml-2 cursor-pointer'
                onClick={() => seeAttended(sponsor.id)}
              >
                ASISTENCIA
              </div>
            </div>

          </div>
        )
    }
]
