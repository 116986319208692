import React, {useCallback, useContext, useEffect, useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faTrash} from '@fortawesome/free-solid-svg-icons';
import "./EditPersonModal.scss"
import {ApiContext} from '../../../../../services/api/api-config';
import useFormState from '../../../../../hooks/useFormState';
import {
    displayTitleOptions,
    displaySpecialtiesOptions,
    prepareFormPersonUpdate,
    existsTitleOption,
    existsSpecialtyOption
} from '../../../../../services/modelUtils/RegisterUtils';
import {getNotifier} from '../../../../../services/notifier';
import {useSelector} from 'react-redux';
import _ from "lodash";
import {PaymentsRegistrySGroups} from "../../PersonsIndexUtils";

export default function EditPersonModal({person, handleCloseModal}) {

    const api = useContext(ApiContext);
    const {form, handleInputChange, setForm} = useFormState({});

    const [first, setFirst] = useState(true)
    const [titleValue, setTitleValue] = useState('');
    const [specialtyValue, setSpecialtyValue] = useState('');
    const [countriesOptions, setCountriesOptions] = useState([]);
    const [country, setCountry] = useState('');
    const [phone, setPhone] = useState('');
    const [showEmailError, setShowEmailError] = useState(false);
    const [fiscalRegime, setFiscalRegime] = useState('');
    const [fiscalRegimeSocialReason, setFiscalRegimeSocialReason] = useState('');
    const [isMexican, setIsMexican] = useState(true)
    const [regimeFiscalOptions, setRegimeFiscalOptions] = useState([]);
    const [eventOptions, setEventOptions] = useState([]);
    const [eventsSelected, setEventsSelected] = useState([])


    const getEvents = useCallback(() => {
        api.events.get().then(response => {
            setEventOptions(response);
        }).catch(
            error => getNotifier().error(error.message)
        );
    }, [api.events]);

    /**
     * Validate email format
     */
    const validateEmail = useCallback((email) => {
        if (/\S+@\S+\.\S+/.test(email)) {
            setShowEmailError(false);
        } else {
            setShowEmailError('true');
        }
    }, []);

    const getCountries = useCallback(() => {
        api.countries.get(
            {
                params: {
                    pagination: false
                }
            }
        ).then(countries => {
            setCountriesOptions(countries)
        }).catch((err) => {
            getNotifier().error(err.detail);
        });
    }, [api.countries]);

    const getRegimeFiscalOptions = useCallback(() => {
        // GET fiscalRegimes list without pagination
        api.fiscalRegimes.get(
            {
                params: {
                    pagination: false,
                    'order[name]': 'ASC',
                }
            }
        ).then(fiscalRegimes => {
            setRegimeFiscalOptions(fiscalRegimes);
        });
    }, [api.fiscalRegimes]);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        let data = null;
        try {
            data = prepareFormPersonUpdate(
                form,
                specialtyValue,
                country,
                fiscalRegime,
                titleValue,
                phone,
                isMexican,
                fiscalRegimeSocialReason,
                eventsSelected
            );
            api.people.update({
                id: person.id,
                params: {...data, sGroups: PaymentsRegistrySGroups}
            }).then(response => {
                getNotifier().success('Actualizado correctamente');
                handleCloseModal();
                //window.location.reload();
            }).catch(
                (e) => getNotifier().error(e.message)
            );
        } catch (e) {
            return getNotifier().error(e.message);
        }
    }, [api.people, country, fiscalRegime, fiscalRegimeSocialReason, form, handleCloseModal, isMexican, person.id, phone, specialtyValue, titleValue, eventsSelected]);

    const checkNationality = useCallback((theCountry) => {
        if (!theCountry || (theCountry && (theCountry === '125' || theCountry === 125))) {
            setIsMexican(true);
        } else {
            setIsMexican(false);
        }
    }, []);

    const handleSetCountry = useCallback((value) => {
        setCountry(value);
        checkNationality(value);
    }, [checkNationality]);

    const isLoading = useSelector(s => !!s.loadingIds['personEditForm'])

    const addEventSelected = useCallback((event) => {
        const found = eventsSelected.find(theEvent => parseInt(event) === theEvent.id);
        if (!found) {
            let theEvents = [...eventsSelected];
            const theIndex = eventOptions.findIndex(theEvent => parseInt(event) === theEvent.id);
            theEvents.push(eventOptions[theIndex]);
            setEventsSelected(theEvents);
        }
    }, [eventOptions, eventsSelected]);

    const removeEvent = useCallback((eventId) => {
        const theIndex = eventsSelected.findIndex(theEvent => eventId === theEvent.id);
        let theEvents = [...eventsSelected];
        theEvents.splice(theIndex, 1);
        const found = person.eventAttendances.find(theEventAttendance => theEventAttendance.event.id === eventId);
        console.log('found in database: ', found);
        if (found) {
            api.eventAttendances.delete({id: found.id});
        }
        setEventsSelected(theEvents);
    }, [api.eventAttendances, eventsSelected, person]);


    const fillForm = useCallback(() => {
        let theTitle = '';
        if (!existsTitleOption(person.title)) {
            theTitle = 'Otro';
            setTitleValue(theTitle);
        } else {
            setTitleValue(person.title);
        }
        let theSpecialty = '';
        if (!existsSpecialtyOption(person.speciality)) {
            theSpecialty = 'Otro';
            setSpecialtyValue(theSpecialty);
        } else {
            setSpecialtyValue(person.speciality);
        }
        setForm(
            {
                id: person.id ? person.id : null,
                title: theTitle === 'Otro' ? 'Otro' : person.title,
                otherTitle: theTitle === 'Otro' ? person.title : '',
                name: person.name,
                patLastName: person.patLastName,
                matLastName: person.matLastName,
                phone: person.phone,
                institution: person.institution ? person.institution : '',
                speciality: theSpecialty === 'Otro' ? 'Otro' : person.speciality,
                otherSpecialty: theSpecialty === 'Otro' ? person.speciality : '',
                country: person.country?.id,
                email: person.email,
                rfc: person.rfc ? person.rfc : '',
                zipCode: person.zipCode ? person.zipCode : '',
                fiscalRegime: person.fiscalRegime ? person.fiscalRegime.id : '',
                socialReason: person.socialReason ? person.socialReason : '',
                socialReasonRfc: person.socialReasonRfc ? person.socialReasonRfc : '',
                zipCodeSocialReason: person.zipCodeSocialReason ? person.zipCodeSocialReason : '',
                fiscalRegimeSocialReason: person.fiscalRegimeSocialReason ? person.fiscalRegimeSocialReason.id : '',
                fiscalRegimeForeigner: person.fiscalRegimeForeigner ? person.fiscalRegimeForeigner : '',
                fiscalRegimeForeignerSocialReason: person.fiscalRegimeForeignerSocialReason ? person.fiscalRegimeForeignerSocialReason : ''
            }
        );
        setCountry(person.country?.id);
        setFiscalRegime(person.fiscalRegime ? person.fiscalRegime.id : '');
        setPhone(person.phone);
        setFiscalRegimeSocialReason(person.fiscalRegimeSocialReason ? person.fiscalRegimeSocialReason.id : '');
        checkNationality(person.country?.id);

        let eventAttendances = [];
        if (person.eventAttendances) {
            person.eventAttendances.forEach(theEvent => {
                eventAttendances.push(theEvent.event);
            });
            setEventsSelected(eventAttendances);
        }

    }, [checkNationality, person.country, person.email, person.fiscalRegime, person.fiscalRegimeForeigner, person.fiscalRegimeForeignerSocialReason, person.fiscalRegimeSocialReason, person.id, person.institution, person.matLastName, person.name, person.patLastName, person.phone, person.rfc, person.socialReason, person.socialReasonRfc, person.speciality, person.title, person.zipCode, person.zipCodeSocialReason,person.eventAttendances, setForm]);

    const handleFieldChange = useCallback((value, field) => {
        let regex = /^[a-zA-Z\u00C0-\u024F]+(?:['’][a-zA-Z\u00C0-\u024F]*)?(?:[a-zA-Z\u00C0-\u024F]+\s*)*$/i;
        if (field === 'rfc' || field === 'socialReasonRfc') {
            regex = /^[a-zA-Z0-9]+$/;
            value = value.toUpperCase();
        }
        if (field === 'socialReason') {
            regex = /^[a-zA-Z0-9\s]+$/;
        }
        if (field === 'email') {
            regex = /^[\w.+-@]+$/;
        }
        if (field === 'zipCode' || field === 'zipCodeSocialReason') {
            regex = /^[0-9]+$/;
        }
        if (value === "" || regex.test(value)) {
            setForm(
                {
                    ...form,
                    [field]: value
                }
            )
        }
    }, [form, setForm]);

    useEffect(() => {
        if (first) {
            setFirst(false);
            getEvents();
            getCountries();
            getRegimeFiscalOptions();
            fillForm();
        }
    }, [fillForm, first, getCountries, getRegimeFiscalOptions, person.title, getEvents])


    return (
        <div className='EditPersonModal'>
            <div className="card card-custom gutter-b">
                <div className="card-header border-0 pt-2">
                    <h3 className="card-title align-items-start flex-column mb-1">
            <span className="card-label font-size-h3 font-weight-bolder text-dark">
              Editar Doctor
            </span>
                    </h3>
                    <div
                        className="close-container d-flex justify-content-end pt-2 cursor-pointer"
                        onClick={handleCloseModal}
                    >
                        <FontAwesomeIcon icon={faTimes} className='close-icon pink-color'/>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <form>
                        {/* Row for title and name */}
                        <div className='row'>
                            <div className='col-sm-12 col-lg-2'>
                                <div className="mt-1">
                                    <div className="form-group mb-1">
                                        <label className="font-weight-bolder">Título</label>
                                        <select
                                            className="form-control form-control-lg"
                                            value={titleValue}
                                            onChange={(e) => setTitleValue(e.target.value)}
                                        >
                                            <option
                                                className='font-bold'
                                                key={0}
                                                value=""
                                            >
                                                Seleccionar
                                            </option>
                                            {_.map(displayTitleOptions, (option, index) => (
                                                <option
                                                    className='font-bold'
                                                    key={index}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {
                                titleValue === 'Otro' &&
                                <div className='col-sm-12 col-lg-4'>
                                    <div className="mt-1">
                                        <div className="form-group mb-1">
                                            <label className="font-weight-bolder">Especificar título</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                value={form.otherTitle || ''}
                                                onChange={handleInputChange('otherTitle')}
                                                placeholder="Ingresa el título"
                                                maxLength={200}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='col-sm-12 col-lg-6'>
                                <div className="mt-1">
                                    <div className="form-group mb-1">
                                        <label className="font-weight-bolder">Nombre(s)*</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            value={form.name || ''}
                                            onChange={handleInputChange('name')}
                                            placeholder="Ingresa el nombre(s)"
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Row for patLastName and matLastName */}
                        <div className='row'>
                            <div className='col-sm-12 col-lg-6'>
                                <div className="mt-1">
                                    <div className="form-group mb-1">
                                        <label className="font-weight-bolder">Apellido paterno*</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            value={form.patLastName || ''}
                                            onChange={handleInputChange('patLastName')}
                                            placeholder="Ingresa el apellido paterno"
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-lg-6'>
                                <div className="mt-1">
                                    <div className="form-group mb-1">
                                        <label className="font-weight-bolder">Apellido materno</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            value={form.matLastName || ''}
                                            onChange={handleInputChange('matLastName')}
                                            placeholder="Ingresa el apellido materno"
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Row for phone */}
                        <div className='row mt-1'>
                            <div className='col-sm-12 col-md-4'>
                                <div className="mt-1">
                                    <div className="form-group mb-2">
                                        <label className="font-weight-bolder">Teléfono</label>
                                        {/* <PhoneInput
                                international
                                placeholder="Ingresa el teléfono"
                                value={phone || ''}
                                onChange={(e) => handleSetPhone(e)}
                                maxLength={16}
                                defaultCountry="MX"
                                labels={es}
                            /> */}
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            value={form.phone || ''}
                                            placeholder="Ingresa el teléfono"
                                            maxLength={15}
                                            onChange={(e) => handleInputChange('phone')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-lg-4'>
                                <div className="mt-1">
                                    <div className="form-group mb-1">
                                        <label className="font-weight-bolder">Especialidad</label>
                                        <select
                                            className="form-control form-control-lg"
                                            value={specialtyValue}
                                            onChange={(e) => setSpecialtyValue(e.target.value)}
                                        >
                                            <option
                                                className='font-bold'
                                                key={0}
                                                value=""
                                            >
                                                Seleccionar
                                            </option>
                                            {_.map(displaySpecialtiesOptions, (option, index) => (
                                                <option
                                                    className='font-bold'
                                                    key={index}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {
                                specialtyValue === 'Otro' &&
                                <div className='col-sm-12 col-lg-4'>
                                    <div className="mt-1">
                                        <div className="form-group mb-1">
                                            <label className="font-weight-bolder">Especificar</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                value={form.otherSpecialty || ''}
                                                onChange={handleInputChange('otherSpecialty')}
                                                placeholder="Ingresa el nombre de la especialidad"
                                                maxLength={200}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {/* Row for country, email */}
                        <div className='row mt-1'>
                            <div className='col-sm-12 col-lg-6'>
                                <div className="mt-1">
                                    <div className="form-group mb-1">
                                        <label className="font-weight-bolder">País</label>
                                        <select
                                            className="form-control form-control-lg"
                                            value={country}
                                            onChange={(e) => handleSetCountry(e.target.value)}
                                        >
                                            <option
                                                className='font-bold'
                                                key={0}
                                                value=""
                                            >
                                                Seleccionar
                                            </option>
                                            {_.map(countriesOptions, (option) => (
                                                <option
                                                    className='font-bold'
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6'>
                                <div className="mt-1">
                                    <div className="form-group mb-1">
                                        <label className="font-weight-bolder">Correo electrónico*</label>
                                        <input
                                            type="email"
                                            className="form-control form-control-lg"
                                            value={form.email || ''}
                                            onChange={handleInputChange('email')}
                                            placeholder="Ingresa el correo electrónico"
                                            maxLength={200}
                                            onBlur={(e) => validateEmail(e.target.value)}
                                        />
                                        {
                                            showEmailError &&
                                            <div className='text-danger'>
                                                El correo electrónico no es válido
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Row for zipCode and fiscal regime */}
                        <div className='row mt-1'>
                            <div className='col-sm-12 col-lg-4'>
                                <div className="mt-1">
                                    <div className="form-group mb-1">
                                        <label className="font-weight-bolder">Código postal*</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            value={form.zipCode || ''}
                                            // onChange={handleInputChange('zipCode')}
                                            placeholder="Ingresa el código postal"
                                            maxLength={5}
                                            // onKeyUp={(e) => handleNumericInput(e.target.value, 'zipCode')}
                                            onChange={(e) => handleFieldChange(e.target.value, 'zipCode')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-lg-8'>
                                {
                                    isMexican &&
                                    <div className="mt-1">
                                        <div className="form-group mb-1">
                                            <label className="font-weight-bolder">Régimen fiscal*</label>
                                            <select
                                                className="form-control form-control-lg"
                                                value={fiscalRegime}
                                                onChange={(e) => setFiscalRegime(e.target.value)}
                                            >
                                                <option
                                                    className='font-bold'
                                                    key={0}
                                                    value=""
                                                >
                                                    Seleccionar
                                                </option>
                                                {_.map(regimeFiscalOptions, (option) => (
                                                    <option
                                                        className='font-bold'
                                                        key={option.id}
                                                        value={option.id}
                                                    >
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                }

                                {
                                    !isMexican &&
                                    <div className="mt-1">
                                        <div className="form-group mb-1">
                                            <label className="font-weight-bolder">Régimen fiscal extranjero *</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                value={form.fiscalRegimeForeigner || ''}
                                                placeholder="Ingresa el regimen fiscal extranjero"
                                                maxLength={200}
                                                onChange={handleInputChange('fiscalRegimeForeigner')}
                                            />
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                        {/* Row for socialReason and social reason RFC */}
                        <div className='row mt-1'>
                            <div className='col-sm-12 col-lg-6'>
                                <div className="mt-1">
                                    <div className="form-group mb-1">
                                        <label className="font-weight-bolder">
                                            Razón social (<span
                                            className='text-danger'>en caso de no ser la misma</span>)
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            value={form.socialReason || ''}
                                            // onChange={handleInputChange('socialReason')}
                                            placeholder="Ingresa la razón social"
                                            maxLength={200}
                                            onChange={(e) => handleFieldChange(e.target.value, 'socialReason')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6'>
                                <div className="mt-1">
                                    <div className="form-group mb-1">
                                        <label className="font-weight-bolder">
                                            RFC
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            value={form.socialReasonRfc || ''}
                                            // onChange={handleInputChange('socialReasonRfc')}
                                            placeholder="Ingresa el RFC"
                                            maxLength={15}
                                            onChange={(e) => handleFieldChange(e.target.value, 'socialReasonRfc')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Eventos */}
                        <div className='row mt-1'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className="mt-1">
                                    <div className="form-group mb-1">
                                        <label className="font-weight-bolder">
                                            Evento
                                        </label>
                                        <select
                                            className="form-control form-control-lg"
                                            value={''}
                                            onChange={(e) => addEventSelected(e.target.value)}
                                        >
                                            <option
                                                className='font-bold'
                                                key={0}
                                                value=""
                                            >
                                                Seleccionar
                                            </option>
                                            {_.map(eventOptions, (event) => (
                                                <option
                                                    className='font-bold'
                                                    key={event.id}
                                                    value={event.id}
                                                >
                                                    {event.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            eventsSelected.length > 0 &&
                            <div>
                                <h4>Eventos/Congresos seleccionados</h4>
                                <ul>
                                    {
                                        _.map(eventsSelected, (theEvent) => (
                                            <li key={theEvent.id}>
                                                <div className='d-flex'>
                                                    <div onClick={() => removeEvent(theEvent.id)}>
                                                        <FontAwesomeIcon icon={faTrash} className='mr-2 cursor-pointer'/>
                                                    </div>
                                                    <div>
                                                        {theEvent.name}
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        }
                        <div className='mt-2'>
                            <button
                                type="submit"
                                disabled={isLoading}
                                onClick={handleSubmit}
                                className="btn btn-primary font-weight-bolder mr-2 px-8"
                            >
                                Actualizar
                            </button>
                            <button
                                type="reset"
                                className="btn btn-clear font-weight-bolder text-muted px-8"
                                onClick={handleCloseModal}
                            >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
