import React, {useContext, useMemo} from 'react'
import {ApiContext} from '../../../../../services/api/api-config';
import {getEventsTableColumns, eventsSGroups} from '../../EventsIndexUtils';
import useTideTable from "../../../../../hooks/useTideTable";
import TideReactTable from "../../../../../components/TideReactTable/TideReactTable";

export default function EventsTable(
    {onEventEdit, deleteEvent, filters, showRegisters, type}
) {
    const api = useContext(ApiContext);

    const eventsTableColumns = useMemo(() => getEventsTableColumns(
        {api, onEventEdit, deleteEvent, showRegisters, type}
    ), [api, onEventEdit, deleteEvent, showRegisters, type]);

    const eventFilters = useMemo(() => ({
        pagination: true,
        sGroups: eventsSGroups,
        ...filters
    }), [filters]);

    const eventsTable = useTideTable({
        entity: 'events',
        columns: eventsTableColumns,
        requestFilters: eventFilters,
    });

    return (
        <div
            className="EventsTableView card card-custom card-shadowless product-container"
        >
            <div className="card-body p-5">
                <TideReactTable {...eventsTable.tableProps}/>
            </div>
        </div>
    )
}
