import React, { useContext, useEffect, useState } from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import { getNotifier } from '../../../../../services/notifier';
import { ApiContext } from '../../../../../services/api/api-config';
import _ from "lodash";
import "./ScholarshipList.scss"

export default function ScholarshipList(
  {closeModal, sponsorId}
) {

  const api = useContext(ApiContext)
  const [first, setFirst] = useState(true)
  const [scholarships, setScholarships] = useState([])

  useEffect(() => {
    if (first) {
      setFirst(false);
      api.people.get({
        params: {
          type: 'BECADO',
          'sponsor.id': sponsorId,
          sGroups: [
            'person_read',
            'person_read_event',
            'event_read',
            'person_read_country',
            'country_read',
            'person_read_event_attendance',
            'event_attendance_read',
            'event_attendance_read_event',
          ]
        }
      }).then(response => {
        setScholarships(response);
      }).catch(
        error => getNotifier().error('Ocurrió un error')
      );
    }
  }, [api.people, first, sponsorId])
  

  return (
    <div className='ScholarshipList'>
      <div className="card card-custom gutter-b">
        <div className="card-header border-0 pt-2">
            <h3 className="card-title align-items-start flex-column mb-1">
              <span className="card-label font-size-h3 font-weight-bolder text-dark">
                Lista de Becados
              </span>
            </h3>
          <div
            className="close-container d-flex justify-content-end pt-2 cursor-pointer"
            onClick={closeModal}
          >
            <FontAwesomeIcon icon={faTimes} className='close-icon pink-color'/>
          </div>
        </div>
        <div className="card-body pt-0">
          <table className='table table-responsive'>
            <thead>
              <tr>
                <th>
                  Título
                </th>
                <th>
                  Nombre(s)
                </th>
                <th>
                  Apellido Paterno
                </th>
                <th>
                  Apellido Materno
                </th>
                <th>
                  Correo Electrónico
                </th>
                <th>
                  Teléfono
                </th>
                <th>
                  País
                </th>
                <th>
                  Eventos
                </th>
              </tr>
            </thead>
            <tbody>
              {
                _.map(scholarships, (scholarship) => (
                  <tr key={scholarship.id}>
                    <td>
                      {scholarship.title}
                    </td>
                    <td>
                      {scholarship.name}
                    </td>
                    <td>
                      {scholarship.patLastName}
                    </td>
                    <td>
                      {scholarship.matLastName}
                    </td>
                    <td>
                      {scholarship.email}
                    </td>
                    <td>
                      {scholarship.phone}
                    </td>
                    <td>
                      {scholarship.country ? scholarship.country.name : ''}
                    </td>
                    <td>
                      {
                        scholarship.eventAttendances &&
                        <ul>
                          {
                            _.map(scholarship.eventAttendances, (attendance, index) => (
                              <li key={index}>
                                {attendance.event.name}
                              </li>
                            ))
                          }
                        </ul>
                      }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
