import React, {useCallback, useContext, useState} from 'react'
import "./PrintTags.scss"
import useFormState from '../../../hooks/useFormState';
import {
    displayTitleOptions
} from '../../../services/modelUtils/RegisterUtils';
import _ from "lodash";
import {ApiContext} from '../../../services/api/api-config';
import {getNotifier} from '../../../services/notifier';
import {Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {prepareForm} from '../../../services/modelUtils/printTagUtils';

export default function PrintTags() {

    const [titleValue, setTitleValue] = useState('');
    const [showIframe, setShowIframe] = useState(false)
    const [pdfUrl, setPdfUrl] = useState('')

    const {form, handleInputChange, setForm} = useFormState();
    const api = useContext(ApiContext)

    const handleFieldChange = useCallback((value, field) => {
        setForm(
            {
                ...form,
                [field]: value
            }
        )
    }, [form, setForm]);

    /**
     * Handle clean form
     */
    const handleCleanForm = useCallback(() => {
        setForm({});
        setTitleValue('');
    }, [setForm]);

    const handlePrintTag = useCallback((e) => {
        e.preventDefault();
        let data = null;
        try {
            data = prepareForm(
                form,
                titleValue
            );
        } catch (e) {
            return getNotifier().error(e.message);
        }
        api.people.printTagOnly({
            params: data,
        }).then((response) => {
            // setPdfUrl(`https://registro-back.amcemig.com/${response}`)
            setPdfUrl(`${response}`)
            setShowIframe(true);
            handleCleanForm();
        });

    }, [api.people, form, titleValue, handleCleanForm]);


    return (
        <div className='PrintTags'>
            <div className="d-flex flex-row">
                <div className="flex-row-fluid">
                    <div className="card speakers-index card-custom card-stretch gutter-b">
                        <div className="card-body">
                            <div className="mb-11">
                                <h2>Imprimir etiqueta</h2>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12 col-lg-2'>
                                    <div className="mt-1">
                                        <div className="form-group mb-1">
                                            <label className="font-weight-bolder">Título </label>
                                            <select
                                                className="form-control form-control-lg"
                                                value={titleValue}
                                                onChange={(e) => setTitleValue(e.target.value)}
                                            >
                                                <option
                                                    className='font-bold'
                                                    key={0}
                                                    value=""
                                                >
                                                    Seleccionar
                                                </option>
                                                {_.map(displayTitleOptions, (option, index) => (
                                                    <option
                                                        className='font-bold'
                                                        key={index}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {
                                    titleValue === 'Otro' &&
                                    <div className='col-sm-12 col-lg-4'>
                                        <div className="mt-1">
                                            <div className="form-group mb-1">
                                                <label className="font-weight-bolder">Especificar título </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-lg"
                                                    value={form.otherTitle || ''}
                                                    onChange={handleInputChange('otherTitle')}
                                                    placeholder="Ingresa el título"
                                                    maxLength={200}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className='col-sm-12 col-lg-6'>
                                    <div className="mt-1">
                                        <div className="form-group mb-1">
                                            <label className="font-weight-bolder">Nombre(s) </label>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                value={form.name || ''}
                                                placeholder="Ingresa el nombre(s)"
                                                maxLength={200}
                                                onChange={(e) => handleFieldChange(e.target.value, 'name')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12 col-lg-6'>
                                    <div className="mt-1">
                                        <div className="form-group mb-1">
                                            <label className="font-weight-bolder">Apellido paterno </label>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                value={form.patLastName || ''}
                                                placeholder="Ingresa el apellido paterno"
                                                maxLength={200}
                                                onChange={(e) => handleFieldChange(e.target.value, 'patLastName')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-lg-6'>
                                    <div className="mt-1">
                                        <div className="form-group mb-1">
                                            <label className="font-weight-bolder">Apellido materno</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                value={form.matLastName || ''}
                                                placeholder="Ingresa el apellido materno"
                                                maxLength={200}
                                                onChange={(e) => handleFieldChange(e.target.value, 'matLastName')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-1'>
                                <div className='col-sm-12 col-lg-4'>
                                    <div className="mt-1">
                                        <div className="form-group mb-1">
                                            <label className="font-weight-bolder">Subtítulo </label>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                value={form.country || ''}
                                                placeholder=""
                                                maxLength={200}
                                                onChange={(e) => handleFieldChange(e.target.value, 'country')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-1'>
                                <div className='col mt-1'>
                                    <button
                                        type="submit"
                                        onClick={handlePrintTag}
                                        className="btn btn-primary font-weight-bolder mr-2 px-8"
                                    >
                                        Imprimir
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showIframe &&
                <Modal
                    show={showIframe} onHide={() => setShowIframe(false)}
                >
                    <div
                        className="d-flex p-2 cursor-pointer icon-container"
                        onClick={() => setShowIframe(false)}
                    >
                        <FontAwesomeIcon icon={faTimes} className='custom-close-icon pink-color'/>
                    </div>
                    <iframe
                        src={`${pdfUrl}#view=fitH`}
                        title="tagPdf"
                        height="500"
                        width="500"
                    />
                </Modal>
            }
        </div>
    )
}
