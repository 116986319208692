import React, {useContext, useCallback, useState, useEffect, useRef} from 'react'
import {ApiContext} from '../../../../../services/api/api-config';
import useFormState from '../../../../../hooks/useFormState';
import {useSelector} from "react-redux";
import _ from "lodash";
import {getNotifier} from '../../../../../services/notifier';
import PaymentFormGeneral from '../PaymentFormGeneral/PaymentFormGeneral';
import {
    displayTitleOptions,
    displaySpecialtiesOptions,
    getEmptyRegistryForm,
    getEmptyPaymentSingle,
    prepareFormPaymentsRegistries,
    existsTitleOption,
    existsSpecialtyOption
} from '../../../../../services/modelUtils/RegisterUtils';
import 'react-phone-number-input/style.css'
import {PaymentsRegistrySGroups} from '../../../Persons/PersonsIndexUtils';
import {Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import "./RegisterForm.scss";

const RegisterForm = (
    {closeForm, person, editPaymentRegistryId}
) => {

    const api = useContext(ApiContext);
    const {form, handleInputChange, setForm} = useFormState(() => getEmptyRegistryForm());
    const [titleValue, setTitleValue] = useState('');
    const [specialtyValue, setSpecialtyValue] = useState('');
    const [countriesOptions, setCountriesOptions] = useState([]);
    const [regimeFiscalOptions, setRegimeFiscalOptions] = useState([]);
    const [country, setCountry] = useState(125);
    const [fiscalRegime, setFiscalRegime] = useState('');
    const [phone, setPhone] = useState('+52');
    const [isMexican, setIsMexican] = useState(true)
    const [showEmailError, setShowEmailError] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState(null)
    const [fiscalRegimeSocialReason, setFiscalRegimeSocialReason] = useState('');
    const bottomPageRef = useRef(null)
    const [showIframe, setShowIframe] = useState(false)
    const [pdfUrl, setPdfUrl] = useState('')
    const [pdfUrlPayment, setPdfUrlPayment] = useState('')
    const [showIframePdf, setShowIframePdf] = useState(false)
    const [paymentsKey, setPaymentsKey] = useState(0);
    const [editPaymentRegistry, setEditPaymentRegistry] = useState(null)


    const remountPayment = () => {
        setPaymentsKey(prevKey => prevKey + 1);
    }
    /**
     * Scroll to bottom of the page
     */
    const scrollToBottom = () => {
        bottomPageRef.current?.scrollIntoView({behavior: "smooth"})
    }

    const checkNationality = useCallback((theCountry) => {
        if (!theCountry || (theCountry && (theCountry === '125' || theCountry === 125))) {
            setIsMexican(true);
        } else {
            setIsMexican(false);
        }
    }, []);


    const resetForm = useCallback(() => {
        setForm(getEmptyRegistryForm());
        setTitleValue('');
        setSpecialtyValue('');
        setFiscalRegime('');
        setCountry('');
        setPhone('');
        setFiscalRegimeSocialReason('');
        setPaymentDetails(null);
        setEditPaymentRegistry(null);
        remountPayment();
    }, [ setForm]);

    const handleCleanForm = useCallback(() => {
        resetForm();
        closeForm();
    }, [closeForm, resetForm]);

    useEffect(() => {
        if (editPaymentRegistryId) {
            api.paymentsRegistries.get({
                params: {
                    id: editPaymentRegistryId,
                    sGroups: PaymentsRegistrySGroups
                }
            }).then((data) => {
                if (data.length > 0) {
                    setEditPaymentRegistry(data[0])
                }
            });
        }else{
            resetForm();
        }

    }, [api, editPaymentRegistryId, resetForm]);
    /**
     * Validate email format
     */
    const validateEmail = useCallback((email) => {
        if (/\S+@\S+\.\S+/.test(email)) {
            setShowEmailError(false);
        } else {
            setShowEmailError('true');
        }
    }, []);



    /**
     * Handle save person information
     * Download pdf
     */
    const downloadPrintPdf = useCallback(async (personId) => {
        try {
            const fileLocation = await api.people.printTag(personId);
            setPdfUrl(`${fileLocation}`)

            setShowIframe(true);
        } catch (error) {
            console.error('Error printing file:', error);
        }
    }, [api.people]);

    /**
     * Handle save person information
     * Download pdf
     */
    const downloadPdf = useCallback(async (paymentRegistryId, person) => {
        try {
            const fileLocation = await api.payments_registry.getPdfUrl({id: paymentRegistryId});
            setPdfUrlPayment(fileLocation);
            setShowIframePdf(true);
        } catch (error) {
            console.error('Error printing file:', error);
        }
        window.setTimeout(downloadPrintPdf(person.id), 1000);
    }, [api.payments_registry, downloadPrintPdf]);


    const setEventAttended = useCallback((person, event, maxSequential) => {
        const params = {
            event: event.id,
            person: person.id,
            attended: false,
            sequential: maxSequential + 1
        };
        api.eventAttendances.create({params}).catch(
            error => getNotifier().error(error.message)
        );

    }, [api.eventAttendances]);

    const getMaxSequential = useCallback((person, event) => {
        api.eventAttendances.get({
            params: {
                event: event.id
            }
        }).then(response => {
            setEventAttended(person, event, response.length);
        }).catch(
            error => getNotifier().error(error.message)
        );
    }, [api.eventAttendances, setEventAttended]);

    const verifyIsCongress = useCallback((events, person) => {
        events.forEach(event => {
            if (event.isCongress && event.isActiveCongress) {
                getMaxSequential(person, event);
            }
        });
    }, [getMaxSequential]);

    const saveDoctor = useCallback((data, sequential) => {
        const apiMethod = person && editPaymentRegistry ? 'update' : 'create';
        const theInfo = {...data};
        if (sequential) {
            theInfo.person.sequential = sequential;
        }
        api.paymentsRegistries[apiMethod]({
            ...(person && editPaymentRegistry && {id: editPaymentRegistry.id + ''}),
            loadingId: 'PersonsForm',
            params: {
                ...theInfo,
                sGroups: PaymentsRegistrySGroups
            },
        }).then((response) => {
            handleCleanForm();

            downloadPdf(response.id, response.person);
            if (data.payments && data.payments.length && data.payments[0].eventDetails) {
                verifyIsCongress(data.payments[0].eventDetails, response.person);
            }
        });
    }, [api.paymentsRegistries, downloadPdf, handleCleanForm, person, verifyIsCongress, editPaymentRegistry]);

    const getDoctorSequential = useCallback((data, isEditing) => {
        if (!isEditing) {
            api.people.get({
                params: {
                    type: 'DOCTOR',
                    "order[id]": "DESC",
                    sGroups: PaymentsRegistrySGroups
                }
            }).then(response => {
                console.log('response: ', response);
                let sequential = 'D01'
                if (response.length > 0) {
                    const lastRegister = response[0];
                    if (lastRegister.sequential) {
                        let currentSequential = parseInt(lastRegister.sequential.substring(1));
                        if (currentSequential < 9) {
                            sequential = 'D0' + (currentSequential + 1);
                        } else {
                            sequential = 'D' + (currentSequential + 1);
                        }
                    }
                }
                saveDoctor(data, sequential);
            }).catch(
                error => getNotifier().error(error.message)
            );
        } else {
            saveDoctor(data, null);
        }

    }, [api.people, saveDoctor]);

    /**
     * Handle the save person information
     */
    const handleSavePaymentsRegistry = useCallback((e) => {
        e.preventDefault();
        let data = null;
        let isEditing = person ? true : false;
        try {
            data = prepareFormPaymentsRegistries(
                form,
                specialtyValue,
                country,
                fiscalRegime,
                titleValue,
                phone,
                paymentDetails,
                isMexican,
                fiscalRegimeSocialReason,
                isEditing
            );
        } catch (e) {
            console.log(e);

            return getNotifier().error(e.message);
        }
        getDoctorSequential(data, isEditing);
    }, [getDoctorSequential, form, specialtyValue, country, fiscalRegime, titleValue, phone, paymentDetails, isMexican, fiscalRegimeSocialReason, person]);

    /**
     * Fill form data with person selected
     */
    const fillForm = useCallback((person) => {


        let theTitle = '';
        if (!existsTitleOption(person.title)) {
            theTitle = 'Otro';
            setTitleValue(theTitle);
        } else {
            setTitleValue(person.title);
        }
        let theSpecialty = '';
        if (!existsSpecialtyOption(person.speciality)) {
            theSpecialty = 'Otro';
            setSpecialtyValue(theSpecialty);
        } else {
            setSpecialtyValue(person.speciality);
        }
        setForm(
            {
                id: person.id ? person.id : null,
                title: theTitle === 'Otro' ? 'Otro' : person.title,
                otherTitle: theTitle === 'Otro' ? person.title : '',
                name: person.name,
                patLastName: person.patLastName,
                matLastName: person.matLastName,
                phone: person.phone,
                institution: person.institution ? person.institution : '',
                speciality: theSpecialty === 'Otro' ? 'Otro' : person.speciality,
                otherSpecialty: theSpecialty === 'Otro' ? person.speciality : '',
                country: person.country?.id,
                email: person.email,
                rfc: person.rfc ? person.rfc : '',
                zipCode: person.zipCode ? person.zipCode : '',
                fiscalRegime: person.fiscalRegime ? person.fiscalRegime.id : '',
                socialReason: person.socialReason ? person.socialReason : '',
                socialReasonRfc: person.socialReasonRfc ? person.socialReasonRfc : '',
                zipCodeSocialReason: person.zipCodeSocialReason ? person.zipCodeSocialReason : '',
                fiscalRegimeSocialReason: person.fiscalRegimeSocialReason ? person.fiscalRegimeSocialReason.id : '',
                fiscalRegimeForeigner: person.fiscalRegimeForeigner ? person.fiscalRegimeForeigner : '',
                fiscalRegimeForeignerSocialReason: person.fiscalRegimeForeignerSocialReason ? person.fiscalRegimeForeignerSocialReason : '',
                payments: [getEmptyPaymentSingle()],
                fiscalAddress: person.fiscalAddress ? person.fiscalAddress : ''
            }
        );
        setCountry(person.country ? person.country.id : 0);
        setFiscalRegime(person.fiscalRegime ? person.fiscalRegime.id : '');
        setPhone(person.phone);
        setFiscalRegimeSocialReason(person.fiscalRegimeSocialReason ? person.fiscalRegimeSocialReason.id : '');
        checkNationality(person.country?.id);
    }, [checkNationality, setForm]);

    /**
     * Use effect hook, get countries, fiscal regimes, events, payment methods and sponsors
     */
    useEffect(() => {
        // GET countries list without pagination
        api.countries.get(
            {
                params: {
                    pagination: false
                }
            }
        ).then(countries => {
            setCountriesOptions(countries)
        }).catch((err) => {
            getNotifier().error(err.detail);
        });

        // GET fiscalRegimes list without pagination
        api.fiscalRegimes.get(
            {
                params: {
                    pagination: false,
                    'order[name]': 'ASC',
                }
            }
        ).then(fiscalRegimes => {
            setRegimeFiscalOptions(fiscalRegimes);
        });

        // Scroll to bottom of page when new payment form is added
        if (form.payments.length > 1) {
            scrollToBottom();
        }

        // Verify if person is selected and is the first time
        if (person) {
            fillForm(person);
        }
    }, [api, fillForm, form.payments.length, person, setForm]);

    const handleSetCountry = useCallback((value) => {
        setCountry(value);
        checkNationality(value);
    }, [checkNationality]);

    const onPaymentValid = useCallback((params) => {
        setPaymentDetails(params);
    }, []);

    const handleFieldChange = useCallback((value, field) => {
        let regex = /^[a-zA-Z\u00C0-\u024F]+(?:['’][a-zA-Z\u00C0-\u024F]*)?(?:[a-zA-Z\u00C0-\u024F]+\s*)*$/i;


        if ((field === 'rfc' || field === 'socialReasonRfc')) {
            if (isMexican) {
                regex = /^[a-zA-Z0-9]+$/;
                value = value.toUpperCase();
            } else {
                regex = /^[a-zA-Z0-9\s]+$/;
            }
        }

        if (field === 'socialReason') {
            regex = /^[a-zA-Z0-9\s]+$/;
        }
        if (field === 'email') {
            regex = /^[\w.+-@]+$/;
        }
        if (field === 'zipCode' || field === 'zipCodeSocialReason' || field === 'phone') {
            regex = /^[0-9]+$/;
        }
        if (value === "" || regex.test(value)) {
            setForm(
                {
                    ...form,
                    [field]: value
                }
            )
        }
    }, [form, isMexican, setForm]);

    const isLoading = useSelector(s => !!s.loadingIds['PersonsForm'])


    return (
        <div className='RegisterForm'>
            <div className='row'>
                <div className='col-6'>
                    <h3 className='text-dark font-weight-bold'>
                        {
                            person ? 'Actualizar registro' : 'Agregar registro'
                        }
                    </h3>
                </div>
            </div>
            <form>
                {/* Row for title and name */}
                <div className='row'>
                    <div className='col-sm-12 col-lg-2'>
                        <div className="mt-1">
                            <div className="form-group mb-1">
                                <label className="font-weight-bolder">Título *</label>
                                <select
                                    className="form-control form-control-lg"
                                    value={titleValue}
                                    onChange={(e) => setTitleValue(e.target.value)}
                                >
                                    <option
                                        className='font-bold'
                                        key={0}
                                        value=""
                                    >
                                        Seleccionar
                                    </option>
                                    {_.map(displayTitleOptions, (option, index) => (
                                        <option
                                            className='font-bold'
                                            key={index}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    {
                        titleValue === 'Otro' &&
                        <div className='col-sm-12 col-lg-4'>
                            <div className="mt-1">
                                <div className="form-group mb-1">
                                    <label className="font-weight-bolder">Especificar título *</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg"
                                        value={form.otherTitle || ''}
                                        onChange={handleInputChange('otherTitle')}
                                        placeholder="Ingresa el título"
                                        maxLength={200}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    <div className='col-sm-12 col-lg-6'>
                        <div className="mt-1">
                            <div className="form-group mb-1">
                                <label className="font-weight-bolder">Nombre(s) *</label>
                                <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    value={form.name || ''}
                                    // onChange={handleInputChange('name')}
                                    placeholder="Ingresa el nombre(s)"
                                    maxLength={200}
                                    onChange={(e) => handleFieldChange(e.target.value, 'name')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Row for patLastName and matLastName */}
                <div className='row'>
                    <div className='col-sm-12 col-lg-6'>
                        <div className="mt-1">
                            <div className="form-group mb-1">
                                <label className="font-weight-bolder">Apellido paterno *</label>
                                <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    value={form.patLastName || ''}
                                    // onChange={handleInputChange('patLastName')}
                                    placeholder="Ingresa el apellido paterno"
                                    maxLength={200}
                                    onChange={(e) => handleFieldChange(e.target.value, 'patLastName')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-6'>
                        <div className="mt-1">
                            <div className="form-group mb-1">
                                <label className="font-weight-bolder">Apellido materno</label>
                                <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    value={form.matLastName || ''}
                                    // onChange={handleInputChange('matLastName')}
                                    placeholder="Ingresa el apellido materno"
                                    maxLength={200}
                                    onChange={(e) => handleFieldChange(e.target.value, 'matLastName')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Row for phone and institution */}
                <div className='row mt-1'>
                    <div className='col-sm-12 col-md-4'>
                        <div className="mt-1">
                            <div className="form-group mb-2">
                                <label className="font-weight-bolder">Teléfono</label>
                                <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    value={form.phone || ''}
                                    placeholder="Ingresa el teléfono"
                                    maxLength={15}
                                    onChange={(e) => handleFieldChange(e.target.value, 'phone')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-8'>
                        <div className="mt-1">
                            <div className="form-group mb-1">
                                <label className="font-weight-bolder">Institución</label>
                                <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    value={form.institution || ''}
                                    onChange={handleInputChange('institution')}
                                    placeholder="Ingresa el nombre de la institución"
                                    maxLength={200}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Row for specialty and specify specialty */}
                <div className='row mt-1'>
                    <div className='col-sm-12 col-lg-6'>
                        <div className="mt-1">
                            <div className="form-group mb-1">
                                <label className="font-weight-bolder">Especialidad</label>
                                <select
                                    className="form-control form-control-lg"
                                    value={specialtyValue}
                                    onChange={(e) => setSpecialtyValue(e.target.value)}
                                >
                                    <option
                                        className='font-bold'
                                        key={0}
                                        value=""
                                    >
                                        Seleccionar
                                    </option>
                                    {_.map(displaySpecialtiesOptions, (option, index) => (
                                        <option
                                            className='font-bold'
                                            key={index}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    {
                        specialtyValue === 'Otro' &&
                        <div className='col-sm-12 col-lg-6'>
                            <div className="mt-1">
                                <div className="form-group mb-1">
                                    <label className="font-weight-bolder">Especificar*</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg"
                                        value={form.otherSpecialty || ''}
                                        onChange={handleInputChange('otherSpecialty')}
                                        placeholder="Ingresa el nombre de la especialidad"
                                        maxLength={200}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {/* Row for country, email and rfc */}
                <div className='row mt-1'>
                    <div className='col-sm-12 col-lg-4'>
                        <div className="mt-1">
                            <div className="form-group mb-1">
                                <label className="font-weight-bolder">País *</label>
                                <select
                                    className="form-control form-control-lg"
                                    value={country}
                                    onChange={(e) => handleSetCountry(e.target.value)}
                                >
                                    <option
                                        className='font-bold'
                                        key={0}
                                        value=""
                                    >
                                        Seleccionar
                                    </option>
                                    {_.map(countriesOptions, (option) => (
                                        <option
                                            className='font-bold'
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-4'>
                        <div className="mt-1">
                            <div className="form-group mb-1">
                                <label className="font-weight-bolder">Correo electrónico *</label>
                                <input
                                    type="email"
                                    className="form-control form-control-lg"
                                    value={form.email || ''}
                                    // onChange={handleInputChange('email')}
                                    placeholder="Ingresa el correo electrónico"
                                    maxLength={200}
                                    onBlur={(e) => validateEmail(e.target.value)}
                                    onChange={(e) => handleFieldChange(e.target.value, 'email')}
                                />
                                {
                                    showEmailError &&
                                    <div className='text-danger'>
                                        El correo electrónico no es válido
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-4'>
                        <div className="mt-1">
                            <div className="form-group mb-1">
                                <label className="font-weight-bolder">
                                    {
                                        isMexican &&
                                        'RFC *'
                                    }
                                    {
                                        !isMexican &&
                                        'Identificador Fiscal Extranjero *'
                                    }
                                </label>
                                {
                                    isMexican &&
                                    <input
                                        type="text"
                                        className="form-control form-control-lg"
                                        value={form.rfc || ''}
                                        placeholder={isMexican ? 'Ingresa el RFC' : 'Ingresa el Identificador Fiscal Extranjero'}
                                        maxLength={15}
                                        onChange={(e) => handleFieldChange(e.target.value, 'rfc')}
                                    />
                                }
                                {
                                    !isMexican &&
                                    <textarea
                                        type="text"
                                        className="form-control form-control-lg"
                                        value={form.rfc || ''}
                                        placeholder="Ingresa el Identificador Fiscal Extranjero"
                                        onChange={(e) => handleFieldChange(e.target.value, 'rfc')}
                                        rows={4}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* Row for zipCode and fiscal regime */}
                <div className='row mt-1'>
                    <div className='col-sm-12 col-lg-4'>
                        <div className="mt-1">
                            <div className="form-group mb-1">
                                <label className="font-weight-bolder">Código postal *</label>
                                <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    value={form.zipCode || ''}
                                    // onChange={handleInputChange('zipCode')}
                                    placeholder="Ingresa el código postal"
                                    maxLength={5}
                                    // onKeyUp={(e) => handleNumericInput(e.target.value, 'zipCode')}
                                    onChange={(e) => handleFieldChange(e.target.value, 'zipCode')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-8'>
                        {
                            isMexican &&
                            <div className="mt-1">
                                <div className="form-group mb-1">
                                    <label className="font-weight-bolder">Régimen fiscal *</label>
                                    <select
                                        className="form-control form-control-lg"
                                        value={fiscalRegime}
                                        onChange={(e) => setFiscalRegime(e.target.value)}
                                    >
                                        <option
                                            className='font-bold'
                                            key={0}
                                            value=""
                                        >
                                            Seleccionar
                                        </option>
                                        {_.map(regimeFiscalOptions, (option) => (
                                            <option
                                                className='font-bold'
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        }

                        {
                            !isMexican &&
                            <div className="mt-1">
                                <div className="form-group mb-1">
                                    <label className="font-weight-bolder">Régimen fiscal extranjero *</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg"
                                        value={form.fiscalRegimeForeigner || ''}
                                        placeholder="Ingresa el regimen fiscal extranjero"
                                        maxLength={200}
                                        onChange={handleInputChange('fiscalRegimeForeigner')}
                                    />
                                </div>
                            </div>
                        }

                    </div>
                </div>
                {/* Row for socialReason and social reason RFC */}
                <div className='row mt-1'>
                    <div className='col-sm-12 col-lg-6'>
                        <div className="mt-1">
                            <div className="form-group mb-1">
                                <label className="font-weight-bolder">
                                    Razón social (<span className='text-danger'>en caso de no ser la misma</span>)
                                </label>
                                <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    value={form.socialReason || ''}
                                    // onChange={handleInputChange('socialReason')}
                                    placeholder="Ingresa la razón social"
                                    maxLength={200}
                                    onChange={(e) => handleFieldChange(e.target.value, 'socialReason')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6'>
                        <div className="mt-1">
                            <div className="form-group mb-1">
                                <label className="font-weight-bolder">
                                    RFC
                                </label>
                                <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    value={form.socialReasonRfc || ''}
                                    // onChange={handleInputChange('socialReasonRfc')}
                                    placeholder="Ingresa el RFC"
                                    maxLength={15}
                                    onChange={(e) => handleFieldChange(e.target.value, 'socialReasonRfc')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Row for zipCode and fiscal regime for Social Reason*/}
                <div className='row mt-1'>
                    <div className='col-sm-12 col-lg-4'>
                        <div className="mt-1">
                            <div className="form-group mb-1">
                                <label className="font-weight-bolder">Código postal</label>
                                <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    value={form.zipCodeSocialReason || ''}
                                    // onChange={handleInputChange('zipCode')}
                                    placeholder="Ingresa el código postal"
                                    maxLength={5}
                                    // onKeyUp={(e) => handleNumericInput(e.target.value, 'zipCode')}
                                    onChange={(e) => handleFieldChange(e.target.value, 'zipCodeSocialReason')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-8'>
                        {
                            isMexican &&
                            <div className="mt-1">
                                <div className="form-group mb-1">
                                    <label className="font-weight-bolder">Régimen fiscal</label>
                                    <select
                                        className="form-control form-control-lg"
                                        value={fiscalRegimeSocialReason}
                                        onChange={(e) => setFiscalRegimeSocialReason(e.target.value)}
                                    >
                                        <option
                                            className='font-bold'
                                            key={0}
                                            value=""
                                        >
                                            Seleccionar
                                        </option>
                                        {_.map(regimeFiscalOptions, (option) => (
                                            <option
                                                className='font-bold'
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        }

                        {
                            !isMexican &&
                            <div className="mt-1">
                                <div className="form-group mb-1">
                                    <label className="font-weight-bolder">Régimen fiscal extranjero</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg"
                                        value={form.fiscalRegimeForeignerSocialReason || ''}
                                        placeholder="Ingresa el regimen fiscal extranjero"
                                        maxLength={200}
                                        onChange={handleInputChange('fiscalRegimeForeignerSocialReason')}
                                    />
                                </div>
                            </div>
                        }

                    </div>
                </div>
                <div className='row mt-1'>
                    <div className='col-12'>
                        <div className="mt-1">
                            <div className="form-group mb-1">
                                <label className="font-weight-bolder">Comentarios</label>
                                <textarea
                                    type="text"
                                    className="form-control form-control-lg"
                                    value={form.fiscalAddress || ''}
                                    placeholder="Ingresa los comentarios"
                                    onChange={handleInputChange('fiscalAddress')}
                                    rows={4}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Header for payments and add new payment button */}
                <div className='row mt-2'>
                    <div className='col mt-1'>
                        <h3>Pagos *</h3>
                    </div>
                </div>

                <PaymentFormGeneral
                    editPaymentRegistry={editPaymentRegistry}
                    key={paymentsKey}
                    api={api}
                    onPaymentValid={onPaymentValid}
                ></PaymentFormGeneral>

                {/* Row for save button */}
                <div className='row mt-1'>
                    <div className='col mt-1'>
                        <button
                            type="submit"
                            disabled={isLoading}
                            onClick={handleSavePaymentsRegistry}
                            className="btn btn-primary font-weight-bolder mr-2 px-8"
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
            {
                showIframePdf &&
                <Modal
                    show={showIframePdf} onHide={() => setShowIframePdf(false)}
                >
                    <div
                        className="d-flex p-2 cursor-pointer icon-container-pdf"
                        onClick={() => setShowIframePdf(false)}
                    >
                        <FontAwesomeIcon icon={faTimes} className='custom-close-icon pink-color'/>
                    </div>
                    <iframe
                        src={`${pdfUrlPayment}#view=fitH`}
                        title="paymentPdf"
                        height="900"
                        width="700"
                    />
                </Modal>
            }
            {
                showIframe &&
                <Modal
                    show={showIframe} onHide={() => setShowIframe(false)}
                >
                    <div
                        className="d-flex p-2 cursor-pointer icon-container"
                        onClick={() => setShowIframe(false)}
                    >
                        <FontAwesomeIcon icon={faTimes} className='custom-close-icon pink-color'/>
                    </div>
                    <iframe
                        src={`${pdfUrl}#view=fitH`}
                        title="tagPdf"
                        height="500"
                        width="500"
                    />
                </Modal>
            }
            {/* REF for page bottom */}
            <div ref={bottomPageRef}/>
        </div>
    )
}

export default RegisterForm;
