import React, {useState, useCallback, useContext} from 'react'
import useBoolean from '../../../hooks/useBoolean';
import { ApiContext } from '../../../services/api/api-config';
import { getNotifier } from '../../../services/notifier';
import AnnualitysForm from './components/AnnualitysForm/AnnualitysForm';
import AnnualitysAdminHeader from './components/AnnualitysAdminHeader/AnnualitysAdminHeader';
import AnnualitysTable from './components/AnnualitysTable/AnnualitysTable'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import "./Annualitys.scss"

export default function Annualitys() {

  const [edit, setEdit] = useState();
  const [addingAnnuality, startAddingAnnuality, stopAddingAnnuality] = useBoolean();
  const [filters, setFilters] = useState({'order[id]':'DESC'});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);

  const api = useContext(ApiContext);

  // Handle close form modal
  const handleCloseForm = useCallback(()=>{
		setEdit(null);
		stopAddingAnnuality();
	},[stopAddingAnnuality]);

  /* Delete event :: BEGIN */
  const deleteAnnuality = useCallback(() => {
    api.annualities.delete({id: idToDelete})
    .then(() => {
      setShowDeleteConfirm(false);
    })
    .catch((err) => {
      getNotifier().error(err.detail);
    });
  }, [api.annualities, idToDelete]);
  /* Delete event :: END */

  const onDeleteAnnuality = useCallback((annuality) => {
    setShowDeleteConfirm(true);
    setIdToDelete(annuality.id);
  }, []);

  return (
    <div className='Annualitys'>
      <div className="d-flex flex-row">
          {/* {
            annualityId > 0 &&
            <AnnualitysRegisterModal
              closeModal={() => setAnnualityId(0)}
              annualityId={annualityId}
            />
          } */}
          {
            !!(edit || addingAnnuality) &&
            <div className="flex-column offcanvas-mobile mx-auto" id="kt_profile_aside">
              <AnnualitysForm
                edit={edit}
                closeModal={handleCloseForm}
              />
            </div>
          }
          <div className="flex-row-fluid ml-lg-8">
            <div className="card events-index card-custom card-stretch gutter-b">
              <div className="card-body">
                <div className="mb-11">
                  <AnnualitysAdminHeader
                    onNewAnnualityClick={startAddingAnnuality}
                    filters = {filters}
                    onFiltersChange = {setFilters}
                  />
                  <AnnualitysTable
                    onAnnualityEdit = {setEdit}
                    deleteAnnuality = {onDeleteAnnuality}
                    filters = {filters}
                  />
                </div>
              </div>
            </div>
            {
              showDeleteConfirm &&
              <div className="flex-column offcanvas-mobile mx-auto SpeakerModal" id="kt_profile_aside">
                <div className="card card-custom gutter-b">
                  <div className="card-header border-0 pt-2">
                      <h3 className="card-title align-items-start flex-column mb-3">
                        <span className="card-label font-size-h3 font-weight-bolder text-dark">
                          Confirmar eliminar Anualidad
                        </span>
                      </h3>
                    <div
                      className="close-container d-flex justify-content-end pt-2 cursor-pointer"
                      onClick={() => setShowDeleteConfirm(false)}
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        className='close-icon pink-color'
                      />
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <h3>
                      ¿Está seguro de eliminar la anualidad?
                    </h3>
                    <div>
                    <button
                      type="button"
                      onClick={deleteAnnuality}
                      className="btn btn-primary font-weight-bolder mr-2 px-8"
                    >
                      Confirmar
                    </button>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
    </div>
  )
}
