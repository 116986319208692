import React, {useMemo} from 'react'
import useTideTable from "../../../../../hooks/useTideTable";
import TideReactTable from "../../../../../components/TideReactTable/TideReactTable";
import { getPaymentsTableColumns } from '../../PaymentsIndexUtils';
import {PaymentsRegistrySGroups} from "../../../Persons/PersonsIndexUtils";

export default function PaymentsTable(
  { onDetailsClick, onPdfClick, onRemoveClick, filters }
) {

  const paymentsTableColumns = useMemo(() => getPaymentsTableColumns(
    { onDetailsClick, onPdfClick, onRemoveClick }
  ), [onDetailsClick, onPdfClick, onRemoveClick]);

   /**
   * Set filters for payment
   */
    const paymentFilters = useMemo(()=>({
      sGroups: PaymentsRegistrySGroups,
      ...filters
    }),[filters]);

  const paymentsTable = useTideTable({
    entity: 'paymentsRegistries',
    columns: paymentsTableColumns,
    requestFilters: paymentFilters
  })

  return (
    <div
      className="PaymentsTableView card card-custom card-shadowless product-container"
    >
      <div className="card-body p-5">
        <TideReactTable {...paymentsTable.tableProps}/>
      </div>
    </div>
  )
}
