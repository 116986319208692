import React, {useState, useContext, useCallback, useEffect} from 'react';
import useBoolean from '../../../hooks/useBoolean';
import {ApiContext} from '../../../services/api/api-config';
import {getNotifier} from '../../../services/notifier';
import EventsTable from './Components/EventsTableView/EventsTable'
import EventForm from './Components/EventForm/EventForm';
import EventsAdminHeader from './Components/EventsAdminHeader/EventsAdminHeader';
import {getEventPhotoUrl} from '../../../services/modelUtils/eventUtils';
import "./EventsIndex.scss";
import EventRegistersModal from './Components/EventRegistersModal/EventRegistersModal';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {useParams} from 'react-router-dom';
import CongressAdminHeader from './Components/EventsAdminHeader/CongressAdminHeader';

export default function EventsIndex() {
    let {type} = useParams();

    const [edit, setEdit] = useState();
    const [first, setFirst] = useState(true)
    const [eventId, setEventId] = useState(0)
    const [addingEvent, startAddingEvent, stopAddingEvent] = useBoolean();
    const [filters, setFilters] = useState({'order[date]': 'DESC'});
    const [filtersCongress, setFiltersCongress] = useState({'order[date]':'DESC'});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);
    const [theType, setTheType] = useState('')

    const api = useContext(ApiContext);

    // Handle close form modal
    const handleCloseForm = useCallback(() => {
        setEdit(null);
        stopAddingEvent();
    }, [stopAddingEvent]);

    /* Delete event :: BEGIN */
    const deleteEvent = useCallback(() => {
        api.events.delete({id: idToDelete})
            .then(() => {
                setShowDeleteConfirm(false);
            })
            .catch((err) => {
                getNotifier().error(err.detail);
            });
    }, [api.events, idToDelete]);
    /* Delete event :: END */

    const onDeleteEvent = useCallback((event) => {
        setShowDeleteConfirm(true);
        setIdToDelete(event.id);
    }, []);


    const showRegisters = useCallback((eventId) => {
        setEventId(eventId);
    }, []);

    useEffect(() => {
        if (first || type !== theType) {
            setFirst(false);
            setTheType(type);
            if (type === 'events') {
                setFilters({...filters, isCongress: false});
            } else {
                setFiltersCongress({...filtersCongress, isCongress: true});
            }
        }
    }, [filters, filtersCongress,first, theType, type])

    return (
        <>
            <div className='EventsIndex'>
                <div className="d-flex flex-row">
                    {
                        eventId > 0 &&
                        <EventRegistersModal
                            closeModal={() => setEventId(0)}
                            eventId={eventId}
                        />
                    }
                    {
                        !!(edit || addingEvent) &&
                        <div className="flex-column offcanvas-mobile mx-auto" id="kt_profile_aside">
                            <EventForm
                                edit={edit}
                                closeModal={handleCloseForm}
                                returnPhotoUrl={getEventPhotoUrl}
                                type={type}
                            />
                        </div>
                    }
                    <div className="flex-row-fluid ml-lg-8">
                        <div className="card events-index card-custom card-stretch gutter-b">
                            <div className="card-body">
                                <div className="mb-11">
                                    {
                    type === 'events' &&<EventsAdminHeader
                                        onNewEventClick={startAddingEvent}
                                        filters={filters}
                                        onFiltersChange={setFilters}
                                        type={type}
                                    />
                                    }
                  {
                    type !== 'events' &&
                    <CongressAdminHeader
                      onNewEventClick={startAddingEvent}
                      filters = {filtersCongress}
                      onFiltersChange = {setFiltersCongress}
                      type={type}
                    />
                  }<EventsTable
                                        onEventEdit={setEdit}
                                        deleteEvent={onDeleteEvent}
                                        showRegisters={showRegisters}
                                        filters={type === 'events' ? filters : filtersCongress}
                                        type={type}
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            showDeleteConfirm &&
                            <div className="flex-column offcanvas-mobile mx-auto SpeakerModal" id="kt_profile_aside">
                                <div className="card card-custom gutter-b">
                                    <div className="card-header border-0 pt-2">
                                        <h3 className="card-title align-items-start flex-column mb-3">
                        <span className="card-label font-size-h3 font-weight-bolder text-dark">
                          Confirmar eliminar {type === 'events' ? 'evento' : 'congreso'}
                        </span>
                                        </h3>
                                        <div
                                            className="close-container d-flex justify-content-end pt-2 cursor-pointer"
                                            onClick={() => setShowDeleteConfirm(false)}
                                        >
                                            <FontAwesomeIcon
                                                icon={faTimes}
                                                className='close-icon pink-color'
                                            />
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <h3>
                                            ¿Está seguro de eliminar el {type === 'events' ? 'evento' : 'congreso'}?
                                        </h3>
                                        <div>
                                            <button
                                                type="button"
                                                onClick={deleteEvent}
                                                className="btn btn-primary font-weight-bolder mr-2 px-8"
                                            >
                                                Confirmar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
