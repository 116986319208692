import React, {useContext, useEffect, useState, useCallback} from 'react'
import { ApiContext } from '../../../../../services/api/api-config';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import { getNotifier } from '../../../../../services/notifier';
import "./PdfModal.scss"

export default function PdfModal({handleCloseModal, id}) {
  const api = useContext(ApiContext);
  const [pdfUrl, setPdfUrl] = useState('')
  const [first, setFirst] = useState(true)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  useEffect(() => {
    if (first) {
      setFirst(false);
      api.payments_registry.getPdfUrl({id}).then(response => {
        setPdfUrl(response);
      }).catch(
        error => JSON.stringify(error)
      );
    }
  }, [api.payments_registry, first, id])

  const handleSendByEmail = useCallback(() => {
    setButtonDisabled(true)
    api.payments_registry.sendPdf({id}).then(() => {
      setButtonDisabled(false)
      return getNotifier.success('Enviado correctamente');
    }).catch(
      error => JSON.stringify(error)
    );
  }, [api.payments_registry, id, setButtonDisabled]);
  

  return (
    <div className='PdfModal'>
      <div className="card card-custom gutter-b">
        <div className="card-header border-0 pt-2 d-flex justify-content-between">
          <h3 className="card-title mb-1">
            <span className="card-label font-size-h3 font-weight-bolder text-dark">
              Información del registro
            </span>
          </h3>
          <div
            className="close-container d-flex pt-2 cursor-pointer"
            onClick={handleCloseModal}
          >
            <FontAwesomeIcon icon={faTimes} className='close-icon pink-color'/>
          </div>
        </div>
        <div className="card-body pt-0">
          {
            pdfUrl !== '' &&
            <>
              <iframe
                src={`${pdfUrl}#view=fitH`}
                title="testPdf"
                height="90%"
                width="100%"
              />
              <div>
                {
                  !buttonDisabled &&
                  <button
                    type="button"
                    disabled={buttonDisabled}
                    onClick={handleSendByEmail}
                    className="btn btn-primary font-weight-bolder mr-2 px-8"
                  >
                    Enviar por correo
                  </button>
                }
              </div>
            </>
          }
        </div>
      </div>
    </div>
  )
}
