// import SuperDashboard from "../../scenes/Logged/SuperDashboard/SuperDashboard";
import UsersAdmin from "../../scenes/Logged/UsersAdmin/UsersAdmin";

import {
    faUsers,
    faUserTie,
    faCalendarDay,
    faUser,
    faDollarSign,
    faUserClock,
    faUserMd,
    faUserCheck,
    faUserCircle,
    faMoneyBill,
    faCogs,
    faCalendarCheck,
    faIdCard
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SponsorsIndex from "../../scenes/Logged/Sponsors/SponsorsIndex";
import EventsIndex from "../../scenes/Logged/Events/EventsIndex";
import RegistersIndex from "../../scenes/Logged/Registers/RegistersIndex";
import PersonsIndex from "../../scenes/Logged/Persons/PersonsIndex";
import PaymentsIndex from "../../scenes/Logged/Payments/PaymentsIndex";
import SpeakersIndex from "../../scenes/Logged/Speakers/SpeakersIndex";
import CoordinatorsIndex from "../../scenes/Logged/Coordinators/CoordinatorsIndex";
import Scholarships from "../../scenes/Logged/Scholarships/Scholarships";
import Annualitys from '../../scenes/Logged/Annualitys/Annualitys';
import PrintTags from "../../scenes/Logged/PrintTags/PrintTags";

export const paths = {
    adminDashboard: '/admin',
    adminUsers: '/users',
    sponsors: '/sponsors',
    events: '/events/:type',
    registers: '/registers',
    persons: '/doctors',
    payments: '/payments',
    speakers: '/speakers',
    coordinators: '/coordinators',
    scholarships: '/scholarships',
    scholarshipsEdit: '/scholarships-new/:id',
    annualitys: '/annualitys',
    congress: '/events/:type',
    printTag: '/print-tag'
};

export const AddRegisterRoute = {
    path: paths.registers,
    name: 'Nuevo Registro',
    component: RegistersIndex,
    icon: <FontAwesomeIcon icon={faUser} className='dashboard-logo icon-dash-logo text-danger'/>,
    activeIcon: <FontAwesomeIcon icon={faUser} className='active-icon icon-dash-logo text-active-icon'/>
}
const getAppRoutes = (securityManager) => {
    let routes = [];
    if (securityManager.role === 'SUPER_ADMIN' || securityManager.role === 'ROLE_GENERAL_ADMIN') {
        routes = [
            {
                path: paths.printTag,
                name: 'Imprimir etiquetas',
                component: PrintTags,
                icon: <FontAwesomeIcon icon={faIdCard} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faIdCard} className='active-icon icon-dash-logo text-active-icon'/>
            },
            AddRegisterRoute,
            {
                path: paths.payments,
                name: 'Pagos',
                component: PaymentsIndex,
                icon: <FontAwesomeIcon icon={faDollarSign} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faDollarSign}
                                             className='active-icon icon-dash-logo text-active-icon'/>
            },
            {
                path: paths.persons,
                name: 'Doctores',
                component: PersonsIndex,
                icon: <FontAwesomeIcon icon={faUserMd} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faUserMd} className='active-icon icon-dash-logo text-active-icon'/>
            },
            {
                path: paths.scholarships,
                name: 'Becados',
                component: Scholarships,
                icon: <FontAwesomeIcon icon={faUserCheck} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faUserCheck}
                                             className='active-icon icon-dash-logo text-active-icon'/>
            },
            {
                path: paths.speakers,
                name: 'Ponentes',
                component: SpeakersIndex,
                icon: <FontAwesomeIcon icon={faUserTie} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faUserTie} className='active-icon icon-dash-logo text-active-icon'/>
            },
            {
                path: paths.coordinators,
                name: 'Coordinadores',
                component: CoordinatorsIndex,
                icon: <FontAwesomeIcon icon={faUserClock} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faUserClock}
                                             className='active-icon icon-dash-logo text-active-icon'/>
            },
            {
                path: '#',
                name: 'Configuración',
                component: null,
                icon: <FontAwesomeIcon icon={faCogs} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faCogs} className='active-icon icon-dash-logo text-active-icon'/>,
                hasSubmenu: true,
                submenus: [
                    {
                        path: paths.adminUsers,
                        name: 'Usuarios',
                        component: UsersAdmin,
                        icon: <FontAwesomeIcon icon={faUsers} className='dashboard-logo icon-dash-logo text-danger'/>,
                        activeIcon: <FontAwesomeIcon icon={faUsers}
                                                     className='active-icon icon-dash-logo text-active-icon'/>
                    },
                    {
                        path: paths.sponsors,
                        name: 'Patrocinadores',
                        component: SponsorsIndex,
                        icon: <FontAwesomeIcon icon={faUserCircle}
                                               className='dashboard-logo icon-dash-logo text-danger'/>,
                        activeIcon: <FontAwesomeIcon icon={faUserCircle}
                                                     className='active-icon icon-dash-logo text-active-icon'/>,
                        isSubmenu: true
                    },
                    {
                        path: paths.events.replace(':type', 'events'),
                        name: 'Eventos',
                        component: EventsIndex,
                        icon: <FontAwesomeIcon icon={faCalendarDay}
                                               className='dashboard-logo icon-dash-logo text-danger'/>,
                        activeIcon: <FontAwesomeIcon icon={faCalendarDay}
                                                     className='active-icon icon-dash-logo text-active-icon'/>,
                        isSubmenu: true
                    },
                    {
                        path: paths.congress.replace(':type', 'congress'),
                        name: 'Congresos',
                        component: EventsIndex,
                        icon: <FontAwesomeIcon icon={faCalendarCheck}
                                               className='dashboard-logo icon-dash-logo text-danger'/>,
                        activeIcon: <FontAwesomeIcon icon={faCalendarCheck}
                                                     className='active-icon icon-dash-logo text-active-icon'/>,
                        isSubmenu: true
                    },
                    {
                        path: paths.annualitys,
                        name: 'Anualidades',
                        component: Annualitys,
                        icon: <FontAwesomeIcon icon={faMoneyBill}
                                               className='dashboard-logo icon-dash-logo text-danger'/>,
                        activeIcon: <FontAwesomeIcon icon={faMoneyBill}
                                                     className='active-icon icon-dash-logo text-active-icon'/>,
                        isSubmenu: true
                    }
                ]
            },
            {
                path: paths.adminUsers,
                name: 'Usuarios',
                component: UsersAdmin,
                icon: <FontAwesomeIcon icon={faUsers} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faUsers} className='active-icon icon-dash-logo text-active-icon'/>,
                hidden: true
            },
            {
                path: paths.sponsors,
                name: 'Patrocinadores',
                component: SponsorsIndex,
                icon: <FontAwesomeIcon icon={faUserCircle} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faUserCircle}
                                             className='active-icon icon-dash-logo text-active-icon'/>,
                hidden: true
            },
            {
                path: paths.events,
                name: 'Eventos',
                component: EventsIndex,
                icon: <FontAwesomeIcon icon={faCalendarDay} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faCalendarDay}
                                             className='active-icon icon-dash-logo text-active-icon'/>,
                hidden: true
            },
            {
                path: paths.scholarshipsEdit,
                name: 'Becados',
                component: Scholarships,
                icon: <FontAwesomeIcon icon={faUserCheck} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faUserCheck}
                                             className='active-icon icon-dash-logo text-active-icon'/>,
                hidden: true
            },
            {
                path: paths.annualitys,
                name: 'Anualidades',
                component: Annualitys,
                icon: <FontAwesomeIcon icon={faMoneyBill} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faMoneyBill}
                                             className='active-icon icon-dash-logo text-active-icon'/>,
                hidden: true
            },
        ];
    }
    if (securityManager.role === 'ROLE_GENERAL_SUPERVISOR') {
        routes = [
            {
                path: paths.printTag,
                name: 'Imprimir etiquetas',
                component: PrintTags,
                icon: <FontAwesomeIcon icon={faIdCard} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faIdCard} className='active-icon icon-dash-logo text-active-icon'/>
            },
            AddRegisterRoute,
            {
                path: paths.payments,
                name: 'Pagos',
                component: PaymentsIndex,
                icon: <FontAwesomeIcon icon={faDollarSign} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faDollarSign}
                                             className='active-icon icon-dash-logo text-active-icon'/>
            },
            {
                path: paths.persons,
                name: 'Doctores',
                component: PersonsIndex,
                icon: <FontAwesomeIcon icon={faUserMd} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faUserMd} className='active-icon icon-dash-logo text-active-icon'/>
            },
            {
                path: paths.speakers,
                name: 'Ponentes',
                component: SpeakersIndex,
                icon: <FontAwesomeIcon icon={faUserTie} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faUserTie} className='active-icon icon-dash-logo text-active-icon'/>
            },
            {
                path: paths.sponsors,
                name: 'Patrocinadores',
                component: SponsorsIndex,
                icon: <FontAwesomeIcon icon={faUserCircle} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faUserCircle}
                                             className='active-icon icon-dash-logo text-active-icon'/>
            }
        ];
    }
    if (securityManager.role === 'ROLE_GENERAL_USER') {
        routes = [
            {
                path: paths.printTag,
                name: 'Imprimir etiquetas',
                component: PrintTags,
                icon: <FontAwesomeIcon icon={faIdCard} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faIdCard} className='active-icon icon-dash-logo text-active-icon'/>
            },
            AddRegisterRoute,
            {
                path: paths.payments,
                name: 'Pagos',
                component: PaymentsIndex,
                icon: <FontAwesomeIcon icon={faDollarSign} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faDollarSign}
                                             className='active-icon icon-dash-logo text-active-icon'/>
            },
            {
                path: paths.persons,
                name: 'Doctores',
                component: PersonsIndex,
                icon: <FontAwesomeIcon icon={faUserMd} className='dashboard-logo icon-dash-logo text-danger'/>,
                activeIcon: <FontAwesomeIcon icon={faUserMd} className='active-icon icon-dash-logo text-active-icon'/>
            }
        ];
    }
    return routes;
};


export default getAppRoutes;
