import React, {useCallback, useState, useContext} from 'react'
import { ApiContext } from '../../services/api/api-config';
import { getNotifier } from '../../services/notifier';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faFileDownload} from '@fortawesome/free-solid-svg-icons';
import excelFormatSpeaker from "../../assets/ponentes_formato.xlsx"
import excelFormatScholarship from "../../assets/becados_formato.xlsx"
import excelFormatCoordinator from "../../assets/coordinadores_formato.xlsx"
import excelFormatDoctor from "../../assets/doctores_formato.xlsx"
import _ from 'lodash';
import "./ImportPerson.scss"

export default function ImportPerson({type, onSuccess}) {

  const [fileSelected, setFileSelected] = useState(null);
  const [errors, setErrors] = useState([])
  const [closeModal, setCloseModal] = useState(false)

  const api = useContext(ApiContext);

  const handleFile = useCallback((e) => {
    const files = e.target.files;
    if (files.length > 0) {
      setFileSelected(files[0]);
    }
  }, []);

  const handleImport = useCallback(() => {
    if (!fileSelected) {
      return getNotifier().error('No ha seleccionado el archivo');
    }
    setCloseModal(false);
    api.people.importExcelPersons({files: fileSelected, params: {type}})
      .then(response => {
        if (response.errors && response.errors.length > -1) {
          setErrors(response.errors);
          onSuccess();
        } else {
          getNotifier().success('Importación realizada correctamente');
          setErrors([]);
          onSuccess();
        }
      }).catch(error => {
        getNotifier().error(error.detail);
        onSuccess();
      });
  }, [api.people, fileSelected, onSuccess, type]);

  const downloadFile = useCallback(() => {
    if (type === 'BECADO') {
      window.location.href = excelFormatScholarship;
    }
    if (type === 'PONENTE') {
      window.location.href = excelFormatSpeaker;
    }
    if (type === 'COORDINADOR') {
      window.location.href = excelFormatCoordinator;
    }
    if (type === 'DOCTOR') {
      window.location.href = excelFormatDoctor;
    }
  }, [type]);

  return (
    <div className='ImportPerson'>
      <div className='import-form'>
        <div className='d-flex justify-content-between'>
          <div className='import-label'>
            Importar
          </div>
          <div
            onClick={downloadFile}
            className='cursor-pointer'
          >
            <FontAwesomeIcon
              icon={faFileDownload}
            /> Descargar formato
          </div>
        </div>
        
        <input
          type="file"
          onChange={handleFile}
          accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <div className='d-flex justify-content-between'>
          <button
            className='btn btn-info mt-2'
            onClick={handleImport}
          >
            Importar
          </button>
          {
            errors.length > 0 &&
            <button
              className='btn btn-warning mt-2'
              onClick={() => setCloseModal(false)}
            >
              Mostrar Errores
            </button>
          }
        </div>
      </div>
      {
        errors.length > 0  && !closeModal && 
        <div className='modal-container'>
          <div className="card card-custom gutter-b">
            <div className="card-header border-0 pt-2">
                <h3 className="card-title align-items-start flex-column mb-3">
                  <span className="card-label font-size-h3 font-weight-bolder text-dark">
                    Errores de importación
                  </span>
                </h3>
              <div
                className="close-container d-flex justify-content-end pt-2 cursor-pointer"
                onClick={() => setCloseModal(true)}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  className='close-icon pink-color'
                />
              </div>
            </div>
            <div className="card-body pt-0">
              <table className='table'>
                <thead>
                  <tr>
                    <th>Fila</th>
                    <th>Error</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    _.map(errors, (theError, index) => (
                      <tr key={index}>
                        <td>
                          {theError.row}
                        </td>
                        <td>
                          <ul>
                            {
                              _.map(theError.errors, (theErrorDetail, index) => (
                                <li key={index}>
                                  {theErrorDetail}
                                </li>
                              ))
                            }
                          </ul>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      }
    </div>
  )
}
