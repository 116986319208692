import React, {useCallback, useEffect, useState} from 'react'
import RegisterForm from './components/RegisterForm/RegisterForm'
import {getNotifier} from '../../../services/notifier';
import "./RegistersIndex.scss";
import RegisterSearch from './components/RegisterSearch/RegisterSearch';
import {useLocation} from 'react-router-dom';

export default function RegistersIndex() {
    const [showSearch, setShowSearch] = useState(true);
    const [person, setPerson] = useState(null);
    const [editPaymentRegistryId, setEditPaymentRegistryId] = useState(null);
    const [registryKey, setRegistryKey] = useState(1);


    const location = useLocation();

    useEffect(() => {
        setShowSearch(true);
        setPerson(null);
        setEditPaymentRegistryId(null);
    }, [location, setShowSearch]);  // notice that location is now a dependency

    /**
     * Handle the click for person selected
     */
    const handlePersonSelected = useCallback((person) => {
        setPerson(person);
        setEditPaymentRegistryId(null);
        setShowSearch(false);
    }, []);


    const handleEditPaymentSelected = useCallback( (person, paymentId) => {
        setPerson(person);
        setEditPaymentRegistryId(paymentId)
        setShowSearch(false);
    }, []);

    // Handle close form
    const handleCloseForm = useCallback(() => {
        setPerson(null);
        setEditPaymentRegistryId(null);
        getNotifier().success(`El registro se ha creado correctamente`);
    }, []);

    /**
     * Handle close modal for search person
     */
    const handleCloseSearchModal = useCallback(() => {
        setShowSearch(false);
        setRegistryKey(registryKey + 1)
    }, [registryKey]);

    return (
        <>
            <div className='RegistersIndex'>
                <div className="d-flex flex-row">
                    <div className="flex-row-fluid">
                        <div className="card sponsors-index card-custom card-stretch gutter-b">
                            <div className="card-body">
                                <div className="mb-11">
                                    {
                                        showSearch &&
                                        <RegisterSearch
                                            handleCloseSearchModal={handleCloseSearchModal}
                                            handlePersonSelected={handlePersonSelected}
                                            handleEditPaymentSelected={handleEditPaymentSelected}
                                        />
                                    }
                                    <RegisterForm
                                        key={registryKey}
                                        closeForm={handleCloseForm}
                                        person={person}
                                        editPaymentRegistryId={editPaymentRegistryId}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
