import React, {useContext, useEffect, useCallback, useState} from 'react'
import {ApiContext} from '../../../../../services/api/api-config';
import useFormState from '../../../../../hooks/useFormState';
import {getNotifier} from '../../../../../services/notifier';
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faTrash} from '@fortawesome/free-solid-svg-icons';
import "./SpeakersForm.scss"
import {prepareFormSpeakers, SpeakersSGroups} from '../../../../../services/modelUtils/SpeakerUtils';
import {
    displayTitleOptions,
    displaySpecialtiesOptions
} from '../../../../../services/modelUtils/RegisterUtils';
import _ from "lodash";

export default function SpeakersForm(
    {edit, closeModal}
) {
    const api = useContext(ApiContext);
    const {form, handleInputChange, setForm} = useFormState({});

    const [titleValue, setTitleValue] = useState('');
    const [specialtyValue, setSpecialtyValue] = useState(null);
    const [countriesOptions, setCountriesOptions] = useState([]);
    const [eventOptions, setEventOptions] = useState([]);
    const [country, setCountry] = useState('');
    const [event, setEvent] = useState('');
    const [showEmailError, setShowEmailError] = useState(false);
    const [first, setFirst] = useState(true)
    const [eventsSelected, setEventsSelected] = useState([])

    const getEvents = useCallback(() => {
      api.events.get().then(response => {
        setEventOptions(response);
      } ).catch(
        error => getNotifier().error(error.message)
      );
    }, [api.events]);

    useEffect(() => {
        if (edit) {
            let isOtherTitle = '';
            displayTitleOptions.forEach(title => {
                if (title.value === edit.title) {
                    isOtherTitle = title.value;
                }
            });
            if (isOtherTitle === '') {
                setTitleValue('Otro');
            } else {
                setTitleValue(edit.title);
            }
            let isOtherSpecialty = '';

            if(edit.speciality!==null) {

                displaySpecialtiesOptions.forEach(specialty => {
                    if (specialty.value === edit.speciality) {
                        isOtherSpecialty = specialty.value;
                    }
                });
                if (isOtherSpecialty === '') {
                    setSpecialtyValue('Otro');
                } else {
                    setSpecialtyValue(edit.speciality);
                }
            }
            setForm({
                id: edit.id,
                name: edit.name,
                patLastName: edit.patLastName,
                matLastName: edit.matLastName,
                institution: edit.institution,
                phone: edit.phone,
                email: edit.email,
                otherTitle: isOtherTitle === '' ? edit.title : '',
                otherSpecialty: isOtherSpecialty === '' ? edit.speciality : '',
                sequential: edit.sequential
            });
            setCountry(edit.country?.id);


            const theEvent = _.find(edit.eventAttendances, (ea) => {
                if (!ea.event.isCongress) {
                    return ea.event;
                }
            });


            if(theEvent){
                setEvent(theEvent.event.id);
            }

            let eventAttendances = [];
            if (edit.eventAttendances) {
              edit.eventAttendances.forEach(theEvent => {
                eventAttendances.push(theEvent.event);
              });
              setEventsSelected(eventAttendances);
            }
        }
    }, [edit, setForm]);


    const getMaxSequential = useCallback((data) => {
        api.people.get({
            params: {
                type: 'PONENTE',
                "order[id]": "DESC",
                sGroups: SpeakersSGroups
            }
        }).then(response => {
            console.log('response: ', response);
            let sequential = 'P01'
            if (response.length > 0) {
                const lastRegister = response[0];
                if (lastRegister.sequential) {
                    let currentSequential = parseInt(lastRegister.sequential.substring(1));
                    if (currentSequential < 9) {
                        sequential = 'P0' + (currentSequential + 1);
                    } else {
                        sequential = 'P' + (currentSequential + 1);
                    }
                }
            }
            const apiMethod = edit ? 'update' : 'create';
            api.people[apiMethod]({
                ...(edit && {id: edit.id + ''}),
                loadingId: 'SpeakerForm',
                params: {
                    ...data,
                    sequential: !edit ? sequential : undefined,
                    sGroups: SpeakersSGroups
                },
            }).then(response => {
                closeModal();
            });
        }).catch(
            error => getNotifier().error(error.message)
        );
    }, [api.people, closeModal, edit]);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        let data = null;
        try {
            data = prepareFormSpeakers(
                form,
                specialtyValue,
                country,
                titleValue,
                event,
                eventsSelected
            );
        } catch (e) {
            return getNotifier().error(e.message);
        }
        getMaxSequential(data);
    }, [getMaxSequential, form, specialtyValue, country, titleValue, event, eventsSelected]);

    /**
     * Validate email format
     */
    const validateEmail = useCallback((email) => {
        if (/\S+@\S+\.\S+/.test(email)) {
            setShowEmailError(false);
        } else {
            setShowEmailError('true');
        }
    }, []);

    const addEventSelected = useCallback((event) => {
      const found = eventsSelected.find(theEvent => parseInt(event) === theEvent.id);
      if (!found) {
        let theEvents = [...eventsSelected];
        const theIndex = eventOptions.findIndex(theEvent => parseInt(event) === theEvent.id );
        theEvents.push(eventOptions[theIndex]);
        setEventsSelected(theEvents);
      }
    }, [eventOptions, eventsSelected]);
  
    const removeEvent = useCallback((eventId) => {
      const theIndex = eventsSelected.findIndex(theEvent => eventId === theEvent.id );
      let theEvents = [...eventsSelected];
      theEvents.splice(theIndex, 1);
      if (edit) {
        const found = edit.eventAttendances.find(theEventAttendance => theEventAttendance.event.id === eventId);
        console.log('found in database: ', found);
        if (found) {
          api.eventAttendances.delete({id: found.id});
        }
      }
      setEventsSelected(theEvents);
    }, [api.eventAttendances, edit, eventsSelected]);

    /**
     * Use effect hook, get countries, fiscal regimes, events, payment methods and sponsors
     */
    useEffect(() => {
      if (first) {
        setFirst(false);
        // GET countries list without pagination
        api.countries.get(
          {
              params: {
                  pagination: false
              }
          }
        ).then(countries => {
          setCountriesOptions(countries)
        }).catch((err) => {
          getNotifier().error(err.detail);
        });

        // GET events list without pagination
        api.events.get(
          {
              params: {
                  pagination: false,
                  isCongress: false

              }
          }
        ).then(response => {
          setEventOptions(response);
        }).catch((err) => {
          getNotifier().error(err.detail);
        });

        getEvents();
      }
    }, [api, first, getEvents, setForm]);

    const isLoading = useSelector(s => !!s.loadingIds['SpeakerForm'])

    return (
        <div className='SpeakersForm'>
            <div className="card card-custom gutter-b">
                <div className="card-header border-0 pt-2">
                    <h3 className="card-title align-items-start flex-column mb-1">
              <span className="card-label font-size-h3 font-weight-bolder text-dark">
                {edit ? 'Editar ponente' : 'Añadir nuevo ponente'}
              </span>
                    </h3>
                    <div
                        className="close-container d-flex justify-content-end pt-2 cursor-pointer"
                        onClick={closeModal}
                    >
                        <FontAwesomeIcon icon={faTimes} className='close-icon pink-color'/>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <form>
                        {/* Row for title and name */}
                        <div className='row'>
                            <div className='col-sm-12 col-lg-2'>
                                <div className="mt-1">
                                    <div className="form-group mb-1">
                                        <label className="font-weight-bolder">* Título</label>
                                        <select
                                            className="form-control form-control-lg"
                                            value={titleValue}
                                            onChange={(e) => setTitleValue(e.target.value)}
                                        >
                                            <option
                                                className='font-bold'
                                                key={0}
                                                value=""
                                            >
                                                Seleccionar
                                            </option>
                                            {_.map(displayTitleOptions, (option, index) => (
                                                <option
                                                    className='font-bold'
                                                    key={index}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {
                                titleValue === 'Otro' &&
                                <div className='col-sm-12 col-lg-4'>
                                    <div className="mt-1">
                                        <div className="form-group mb-1">
                                            <label className="font-weight-bolder">Especificar título</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                value={form.otherTitle || ''}
                                                onChange={handleInputChange('otherTitle')}
                                                placeholder="Ingresa el título"
                                                maxLength={200}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='col-sm-12 col-lg-6'>
                                <div className="mt-1">
                                    <div className="form-group mb-1">
                                        <label className="font-weight-bolder">* Nombre(s)</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            value={form.name || ''}
                                            onChange={handleInputChange('name')}
                                            placeholder="Ingresa el nombre(s)"
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Row for patLastName and matLastName */}
                        <div className='row'>
                            <div className='col-sm-12 col-lg-6'>
                                <div className="mt-1">
                                    <div className="form-group mb-1">
                                        <label className="font-weight-bolder">* Apellido paterno</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            value={form.patLastName || ''}
                                            onChange={handleInputChange('patLastName')}
                                            placeholder="Ingresa el apellido paterno"
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-lg-6'>
                                <div className="mt-1">
                                    <div className="form-group mb-1">
                                        <label className="font-weight-bolder">Apellido materno</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            value={form.matLastName || ''}
                                            onChange={handleInputChange('matLastName')}
                                            placeholder="Ingresa el apellido materno"
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Row for phone and institution */}
                        <div className='row mt-1'>
                            <div className='col-sm-12 col-md-4'>
                                <div className="mt-1">
                                    <div className="form-group mb-2">
                                        <label className="font-weight-bolder">Teléfono</label>
                                        <input type="text" className="form-control"
                                               onChange={handleInputChange('phone')} name="billing_zip"
                                               placeholder="Teléfono" value={form.phone || ''}/>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-lg-4'>
                                <div className="mt-1">
                                    <div className="form-group mb-1">
                                        <label className="font-weight-bolder">Especialidad</label>
                                        <select
                                            className="form-control form-control-lg"
                                            value={specialtyValue}
                                            onChange={(e) => setSpecialtyValue(e.target.value)}
                                        >
                                            <option
                                                className='font-bold'
                                                key={0}
                                                value=""
                                            >
                                                Seleccionar
                                            </option>
                                            {_.map(displaySpecialtiesOptions, (option, index) => (
                                                <option
                                                    className='font-bold'
                                                    key={index}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {
                                specialtyValue === 'Otro' &&
                                <div className='col-sm-12 col-lg-4'>
                                    <div className="mt-1">
                                        <div className="form-group mb-1">
                                            <label className="font-weight-bolder">Especificar</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                value={form.otherSpecialty || ''}
                                                onChange={handleInputChange('otherSpecialty')}
                                                placeholder="Ingresa el nombre de la especialidad"
                                                maxLength={200}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {/* Row for country, email and rfc */}
                        <div className='row mt-1'>
                            <div className='col-sm-12 col-lg-6'>
                                <div className="mt-1">
                                    <div className="form-group mb-1">
                                        <label className="font-weight-bolder">* País</label>
                                        <select
                                            className="form-control form-control-lg"
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                        >
                                            <option
                                                className='font-bold'
                                                key={0}
                                                value=""
                                            >
                                                Seleccionar
                                            </option>
                                            {_.map(countriesOptions, (option) => (
                                                <option
                                                    className='font-bold'
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-lg-6'>
                                <div className="mt-1">
                                    <div className="form-group mb-1">
                                        <label className="font-weight-bolder">* Correo electrónico</label>
                                        <input
                                            type="email"
                                            className="form-control form-control-lg"
                                            value={form.email || ''}
                                            onChange={handleInputChange('email')}
                                            placeholder="Ingresa el correo electrónico"
                                            maxLength={200}
                                            onBlur={(e) => validateEmail(e.target.value)}
                                        />
                                        {
                                            showEmailError &&
                                            <div className='text-danger'>
                                                El correo electrónico no es válido
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Eventos */}
                        <div className='row mt-1'>
                          <div className='col-sm-12 col-lg-12'>
                              <div className="mt-1">
                                  <div className="form-group mb-1">
                                      <label className="font-weight-bolder">
                                          Evento
                                      </label>
                                      <select
                                          className="form-control form-control-lg"
                                          value={''}
                                          onChange={(e) => addEventSelected(e.target.value)}
                                      >
                                          <option
                                              className='font-bold'
                                              key={0}
                                              value=""
                                          >
                                              Seleccionar
                                          </option>
                                          {_.map(eventOptions, (event) => (
                                              <option
                                                  className='font-bold'
                                                  key={event.id}
                                                  value={event.id}
                                              >
                                                  {event.name}
                                              </option>
                                          ))}
                                      </select>
                                  </div>
                              </div>
                          </div>
                        </div>
                        {
                          eventsSelected.length > 0 &&
                          <div>
                              <h4>Eventos/Congresos seleccionados</h4>
                              <ul>
                              {
                                  _.map(eventsSelected, (theEvent) => (
                                  <li key={theEvent.id}>
                                      <div className='d-flex'>
                                      <div onClick={() => removeEvent(theEvent.id)}>
                                          <FontAwesomeIcon icon={faTrash} className='mr-2 cursor-pointer'/>
                                      </div>
                                      <div>
                                          {theEvent.name}
                                      </div>
                                      </div>
                                  </li>
                                  ))
                              }
                              </ul>
                          </div>
                        }
                        <div className='mt-2'>
                            <button
                                type="submit"
                                disabled={isLoading}
                                onClick={handleSubmit}
                                className="btn btn-primary font-weight-bolder mr-2 px-8"
                            >
                                Guardar
                            </button>
                            <button
                                type="reset"
                                className="btn btn-clear font-weight-bolder text-muted px-8"
                                onClick={closeModal}
                            >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
