import React, {useCallback, useContext} from 'react';
import './Login.scss';
import {ApiContext} from "../../../services/api/api-config";
import useFormState from "../../../hooks/useFormState";
import {LOGIN_STATE, LOGIN_LOADING_ID} from "tide-api";
import {useSelector} from "react-redux";
import { getNotifier } from '../../../services/notifier';
import logo from '../../../assets/img/logo_green.png'


const Login = () => {
    const api = useContext(ApiContext);


    const {form, handleInputChange} = useFormState({});

    const login = useCallback((e) => {
        e.preventDefault();
        if(!form.email || !form.pass)
            return;
        api.login(form.email, form.pass).catch(() => {
            getNotifier().error('No pudimos validar su cuenta, inténtalo nuevamente.')
        });
    },[api, form]);

    // --- login state message ------
    const loginState = useSelector(({api}) => api.loggedIn);
    const loading = useSelector(({loadingIds}) => loadingIds[LOGIN_LOADING_ID] || loadingIds['Login.me']);

    let loginMsg;
    switch (loginState) {
        case LOGIN_STATE.LOGIN_ERROR:
            loginMsg = 'Lo sentimos, hubo un error al iniciar sesión.';
            break;
        case LOGIN_STATE.BAD_CREDENTIALS:
            loginMsg = 'El usuario y contraseña no coinciden';
            break;
        default:
            loginMsg = null;
            break;
    }

    return (
        <div className='Login'>

            <div className='login-form text-center p-7 position-relative overflow-hidden'>
                <div className='logo'>
                    <img src={logo} alt='logo amcemig' className='img-thumbnail'/>
                </div>
			    <div className="login-signin">
			    	<div className="mb-20">
			    		<h3>Inicia Sesión</h3>
			    		<div className="white-color font-weight-bold mb-5">
                            Ingresa tus datos para iniciar sesión:
                        </div>
			    	</div>
			    	<form
                        className="form"
                        id="kt_login_signin_form"
                        onSubmit={login}
                    >
			    		<div className="form-group mb-5">
			    			<input
                                id='login-user-input'
                                onChange={handleInputChange('email')}
                                value={form.email||''}
                                className="form-control h-auto form-control-solid py-4 px-8"
                                type="text"
                                placeholder="Ingresa tu usuario"
                                name="username"
                                autoComplete="off"
                            />
			    		</div>
			    		<div className="form-group mb-5">
			    			<input
                                className="form-control h-auto form-control-solid py-4 px-8"
                                id='login-pass-input'
                                type='password'
                                onChange={handleInputChange('pass')}
                                value={form.pass||''}
                                placeholder="Ingresa tu contraseña"
                                name="password"
                            />
			    		</div>
			    		<button
                            id="kt_login_signin_submit"
                            type='submit'
                            disabled={loading}
                            className="submit-button btn font-weight-bold px-9 py-4 my-3 mx-4"
                        >
                            Iniciar sesión
                        </button>
                        {
                            loginMsg &&
                            <p className='error'>{loginMsg}</p>
                        }
			    	</form>
			    </div>
            </div>
        </div>
    );
};

export default Login;
