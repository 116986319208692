import React, {useContext, useMemo} from 'react'
import { ApiContext } from '../../../../../services/api/api-config';
import {PaymentsRegistrySGroups, getPeopleTableColumns} from '../../PersonsIndexUtils';
import useTideTable from "../../../../../hooks/useTideTable";
import TideReactTable from "../../../../../components/TideReactTable/TideReactTable";
import "./PersonsTable.scss";

export default function PersonsTable(
  {onPaymentsClick, onDetailsClick, onRemoveClick, filters, onEditPerson}
) {
  const api = useContext(ApiContext);
  
  /**
   * Get columns for persons table
   */
  const personsTableColumns = useMemo(() => getPeopleTableColumns(
    { api, onPaymentsClick, onDetailsClick, onRemoveClick, onEditPerson }
  ), [api, onDetailsClick, onPaymentsClick, onRemoveClick, onEditPerson]);

  /**
   * Set filters for payment registry
   */
  const peopleFilters = useMemo(()=>({
    pagination: true,
    sGroups: PaymentsRegistrySGroups,
    ...filters
  }),[filters]);

  /**
   * Create tide table
   */
  const personsTable = useTideTable({
    entity: 'people',
    columns: personsTableColumns,
    requestFilters: peopleFilters,
  });

  return (
    <div
        className="SponsorsTableView card card-custom card-shadowless product-container"
    >
      <div className="card-body p-5">
        <TideReactTable {...personsTable.tableProps}/>
      </div>
    </div>
  )
}
