import React, {useContext, useEffect, useCallback, useState} from 'react'
import {ApiContext} from '../../../../../services/api/api-config';
import useFormState from '../../../../../hooks/useFormState';
import {getNotifier} from '../../../../../services/notifier';
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import "./EventForm.scss";
import {prepareFormEvent} from '../../EventsIndexUtils';
import _ from "lodash";
import CostsForm from "../../../../../components/CostsForm/CostsForm";

const EventForm = (
    {edit, closeModal, returnPhotoUrl, type}
) => {
    const api = useContext(ApiContext);
    const {form, handleInputChange, setForm} = useFormState({});
    const hiddenFileInput = React.useRef(null);
    const [file, setFile] = useState(null);
    const [imgSrc, setImgSrc] = useState(returnPhotoUrl(edit, api));
    const [first, setFirst] = useState(true)
    const [congresses, setCongresses] = useState([])
    const [congressValue, setCongressValue] = useState('')
    const loadingId = "congresses";
    const [costs, setCosts] = useState([]);


    const handleCostsChange = (newCosts) => {
        setCosts(newCosts);
    };

    const handleAddCost = () => {
        setCosts([...costs, {concept: '', amount: 0}]);
    };

    useEffect(() => {

        // GET sponsors list
        api.events.get({
            params: {
                isCongress: true,
            },
            customProp: loadingId
        }).then(response => {
            setCongresses(response);
        }).catch(
            error => getNotifier().error('Ocurrió un error')
        );

    }, [api, setForm]);


    useEffect(() => {
        if (first && edit) {

            setFirst(false);
            let theDate = '';
            let theHour = '';
            if (edit.date) {
                theDate = edit.date.split('T');
                theHour = theDate[1].split(':');
            }

            setForm({
                name: edit.name,
                date: edit.date
                    ? theDate[0] + 'T' + theHour[0] + ':' + theHour[1]
                    : '',
                place: edit.place,
                isCongress: edit.isCongress,
                isActiveCongress: edit.isActiveCongress,
                amount: edit.amount,
                congress: edit.congress ? edit.congress.id : '',
            });

            if (edit) {
                setCosts(edit.costs);
                setCongressValue(edit.congress ? edit.congress.id : '');
            }

        } else {
            if (first) {
                setFirst(false);
                if (type === 'congress') {
                    setForm({...form, isCongress: true})
                } else {
                    setForm({...form, isCongress: false})
                }
            }
        }
    }, [edit, first, form, setForm, type]);

    useEffect(() => {
        if (edit) {
            console.log(edit);
            setCosts(edit.eventPrices);
        }

    }, [edit])

    const handleMockClickToFile = useCallback(e => {
        hiddenFileInput.current.click();
    }, []);

    const handleSaveEvent = useCallback((data) => {
        const apiMethod = edit ? 'update' : 'create';
        api.events[apiMethod]({
            ...(edit && {id: edit.id + ''}),
            loadingId: 'EventsForm',
            params: {
                ...data
            },
            files: file
        }).then(() => {
            // window.location.reload();
            closeModal();
        });

    }, [api, file, closeModal, edit]);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        let data = null;
        try {
            data = prepareFormEvent(
                form,
                file,
                edit,
                congressValue,
                costs,
                type
            );
        } catch (e) {
            return getNotifier().error(e.message);
        }
        if (data) {
            handleSaveEvent(data);
        }
    }, [form, file, edit, congressValue, costs, type, handleSaveEvent]);

    const handleFileSelection = useCallback(e => {
        if (!e.target?.files?.length)
            return;
        const file = e.target.files[0];
        setImgSrc(URL.createObjectURL(file))
        setFile(file);
    }, [setFile]);

    const handleIsActiveCongressChange = useCallback((isActiveCongress) => {
        let theParams = {...form};
        if (isActiveCongress) {
            theParams.isActiveCongress = true;
        } else {
            theParams.isActiveCongress = false;
        }
        console.log(theParams);
        
        setForm(theParams);
    }, [form, setForm]);

    const isLoading = useSelector(s => !!s.loadingIds['EventsForm'])

    return (
        <div className='EventForm'>
            <div className="card card-custom gutter-b">
                <div className="card-header border-0 pt-2">
                    <h3 className="card-title align-items-start flex-column mb-3">
              <span className="card-label font-size-h3 font-weight-bolder text-dark">
                {edit ? 'Editar ' : 'Añadir nuevo '}
                  {type === 'events' ? 'evento' : 'congreso'}
              </span>
                    </h3>
                    <div
                        className="close-container d-flex justify-content-end pt-2 cursor-pointer"
                        onClick={closeModal}
                    >
                        <FontAwesomeIcon
                            icon={faTimes}
                            className='close-icon pink-color'
                        />
                    </div>
                </div>
                <div className="card-body pt-0">
                    <form>
                        <div className="mt-6">
                            <div className="form-group mb-8">
                                <label className="font-weight-bolder">Nombre</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid form-control-lg"
                                    value={form.name || ''}
                                    onChange={handleInputChange('name')}
                                    placeholder={"Ingresa el nombre del " + (type === 'events' ? 'evento' : 'congreso')}
                                    maxLength={200}
                                />
                            </div>
                        </div>
                        {type === 'events' &&
                            <div className="mt-6">
                                <div className="form-group mb-8">
                                    <label className="font-weight-bolder">Congreso</label>
                                    <select
                                        className="form-control form-control-lg"
                                        value={congressValue || ''}
                                        onChange={(e) => setCongressValue(e.target.value)}
                                    >
                                        <option
                                            className='font-bold'
                                            key={0}
                                            value=""
                                        >
                                            Seleccionar
                                        </option>
                                        {_.map(congresses, (option) => (
                                            <option
                                                className='font-bold'
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        }

                        <div className="mt-6">
                            <div className="form-group mb-8">
                                <label className="font-weight-bolder">Fecha</label>
                                <input
                                    type="datetime-local"
                                    className="form-control form-control-solid form-control-lg"
                                    value={form.date || ''}
                                    onChange={handleInputChange('date')}
                                    placeholder="Ingresa fecha del evento"
                                />
                            </div>
                        </div>
                        <div className="d-flex mb-8">
                            <div className="symbol symbol-70 flex-shrink-0 mr-4">
                                <div className="symbol-label image-holder">
                                    {
                                        imgSrc && <img src={imgSrc} className='image-display' alt="file-display"/>
                                    }
                                </div>
                            </div>
                            <div
                                className="symbol symbol-70 flex-shrink-0"
                                onClick={handleMockClickToFile}
                            >
			    			<span
                                className="h-70px w-70px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0">
                  <span className="svg-icon svg-icon-lg m-0">
                    {/*begin::Svg Icon | path:assets/media/svg/icons/Design/Image.svg*/}
                      <input
                          type='file'
                          ref={hiddenFileInput}
                          onChange={handleFileSelection}
                          style={{display: 'none'}}
                      />
                      {/*end::Svg Icon*/}
                  </span>
                                {file ? 'Cambiar' : 'Subir'} imágen
                </span>
                            </div>
                            {/*end::Symbol*/}
                        </div>
                        <div className="mt-6">
                            <div className="form-group mb-8">
                                <label className="font-weight-bolder">
                                    {type === 'events' ? 'Lugar del evento' : 'Lugar del congreso'}
                                </label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid form-control-lg"
                                    value={form.place || ''}
                                    onChange={handleInputChange('place')}
                                    placeholder={type === 'events' ? 'Ingresa el lugar del evento' : 'Ingresa el lugar del congreso'}
                                    maxLength={200}
                                />
                            </div>
                        </div>
                        <CostsForm costs={costs} onCostsChange={handleCostsChange} onAddCost={handleAddCost}/>
                        {
                            <div className='mt-6'>
                                {
                                    <div className="form-group mb-8">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            onClick={(e) => handleIsActiveCongressChange(e.target.checked)}
                                            defaultChecked={form.isActiveCongress}
                                        />
                                        <label className="font-weight-bolder">¿Está Activo?</label>
                                    </div>
                                }
                            </div>
                        }

                        <button
                            type="submit"
                            disabled={isLoading}
                            onClick={handleSubmit}
                            className="btn btn-primary font-weight-bolder mr-2 px-8"
                        >
                            Guardar
                        </button>
                        <button
                            type="reset"
                            className="btn btn-clear font-weight-bolder text-muted px-8"
                            onClick={closeModal}
                        >
                            Cancelar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EventForm;
