import React, {useContext, useCallback} from 'react';
import "./ResetPassword.scss";
import logo from "../../../assets/img/logo_green.png"
import {paths} from "../../../services/routes/mainRoutes";
import {Link, useLocation} from "react-router-dom";
import useFormState from '../../../hooks/useFormState';
import {ApiContext} from '../../../services/api/api-config';
import useBoolean from '../../../hooks/useBoolean';

const ResetPassword = () => {
    const {form, handleInputChange, setForm} = useFormState({});
    const api = useContext(ApiContext);
    const [loading, loadingOn, loadingOff] = useBoolean();
    const queryString = useLocation().search;
    const [changedPassword, setChangedPassword] = useBoolean();

    const resetPassword = useCallback((e) => {
        e.preventDefault();
        loadingOn();
        const token = new URLSearchParams(queryString).get("_token");
        api.users.resetPassword({params: {token: token, password: form.pass}})
            .then(() => {
                setChangedPassword();
                loadingOff();
                setForm({});
            });
    }, [api, form, queryString, setChangedPassword, loadingOn, loadingOff, setForm]);

    return (
        <div className="ResetPassword">
            <div className='login-form text-center p-7 position-relative overflow-hidden'>
                <div className="mt-5 mb-15">
                    <Link to={paths.login} className='links'>
                        <img src={logo} alt='logo-amcemig' className="img-thumbnail"/>
                    </Link>
                </div>
                <div className="login-signin">
                    {changedPassword &&
                    <div className="registerResponse mb-4 text-success">
                        <h4>Tu contraseña se ha cambiado correctamente, da click en el siguiente enlace para iniciar
                            sesión</h4>
                        <Link to={paths.login} className='links'>
                            <h3 className='text-info mt-5 mb-5'>Inicia Sesión</h3>
                        </Link>
                    </div>
                    }
                    {!changedPassword &&
                    <>
                        <div className="mb-20">
                            <h3>Recupera tu contraseña</h3>
                            <div className="text-white font-weight-bold mb-5">Ingresa una nueva contraseña:</div>
                        </div>
                        <form className="form" id="kt_login_signin_form" onSubmit={resetPassword}>
                            <div className="form-group mb-5">
                                <input id='login-user-input' onChange={handleInputChange('pass')}
                                       value={form.pass || ''}
                                       className="form-control h-auto form-control-solid py-4 px-8" type="password"
                                       placeholder="Escribe tu nueva contraseña" name="password" autoComplete="off"/>
                            </div>
                            <div className="form-group mb-5">
                                <input className="form-control h-auto form-control-solid py-4 px-8"
                                       id='login-pass-input' onChange={handleInputChange('confirmation')}
                                       value={form.confirmation || ''} type="password"
                                       placeholder="Confirma tu nueva contraseña" name="password"/>
                            </div>
                            <button id="kt_login_signin_submit" type='submit'
                                    disabled={!form.pass || !form.confirmation || (form.pass !== form.confirmation) || loading}
                                    className="submit-button btn font-weight-bold px-9 py-4 my-3 mx-4">Enviar
                            </button>
                        </form>
                    </>
                    }

                </div>
            </div>
        </div>
    );
}

export default ResetPassword;