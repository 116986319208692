import React, {useContext, useMemo} from 'react'
import TideReactTable from '../../../../../components/TideReactTable/TideReactTable'
import {ApiContext} from '../../../../../services/api/api-config';
import useTideTable from '../../../../../hooks/useTideTable';
import {getAnnualitysTableColumns, annualitysSGroups} from '../../AnnualitysUtils';
import "./AnnualitysTable.scss"

export default function AnnualitysTable(
    {onAnnualityEdit, deleteAnnuality, filters}
) {

    const api = useContext(ApiContext);

    const annualitysTableColumns = useMemo(() => getAnnualitysTableColumns(
        {api, onAnnualityEdit, deleteAnnuality}
    ), [api, onAnnualityEdit, deleteAnnuality]);

    const annualitysFilters = useMemo(() => ({
        pagination: true,
        sGroups: annualitysSGroups,
        ...filters
    }), [filters]);

    const annualitysTable = useTideTable({
        entity: 'annualities',
        columns: annualitysTableColumns,
        requestFilters: annualitysFilters,
    });


    return (
        <div className='AnnualitysTable card card-custom card-shadowless product-container'>
            <div className="card-body p-5">
                <TideReactTable {...annualitysTable.tableProps}/>
            </div>
        </div>
    )
}
