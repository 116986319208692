import React, {useContext, useCallback} from 'react'
import TideTableFilters  from '../../../../../components/TideTableFilters/TideTableFilters';
import useCallbackCreator from "use-callback-creator";
import "./SponsorsAdminHeader.scss"
import { ApiContext } from '../../../../../services/api/api-config';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";

const SponsorsAdminHeader = (
  { onNewSponsorClick, filters, onFiltersChange }
) => {

  const api = useContext(ApiContext)

  const handleInputChange = useCallbackCreator( (field, event) => {
    const value = event.target.value;
    onFiltersChange( filters => {
      const newFilters = {...filters, [field]:value };
      if(value)
        return newFilters;
      delete newFilters[field];
      return newFilters;
    } );
  },[onFiltersChange]);

  const handleOrderChange = useCallbackCreator( (field) => {
    onFiltersChange( filters => {
      const newFilters = {...filters};
      if(!filters[field])
        newFilters[field] = 'ASC' ;
      else if(filters[field]==='ASC')
        newFilters[field] = 'DESC' ;
      else
        delete newFilters[field];
      return newFilters;
    } );
  },[onFiltersChange]);

  const filtersConfig = [
    {
      name: 'nombre',
      onChange: handleInputChange('name'),
      value: filters.name,
      type: 'text',
      placeholder: 'Filtrar por nombre',
      orderOnChange: handleOrderChange('order[name]'),
      orderStatus: filters['order[name]'],
    },
  ];

  const excelUrl = useCallback( () => {
      window.location.href = api.sponsors.exportExcelUrl(filters);
    }, [api.sponsors, filters]
  );

  return (
    <div className="SponsorsAdminHeader d-flex flex-row w-100">
      <div className="card-toolbar mt-5">
        <ul className="nav nav-pills nav-pills-sm nav-dark-75 justify-content-center justify-content-md-end">
          <li className="nav-item btn-amcemig">
            <span
              onClick={excelUrl}
              className="excelButton btn d-flex justify-content-center"
            >
              <FontAwesomeIcon icon={faFileExcel} className="text-white" />
            </span>
          </li>
          <li className="nav-item btn-amcemig">
            <span
              className="nav-link text-light py-2 px-4 btn-info cursor-pointer"
              data-toggle="tab"
              onClick={onNewSponsorClick}
            >
              Añadir Patrocinador
            </span>
          </li>
        </ul>
      </div>
      <div className="d-flex justify-content-md-start">
          <TideTableFilters inputsObject={filtersConfig} />
      </div>
      
    </div>
  )
}

export default SponsorsAdminHeader;