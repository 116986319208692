import React, { useState, useMemo, useCallback, useContext } from 'react';
import useTideTable from '../../../hooks/useTideTable';
import "./UsersAdmin.scss";
import TideReactTable from "../../../components/TideReactTable/TideReactTable";
import {faEdit, faTrash, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UsersAdminEdit from "./components/UsersAdmin/UsersAdminEdit";
import useBoolean from '../../../hooks/useBoolean';
import {userListSGroups} from "../../../services/modelUtils/userUtils";
import {useSelector} from "react-redux";
import { getNotifier } from '../../../services/notifier';
import { ApiContext } from '../../../services/api/api-config';

const UsersAdmin = () => {
    const userFullName = (u) => `${u?.username}`.trim();
    const [editingUser, setEditingUser] = useState();
    const [userIdToDelete, setUserIdToDelete] = useState(0)
    const [addingUser, addingUserOn, addingUserOff] = useBoolean();
    const me = useSelector(({api})=>api.me);
    const api = useContext(ApiContext);

    const usersColumns = useCallback((onEdit, onDelete, role) => [
        {Header: "Usuario", accessor: userFullName},
        {Header: "Rol", accessor: user => user.role.title },
        {
            Header: 'Acciones',
            accessor: user =>
            (
                <div className="actionButtonsContainer">
                    {
                        role !== 'ROLE_GENERAL_USER' &&
                        <button
                            className="btn btn-outline-success font-weight-bolder py-1 mr-1"
                            onClick={() => onEdit(user)}
                        >
                            <FontAwesomeIcon icon={faEdit} className="h-5 cursor-pointer"/>
                        </button>
                    }
                    {
                        (role === 'SUPER_ADMIN' || role === 'ROLE_GENERAL_ADMIN') && (user.role.name !== 'SUPER_ADMIN') &&
                        <button
                            className="btn btn-outline-danger font-weight-bolder py-1"
                            onClick={() => onDelete(user)}
                        >
                            <FontAwesomeIcon icon={faTrash} className="h-5 cursor-pointer"/>
                        </button>
                    }
                </div>
            )
        }
    ], []);

    const deleteUser = useCallback((user) => {
        setUserIdToDelete(user.id);
    }, []);

    const deleteUserConfirmed = useCallback(() => {
        if (userIdToDelete === me.id) {
            return getNotifier().error('No puede eliminar su propio usuario');
        }
        api.users.delete({id: userIdToDelete}).then(() => {
            setUserIdToDelete(0);
            getNotifier().success('Se ha eliminado el usuario');
        }).catch(
            error => getNotifier().error('No se pudo eliminar el usuario')
        );
    }, [api, me, userIdToDelete]);

    const requestFilters = useMemo(()=>({
        sGroups: userListSGroups
    }),[]);

    const columns = useMemo(() => usersColumns(setEditingUser, deleteUser, me.role.name), [deleteUser, me, usersColumns]);

    const table = useTideTable({
        entity: 'users',
        columns,
        requestFilters,
    });

    return (
        <div className='UsersAdmin d-flex flex-row'>
            <div className={"UsersAdmin " + ((editingUser || addingUser) && 'scroll-down-block')}>
                <div className='card card-custom gutter-b'>
                    <div className="card-header border-0 pt-5">
			    	    <h3 className="card-title align-items-start flex-column">
			    		    <span className="card-label font-weight-bolder magenta-color font-bold">Usuarios</span>
			    	    </h3>
                        <div className="card-toolbar">
                            <ul className="nav nav-pills nav-pills-sm nav-dark-75">
                                <li className="nav-item cursor-pointer btn-amcemig">
						    	    {
                                        me.role.name !== 'ROLE_GENERAL_USER' &&
                                        <span
                                            className={"nav-link text-primary py-2 px-4 text-light cursor-pointer"}
                                            data-toggle="tab"
                                            onClick={addingUserOn}
                                        >
                                            Añadir
                                        </span>
                                    }
						        </li>
						    </ul>
					    </div>
			        </div>
                    <div className="card-body pt-2 pb-0 mt-n3">
                        <div className="tab-content mt-5" id="myTabTables6">
                            {/*begin::Tap pane*/}
			                <div className="tab-pane fade active show" id="kt_tab_pane_6_1" role="tabpanel" aria-labelledby="kt_tab_pane_6_1">
			            	    {/*begin::Table*/}
			            	    <TideReactTable {...table.tableProps}/>
			            	    {/*end::Table*/}
			                </div>

                            {
                                editingUser &&
                                <div className='mt-5'>
                                    <UsersAdminEdit
                                        user={editingUser}
                                        onClose={()=>setEditingUser(null)}
                                        isEdit={true}
                                    />
                                </div>
                            }

                            {
                                addingUser &&
                                <div className='mt-5'>
                                    <UsersAdminEdit user={editingUser} onClose={addingUserOff} isEdit={false}/>
                                </div>
                            }
                            {
                                userIdToDelete  !== 0 &&
                                    <div className="flex-column offcanvas-mobile mx-auto SpeakerModal" id="kt_profile_aside">
                                        <div className="card card-custom gutter-b">
                                            <div className="card-header border-0 pt-2">
                                                <h3 className="card-title align-items-start flex-column mb-3">
                                                    <span className="card-label font-size-h3 font-weight-bolder text-dark">
                                                        Confirmar eliminar usuario
                                                    </span>
                                                </h3>
                                                <div
                                                    className="close-container d-flex justify-content-end pt-2 cursor-pointer"
                                                    onClick={() => setUserIdToDelete(0)}
                                                >
                                                <FontAwesomeIcon
                                                    icon={faTimes}
                                                    className='close-icon pink-color'
                                                />
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">
                                                <h3>
                                                    ¿Está seguro de eliminar el usuario?
                                                </h3>
                                                <div>
                                                    <button
                                                        type="button"
                                                        onClick={deleteUserConfirmed}
                                                        className="btn btn-primary font-weight-bolder mr-2 px-8"
                                                    >
                                                        Confirmar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
			                {/*end::Tap pane*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

UsersAdmin.displayName="UsersAdmin";

export default UsersAdmin;
