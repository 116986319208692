import React, { useContext, useEffect, useState } from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import "./EventRegistersModal.scss";
import { ApiContext } from '../../../../../services/api/api-config';
import _ from "lodash";

export default function EventRegistersModal({eventId, closeModal}) {

  const api = useContext(ApiContext);
  const [first, setFirst] = useState(true)
  const [persons, setPersons] = useState([])

  useEffect(() => {
    if (first) {
      api.events.getRegisters(eventId).then(response => {
        setPersons(response);
        setFirst(false);
      }).catch(error => {
        setFirst(false);
      });
    }
  }, [api.events, eventId, first])

  const onlyAttendedNumber = _.filter(persons, {attended: true}).length;

  return (
    <div className='EventRegistersModal'>
      <div className="card card-custom gutter-b">
        <div className="card-header border-0 pt-2">
            <h3 className="card-title align-items-start flex-column mb-3">
              <span className="card-label font-size-h3 font-weight-bolder text-dark">
                {persons.length} Registrados al evento - {onlyAttendedNumber} Asistieron
              </span>
            </h3>
          <div
            className="close-container d-flex justify-content-end pt-2 cursor-pointer"
            onClick={closeModal}
          >
            <FontAwesomeIcon
              icon={faTimes}
              className='close-icon pink-color'
            />
          </div>
        </div>
        <div className="card-body pt-0">
          <table className='table'>
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Título</th>
                <th>Nombre</th>
                <th>Apellido Paterno</th>
                <th>Apellido Materno</th>
                <th>Correo electrónico</th>
                <th>Teléfono</th>
                <th>RFC</th>
                <th>Institución</th>
                <th>Especialidad</th>
                <th>¿Asistió?</th>
              </tr>
            </thead>
            <tbody>
              {
                _.map(persons, (person, index) => (
                  <tr key={index}>
                    <td>{person.person.type ? person.person.type : 'Registrado'}</td>
                    <td>{person.person.title}</td>
                    <td>{person.person.name}</td>
                    <td>{person.person.patLastName}</td>
                    <td>{person.person.matLastName}</td>
                    <td>{person.person.email}</td>
                    <td>{person.person.phone}</td>
                    <td>{person.person.rfc}</td>
                    <td>{person.person.institution}</td>
                    <td>{person.person.speciality}</td>
                    <td>{person.attended ? 'Si' : 'No'}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
